import React, { useEffect, useState } from "react";
import HomePageUser from "./HomePageUser";
import Header from "../Header";
import {
  FetchHomePageDetails,
  FetchUserProgressFromBatchTable,
  FetchMentorDetails,
  RetriveMessagesFromBatchNotification,
} from "../../api/aws-api";
import { ConstructorEventGet } from "../../Event/event";

const HomePage = (props) => {
  const [data, setData] = useState([]);
  const [notification, setNotification] = useState([]);
  const [coursedata, setCourseData] = useState();
  const [batchProgress, setBatchProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [starRating,setStarRating] = useState()

  useEffect(() => {
    const storedData = localStorage.getItem("UserKey");
    if (
      storedData !== "undefined" &&
      storedData !== "null" &&
      storedData !== undefined &&
      storedData !== null
    ) {
      fetchdata(JSON.parse(storedData));
    }


  }, []);
  const fetchdata = async (params) => {
    setData(JSON.parse(sessionStorage.getItem("UserData")));
    const temp = sessionStorage.getItem("HomePageNotification");
    const parsedTemp = temp ? JSON.parse(temp) : null;
    setNotification(parsedTemp || []);
    const responsedata = await ConstructorEventGet(
      FetchHomePageDetails,
      params
    );
    setData(responsedata[0]);
    setStarRating(responsedata[0].admiration)
    if (responsedata[0] && responsedata[0].course) {
      setCourseData(responsedata[0].course);
      fetchBatchProgress(responsedata[0].course);
      fetchBatchNotification(responsedata[0].course);
    }
  };

  // console.log(`the star rating is----------- ${starRating}`)
  const fetchBatchNotification = async (coursedetails) => {
    const responsedata = await ConstructorEventGet(
      RetriveMessagesFromBatchNotification,
      { BatchName: coursedetails[0].batch_id }
    );
    if (responsedata[0] === "success") {
      sessionStorage.setItem(
        "HomePageNotification",
        JSON.stringify(responsedata[1])
      );
      setNotification(responsedata[1]);
    } else if (responsedata === "no-data") {
      sessionStorage.setItem("HomePageNotification", JSON.stringify([]));
      setNotification([]);
    }
    fetchmentordata();
  };

  const fetchmentordata = async () => {
    //const headers = {'authorizationToken': '1/G2G1GG378F'}
    //const response = await axios.get(FetchMentorDetails , { headers });
    //console.log(response.data)
    const responsedata = await ConstructorEventGet(FetchMentorDetails, {
      BatchId: "1-1",
    });
    sessionStorage.setItem("MentorName", JSON.stringify(responsedata));
  };
  const fetchBatchProgress = async (coursedetails) => {
    const responsedata = await ConstructorEventGet(
      FetchUserProgressFromBatchTable,
      {
        mentorId: coursedetails[0].batch_mentor_id,
        BatchId: coursedetails[0].batch_id,
      }
    );
    if (responsedata === "no_class_sheduled") {
    } else if (responsedata[0] === "Class_Completed") {
      setBatchProgress(responsedata[1]);
      sessionStorage.setItem("CourseKey", JSON.stringify(coursedetails));
    } else {
      setBatchProgress(responsedata[0]);
      coursedetails[0]["next_class_details"] = responsedata[1];
      sessionStorage.setItem("CourseKey", JSON.stringify(coursedetails));
    }
  };

  return (
    <>
      <Header course={coursedata} />
      <HomePageUser
        data={data}
        loading={loading}
        notification={notification}
        batchProgress={batchProgress}
      />
    </>
  );
};

export default HomePage;
