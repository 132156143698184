import React, { useState, useRef } from "react";
import "../../assets/css/newacitivity.css";
import backgroundImage from "../../assets/images/NewActivityImages/background.jpg";


export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();
  const [InputFontColor, setInputFontColor] = useState("");

  const [questionNumber, setQuestionNumber] = useState(0);
  const [Activitystarted, setActivitystarted] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [ClickedNext, setClickedNext] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [ValueDropdown, setValueDropdown] = useState("");
  const [ButtonName, setButtonName] = useState("");
  const [ButtonSize, setButtonSize] = useState("");
  const [ButtonHeight, setButtonHeight] = useState("");
  const [ButtonWidth, setButtonWidth] = useState("");
  const [ButtonColor, setButtonColor] = useState("");
  const [Torch, setTorch] = useState(false);
  const [SelectedFontsize, setSelectedFontsize] = useState(false);
  const [selectedpopup, setSelectedpopup] = useState(false);

  // Handle dropdown change event
  const handleColorChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedColor(event.target.value);
    }
  };
  const handleScreenChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedScreen(event.target.value);
    }
  };
  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.id);
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");
    const droppedItem = document.getElementById(droppedItemId);
    e.target.appendChild(droppedItem);

    setDragging(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const ButtonStyle = {
    fontSize: Activitystarted ? parseInt(ButtonSize) : "10px",
    backgroundColor: Activitystarted && ButtonColor ? ButtonColor : "black",
    height: Activitystarted ? parseInt(ButtonHeight) : "60px",
    width: Activitystarted ? parseInt(ButtonWidth) : "60px",
    borderRadius: "10px",
    color: Activitystarted && InputFontColor ? InputFontColor : "white",
    marginTop: "10px",
  };

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    console.log("The extracted HTML code is----", data);
    setHtmlCode(data);
  }

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  return (
    <div className="container" style={{ overflow: "hidden",backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>

      <div></div>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="Newbody">
            <div className="Newheader">
              <div className="design-vew7 mt-2" ref={getHtmlCode}>
                <div className="design-vew7box">
                  Choose Your Adventure! Do you Want to go LEFT or RIGHT
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                  onDrop={(e) => Activitystarted && handleDrop(e)}
                  onDragOver={(e) => Activitystarted && handleDragOver(e)}
                >
                  <button
                    className="buttonsession"
                    style={{ backgroundColor: "green" }}
                  >
                    Left
                  </button>
                </div>

                {/* <div class="box-content p-3 mt-13 ml-20">
                  <div
                    class="box-content p-3"
                    onDrop={(e) => Activitystarted && handleDrop(e)}
                    onDragOver={(e) => Activitystarted && handleDragOver(e)}
                  >
                    <div
                      class=" plug-boardwork-space-height"
                      id="drop-zone"
                    ></div>
                  </div>
                </div>  */}

                {/* {!ClickedNext ? <div className="mt-14"></div> : ""} */}
              </div>
              {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}
              <button
                className="button-12 mt-2 "
                style={{ textAlign: "center" }}
                onClick={() =>
                  Activitystarted
                    ? (setClickedNext(false), setActivitystarted(false))
                    : setActivitystarted(true)
                }
              >
                {Activitystarted ? "RESET" : "RUN"}
              </button>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div class="lg:col-span-9 col-span-12 mt-5">
          <i class="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>
          <div className="NavbarApp">&emsp;Instructions</div>
          <div className="fontsp">Goal:</div>
          <h6>
            &emsp;&emsp;Add a second button to the screen that says "Right!"
            with an "id" of "rightButton".{" "}
          </h6>
          <div className="fontsp">How:</div>
          <h6>
            &emsp;&emsp; In Design Mode drag in a new button. Position and
            resize it. Then set its "Height","Width", "text", "background
            color", and "font size".{" "}
          </h6>

          <div className=" row ">
            <div className="col-6 ">
              <div class="box">
                <div class="box-head">
                  <h2>&emsp;&emsp;&emsp;TOOLBOX</h2>
                  <a class="yellow-btn">
                    <i class="fa-solid fa-minus"></i>
                  </a>
                  <iconify-icon
                    icon="icon-park-twotone:right-c"
                    width="20"
                    class="iconify-icon-right"
                  ></iconify-icon>
                  <iconify-icon
                    icon="ant-design:setting-outlined"
                    width="20"
                    class="iconify-icon-left"
                  ></iconify-icon>
                </div>
              </div>

              <div class="box-content p-3 Toolboxmaxwidth">
                <ul class="" role="tablist">
                  <li class="nav-item">
                    <i class="fa-regular fa-gear"></i>
                    <a class="ybox" data-bs-toggle="tab" href="#control">
                      Design the Elements
                    </a>
                  </li>
                </ul>
                <hr></hr>
                <hr></hr>
                <hr></hr>
                <div
                  onDragStart={(e) => Activitystarted && handleDragStart(e)}
                  onDragEnd={(e) => Activitystarted && handleDragEnd(e)}
                >
                  <div class=" draggable" draggable="true" id="2">
                    <div class="box-content p-3">
                      <button style={ButtonStyle} id="button1" draggable="true">
                        {Activitystarted && ButtonName ? ButtonName : "Button"}
                      </button>
                      {/* <input id="Input1" draggable="true"></input> */}
                      <div class="">
                        <iconify-icon
                          icon="vaadin:button"
                          width="60"
                          id="1"
                          draggable="true"
                        ></iconify-icon>
                        <iconify-icon
                          icon="lucide:text-cursor-input"
                          width="60"
                          id="2"
                          draggable="true"
                        ></iconify-icon>
                        <iconify-icon
                          icon="material-symbols:abc"
                          width="60"
                          id="3"
                          draggable="true"
                        ></iconify-icon>
                        <iconify-icon
                          icon="fluent-mdl2:dropdown"
                          width="60"
                          id="d4"
                          draggable="true"
                        ></iconify-icon>
                        <iconify-icon
                          icon="radix-icons:radiobutton"
                          width="60"
                          id="drag5gable-item1"
                          draggable="true"
                        ></iconify-icon>
                        <iconify-icon
                          icon="tabler:checkbox"
                          width="60"
                          id="draggable6-item1"
                          draggable="true"
                        ></iconify-icon>

                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5 ">
              <div class="box">
                <div class="box-head">
                  <h2>WORKSPACE</h2>
                  <a
                    class="btn mt-5 "
                    onClick={() => {
                      popupvalue();
                      handleHtmlCode();
                    }}
                  >
                    <button>Show Code</button>
                  </a>
                  {"  "}
                  <a
                    class="btn mt-5"
                    data-bs-toggle="modal"
                    data-bs-target="#show-code-popup"
                  >
                    <button>Version History</button>
                  </a>
                  {selectedpopup && (
                    <div className="modal">
                      <div className="overlay">
                        <div className="modal-content">
                          {htmlCode ? htmlCode : null}
                          <button
                            className="btn"
                            style={{ marginTop: "30px" }}
                            onClick={popupvalue}
                          >
                            close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div class="box-content p-3 Workspacemaxwidth">
                  <div class="box-content p-3">
                    <div>
                      <input
                        value={ButtonName}
                        onChange={(e) => setButtonName(e.target.value)}
                        type="text"
                        placeholder="Enter button Name"
                      />
                    </div>
                    <div>
                      <input
                        value={ButtonSize}
                        type="number"
                        placeholder="Enter font Size "
                        onChange={(e) => setButtonSize(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        value={ButtonHeight}
                        type="number"
                        placeholder="Enter button height"
                        onChange={(e) => setButtonHeight(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      <input
                        type="number"
                        onChange={(e) => setButtonWidth(e.target.value)}
                        placeholder="Enter button width"
                        value={ButtonWidth}
                      ></input>
                    </div>
                    <select
                      onChange={(e) => setButtonColor(e.target.value)}
                      value={ButtonColor}
                    >
                      <option value="" disabled>
                        Button color
                      </option>
                      <option value="red">RED</option>
                      <option value="blue">BLUE</option>
                      <option value="green">GREEN</option>
                      <option value="yellow">YELLOW</option>
                    </select>
                    <select
                      placeholder="Font color"
                      onChange={(e) => setInputFontColor(e.target.value)}
                      value={InputFontColor}
                    >
                      <option value="" disabled>
                        Font Color
                      </option>
                      {/* Other dropdown options */}
                      <option value="black">BLACK</option>
                      {/* <option value="C7ED1A">NEON</option> */}
                      <option value="#DB3BEE">VOILET</option>
                      <option value="#605C61 ">GREY</option>
                    </select>

                    <div class="plug-board work-space"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appone;
