import React, { useState, useEffect, Component } from 'react';
import "../../assets/css/newacitivity.css"

function ActivityNine() {
    const [KCount, setKCount] = useState(0);
    const [SCount, setSCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [counting, setCounting] = useState(false);
    const [activitystarted, setActivitystarted] = useState(false)
    const [ClickedNext, setClickedNext] = useState(false)

    useEffect(() => {

        const handleKeyPress = (e) => {
            if (activitystarted && counting) {
                if (e.key === 'k') {
                    setKCount((prevCount) => prevCount + 1);
                } else if (e.key === 's') {
                    setSCount((prevCount) => prevCount + 1);
                }
                setTotalCount((prevTotal) => prevTotal + 1);
            }
        };
        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [totalCount, counting]); // Include counting in the dependency array

    return (
        <div className="container">
            <div className='grid grid-cols-12'>
                <div className="lg:col-span-3 col-span-12 ">
                    <div className="design-vew22" >
                        <div className='boxNine'>Which Finger Is The Fastest ?</div>
                        <h7>Put one finger on the "S" key and the other on the "K" key.Then click as Fast you Can</h7>
                        {!counting ? <button className='button-86' onClick={() => activitystarted && setCounting(true)}>Start Counting</button>
                            : <button className='button-86 ' onClick={() => (setCounting(false), setKCount(0), setSCount(0), setTotalCount(0))}>Stop Counting</button>}
                        <p >K Count: {KCount}</p>
                        <p>S Count: {SCount}</p>
                        {/* <p>Total Count: {totalCount}</p> */}
                        <iframe className='mr-4' src="https://giphy.com/embed/kz6cm1kKle2MYkHtJF" width="210" height="100" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
                    </div>
                    {/* <div class="nav-controls justify-content-between align-items-center"> */}
                        <button className="button-12 mr-2" style={{ textAlign: "center" }} onClick={() => activitystarted ? (setClickedNext(false), setActivitystarted(false), setCounting(false), setKCount(0), setSCount(0), setTotalCount(0)) : setActivitystarted(true)}>{activitystarted ? "RESET" : "RUN"}</button>
                    {/* </div> */}
                    </div>
                        <div class="lg:col-span-9 col-span-12">
                        <div className='whiteboard'>
                            <div class="headerwhitebox"></div>
                            <h4 class="rightheading">The Fastest Finger  </h4>
                            <div class="rightpaneul">
                                This app tells you which finger is the fastest,the one that presses the 's' key or the one that presses the 'k' key.run the app and press each as many you can
                                <ul >
                                    <h4>Input:</h4>
                                    <li>1. Whether the 's'key is pressed(user key press)</li>
                                    <li>2. Whether the 'k'key is pressed(user key press)</li>
                                    <li>3. List of influential figures and the month they were born(internet or stored in the app)</li>
                                    <hr></hr>
                                    <h4>Output</h4>
                                    <li>display of how many times the 's' key has been pressed</li>
                                    <li>display of how many times the 'k' key has been pressed</li>
                                    <li>display of which key has been pressed the most,or if it's a tie </li>
                                    <hr></hr>
                                    <h4>Processing</h4>
                                    <li>Out of the Four types of processing that you have learned about,pick one and exlain how it can be used to change the input into output</li>
                                    <li>*if/then</li>
                                    <li>*counting</li>
                                    <li>*finding a match</li>
                                    <li>*comparing</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                
            </div>
        </div>

    );
}

export default ActivityNine;
