// import React, { useEffect, useState } from 'react';
// import { gapi } from 'gapi-script';

// const CLIENT_ID = '1059586340559-6dkenj4q8lo84pni4dji3mko9j6vj6jo.apps.googleusercontent.com';
// const API_KEY = 'AIzaSyAW5RCWjgZI2uzZo-6BS1GDDZkorfR-0C0';
// const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
// const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly';

// const FetchCalendarEvents = () => {
//     const [events, setEvents] = useState([]);
//     const [isSignedIn, setIsSignedIn] = useState(false);

//     useEffect(() => {
//         const initClient = () => {
//             gapi.client.init({
//                 apiKey: API_KEY,
//                 clientId: CLIENT_ID,
//                 discoveryDocs: DISCOVERY_DOCS,
//                 scope: SCOPES,
//             }).then(() => {
//                 const authInstance = gapi.auth2.getAuthInstance();
//                 setIsSignedIn(authInstance.isSignedIn.get());
//                 authInstance.isSignedIn.listen(setIsSignedIn);
//             }).catch(error => {
//                 console.error('Failed to initialize client:', error);
//             });
//         };

//         gapi.load('client:auth2', initClient);
//     }, []);

//     const handleSignInClick = () => {
//         gapi.auth2.getAuthInstance().signIn();
//     };

//     const handleSignOutClick = () => {
//         gapi.auth2.getAuthInstance().signOut();
//     };

//     const fetchEvents = () => {
//         const calendarId = 'primary'; // Or use a specific calendar ID
//         const timeMin = new Date().toISOString();
//         const timeMax = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7).toISOString(); // 1 week ahead

//         gapi.client.calendar.events.list({
//             calendarId: calendarId,
//             timeMin: timeMin,
//             timeMax: timeMax,
//             showDeleted: false,
//             singleEvents: true,
//             maxResults: 10,
//             orderBy: 'startTime',
//         }).then(response => {
//             console.log('API Response:', response);
//             const events = response.result.items;
//             setEvents(events);
//         }).catch(error => {
//             console.error('API Error:', error);
//         });
//     };

//     useEffect(() => {
//         if (isSignedIn) {
//             fetchEvents();
//         }
//     }, [isSignedIn]);

//     return (
//         <div>
//             <h2>Google Calendar Events</h2>
//             {isSignedIn ? (
//                 <>
//                     <button onClick={handleSignOutClick}>Sign Out</button>
//                     <ul>
//                         {events.length === 0 ? (
//                             <li>No events found.</li>
//                         ) : (
//                             events.map(event => (
//                                 <li key={event.id}>
//                                     <p>Event: {event.summary}</p>
//                                     <p>Start: {event.start.dateTime || event.start.date}</p>
//                                     <p>End: {event.end.dateTime || event.end.date}</p>
//                                     {event.attendees && event.attendees.length > 0 ? (
//                                         <div>
//                                             <p>Attendees:</p>
//                                             <ul>
//                                                 {event.attendees.map(attendee => (
//                                                     <li key={attendee.email}>
//                                                         {attendee.displayName || attendee.email} - {attendee.responseStatus}
//                                                     </li>
//                                                 ))}
//                                             </ul>
//                                         </div>
//                                     ) : (
//                                         <p>No attendees</p>
//                                     )}
//                                     {event.conferenceData && event.conferenceData.entryPoints && event.conferenceData.entryPoints.map(entryPoint => (
//                                         entryPoint.entryPointType === 'video' && (
//                                             <p key={entryPoint.uri}>Google Meet Link: <a href={entryPoint.uri}>{entryPoint.uri}</a></p>
//                                         )
//                                     ))}
//                                 </li>
//                             ))
//                         )}
//                     </ul>
//                 </>
//             ) : (
//                 <button onClick={handleSignInClick}>Sign In with Google</button>
//             )}
//         </div>
//     );
// };

// export default FetchCalendarEvents;


import React, { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';

const CLIENT_ID = '347764156937-klkgu25nrg2l7pq8iticmrvha29f3vr7.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCio8YcxXtf28_wN_ZWIHIjrlmzjGbXTJg';
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/calendar';

const GoogleMeetComponent = () => {
    const [events, setEvents] = useState([]);
    const [isSignedIn, setIsSignedIn] = useState(false);

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            }).then(() => {
                const authInstance = gapi.auth2.getAuthInstance();
                setIsSignedIn(authInstance.isSignedIn.get());
                authInstance.isSignedIn.listen(setIsSignedIn);
            }).catch(error => {
                console.error('Failed to initialize client:', error);
            });
        };

        gapi.load('client:auth2', initClient);
    }, []);

    const handleSignInClick = () => {
        gapi.auth2.getAuthInstance().signIn();
    };

    const handleSignOutClick = () => {
        gapi.auth2.getAuthInstance().signOut();
    };

    const createGoogleMeetEvent = () => {
        const event = {
            summary: 'Sample oogle Meet Event',
            description: 'Meeting to discuss project updates.',
            start: {
                dateTime: '2024-07-25T09:00:00-07:00',
                timeZone: 'America/Los_Angeles',
            },
            end: {
                dateTime: '2024-07-25T10:00:00-07:00',
                timeZone: 'America/Los_Angeles',
            },
            conferenceData: {
                createRequest: {
                    requestId: 'unique-request-id', // Unique request ID to create conference
                    conferenceSolutionKey: {
                        type: 'hangoutsMeet',
                    },
                },
            },
            attendees: [
                { email: 'naveencodomo@gmail.com' },
                { email: 'frodonaveen33@gmail.com' }
            ],
        };

        gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
            conferenceDataVersion: 1, // Required to include conference data
        }).then(response => {
            console.log('Event created:', response);
            fetchEvents(); // Optionally fetch events to see the newly created one
        }).catch(error => {
            console.error('Error creating event:', error);
        });
    };

    const fetchEvents = () => {
        const calendarId = 'primary';
        const timeMin = new Date().toISOString();
        const timeMax = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7).toISOString(); // 1 week ahead

        gapi.client.calendar.events.list({
            calendarId: calendarId,
            timeMin: timeMin,
            timeMax: timeMax,
            showDeleted: false,
            singleEvents: true,
            maxResults: 10,
            orderBy: 'startTime',
        }).then(response => {
            console.log('API Response:', response);
            const events = response.result.items;
            setEvents(events);
        }).catch(error => {
            console.error('API Error:', error);
        });
    };

    useEffect(() => {
        if (isSignedIn) {
            fetchEvents();
        }
    }, [isSignedIn]);

    return (
        <div>
            <h2>Google Meet and Calendar Events</h2>
            {isSignedIn ? (
                <>
                    <button onClick={handleSignOutClick}>Sign Out</button>
                    <br />
                    <button onClick={createGoogleMeetEvent}>Create Google Meet Event</button>
                    <ul>
                        {events.length === 0 ? (
                            <li>No events found.</li>
                        ) : (
                            events.map(event => (
                                <li key={event.id}>
                                    <p>Event: {event.summary}</p>
                                    <p>Start: {event.start.dateTime || event.start.date}</p>
                                    <p>End: {event.end.dateTime || event.end.date}</p>
                                    {event.attendees && event.attendees.length > 0 ? (
                                        <div>
                                            <p>Attendees:</p>
                                            <ul>
                                                {event.attendees.map(attendee => (
                                                    <li key={attendee.email}>
                                                        {attendee.displayName || attendee.email} - {attendee.responseStatus}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ) : (
                                        <p>No attendees</p>
                                    )}
                                    {event.conferenceData && event.conferenceData.entryPoints && event.conferenceData.entryPoints.map(entryPoint => (
                                        entryPoint.entryPointType === 'video' && (
                                            <p key={entryPoint.uri}>Google Meet Link: <a href={entryPoint.uri}>{entryPoint.uri}</a></p>
                                        )
                                    ))}
                                </li>
                            ))
                        )}
                    </ul>
                </>
            ) : (
                <button onClick={handleSignInClick}>Sign In with Google</button>
            )}
        </div>
    );
};

export default GoogleMeetComponent;


