import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import { FetchCourseDetails, FetchMentorDetails } from "../../api/aws-api";
import { ConstructorEventGet } from "../../Event/event";
import { ClassicSpinner } from "react-spinners-kit";
import { useLocation } from "react-router-dom"
import { gapi } from 'gapi-script';
import Swal from 'sweetalert2'


const AddBatch = (props) => {
  const location = useLocation();
  const [courseOptions, setCourseOptions] = useState([]);
  const [prevCalculateDateTime, setPrevCalculateDateTime] = useState([]);
  const [startdate, setStartDate] = useState();
  const [enddate, setEndDate] = useState();
  const [showeventmenu, setShoweventmenu] = useState(false);
  const [loading, setLoading] = useState(false)
  const [course, setCourse] = useState();
  const [calenderEventDates, setCalenderEventDates] = useState([])
  const [showAddStudentButton, setShowAddStudentButton] = useState(false)
  const [recurrent, setRecurrent] = useState("Mon--Fri");
  const [coursedata, setCoursedata] = useState([]);
  const [starttime, setStartTime] = useState("12:30");
  const [endtime, setEndTime] = useState("13:30");
  const [prevEvents, setPrevEvents] = useState([])
  const [calenderEvents, setCalenderEvents] = useState([])
  const [batchData, setBatchData] = useState([])
  const navigate = useNavigate()

  const [CustomBatchShedule, setCustomBatchShedule] = useState(false)//Custom
  const [Customdate, setCustomDate] = useState();
  const [Customstarttime, setCustomStartTime] = useState("12:30");
  const [Customendtime, setCustomEndTime] = useState("13:30");
  const [Customdatelist, setCustomDateList] = useState([]);
  const [meetLink, setMeetLink] = useState("")
  const [signedIn, setSignedIn] = useState(false);

  const CLIENT_ID = '347764156937-klkgu25nrg2l7pq8iticmrvha29f3vr7.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyCio8YcxXtf28_wN_ZWIHIjrlmzjGbXTJg';
  const SCOPES = 'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/admin.reports.audit.readonly';



  useEffect(() => {
    const { batchdata } = location.state ? location.state : []
    setBatchData(location.state)
    const filteredBatch = batchdata.filter(obj => obj.batchProgress === "Scheduled" || obj.batchProgress === "InProgress")
    AcumulatePreviousClassesToCalender(filteredBatch)
    const storedData = sessionStorage.getItem('CourseKey');
    const mentorName = sessionStorage.getItem("SelectedMentor")
    const mentorDetails = JSON.parse(mentorName)
    setCourseOptions(mentorDetails.course)
    // console.log("the stored data is", storedData)
    // if (storedData !== "undefined" && storedData !== "null" && storedData !== undefined && storedData !== null) { setCourseOptions(JSON.parse(storedData)) }
    gapi.load('client:auth2', start);
  }, [])

  function start() {
    gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      scope: SCOPES,
    }).then(() => {
      gapi.client.load('calendar', 'v3'); // Load the Calendar API library
      const authInstance = gapi.auth2.getAuthInstance();
      authInstance.signIn();
      setSignedIn(authInstance.isSignedIn.get());
      authInstance.isSignedIn.listen(setSignedIn);
    });
  }


  const handleDateSelect = (selectInfo) => {
    console.log("the showstudent button is", showAddStudentButton)
    setStartDate(dateChanger(selectInfo.start))
    setEndDate(recurrent === "Mon-Wed-Fri" ? addWorkingDaysMonWedFri(dateChanger(selectInfo.start), coursedata.total_class) : addWorkingDaysTueThursSat(dateChanger(selectInfo.start), coursedata.total_class))
  };

  const dateChanger = (today) => {
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  }

  const dayOfWeek = (dateStr) => {
    const date = new Date(dateStr);
    const dayOfWeekIndex = date.getDay();
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = dayNames[dayOfWeekIndex];
    return dayName
  }

  const createGoogleMeet = () => {
    const event = {
      summary: 'Google Meet Meeting',
      start: {
        dateTime: new Date(new Date().getTime() + 5 * 60 * 1000).toISOString(), // 5 minutes from now
        timeZone: 'America/Los_Angeles',
      },
      end: {
        dateTime: new Date(new Date().getTime() + 30 * 60 * 1000).toISOString(), // 30 minutes later
        timeZone: 'America/Los_Angeles',
      },
      conferenceData: {
        createRequest: {
          requestId: 'some-random-string',
          conferenceSolutionKey: {
            type: 'hangoutsMeet',
          },
        },
      },
    };

    gapi.client.calendar.events.insert({
      calendarId: 'primary',
      resource: event,
      conferenceDataVersion: 1,
    }).then(response => {
      console.log("the response", response.result.status)
      console.log('Meet link:', response.result.hangoutLink);
      if (response.result.status === "confirmed") {
        NavigatetoStudentAddPage(response.result.hangoutLink)
      }

    }).catch(error => {
      console.error('Error creating Google Meet:', error);
    });

  };


  const AddDurationToTime = (inputTime, inputDuration) => {
    // Convert input time and duration to hours and minutes
    var [hours, minutes] = inputTime.split(":").map(Number);
    var durationParts = inputDuration.match(/(\d+H)?(\d+M)?/);
    var durationHours = durationParts[1] ? parseInt(durationParts[1]) : 0;
    var durationMinutes = durationParts[2] ? parseInt(durationParts[2]) : 0;
    // Add duration to time
    var newHours = hours + durationHours;
    var newMinutes = minutes + durationMinutes;
    // Handle overflow of minutes
    if (newMinutes >= 60) {
      newHours += Math.floor(newMinutes / 60);
      newMinutes %= 60;
    }
    // Format output
    var outputTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
    return outputTime
  }
  const AcumulatePreviousClassesToCalender = (data) => {
    const totalPrevEvents = []
    const CalculateDateTime = []
    for (let batch = 0; batch < data.length; batch++) {
      if ('ClassSchedule' in data[batch]) {
        CalculateDateTime.push({
          startDate: data[batch].StartDate,
          endDate: data[batch].EndDate,
          startTime: data[batch].StartTime,
          endTime: data[batch].EndTime,
        })
        for (let sheduledclass = 0; sheduledclass < data[batch]["ClassSchedule"].length; sheduledclass++) {
          totalPrevEvents.push(
            {
              title: data[batch]["BatchName"] + "(" + (data[batch]["ClassSchedule"][sheduledclass]["scheduleNumber"]) + ")",
              start: data[batch]["ClassSchedule"][sheduledclass]["startDateTime"],
              end: data[batch]["ClassSchedule"][sheduledclass]["endDateTime"],
              scheduleNumber: data[batch]["ClassSchedule"][sheduledclass]["scheduleNumber"],
            }
          )
        }
      }
    }
    setPrevCalculateDateTime(CalculateDateTime)
    setPrevEvents(totalPrevEvents)
    setCalenderEvents(totalPrevEvents)
  }
  const IncludeInCustomDateList = () => {

    console.log("adding customdates pressed")
    var temp = {
      startDateTime: Customdate + "T" + Customstarttime + ":00",
      endDateTime: Customdate + "T" + Customendtime + ":00",
      status: "Scheduled",
      meet_link: meetLink
    }
    const check = doesCustomDateOverlapWithPreviosDate(Customdate, Customstarttime, Customendtime)
    if (!check) { setCustomDateList([...Customdatelist, temp]) }

  }
  const removeDateFromCustomDateList = (startDateTime) => {
    const updatedList = Customdatelist.filter((item) => item["startDateTime"] !== startDateTime);
    setCustomDateList(updatedList);
  };

  const doesCustomDateOverlapWithPreviosDate = (customdate, startTime, endTime) => {
    console.log("it comes inside the customoverlappreviousdate", customdate, startTime, endTime)

    const date = new Date(customdate);
    const timeStart = new Date(`1970-01-01T${startTime}`);
    const timeEnd = new Date(`1970-01-01T${endTime}`);
    let flag = false
    for (const data of prevCalculateDateTime) {
      const date2Start = new Date(data.startDate);
      const date2End = new Date(data.endDate);
      const time2Start = new Date(`1970-01-01T${data.startTime}`);
      const time2End = new Date(`1970-01-01T${data.endTime}`);
      time2End.setMinutes(time2End.getMinutes() + 15);
      console.log("sssss", time2End)
      if (date <= date2End && date >= date2Start) {
        if (timeStart < time2End && timeEnd > time2Start) {
          flag = true // Ranges overlap
          Swal.fire({
            icon: "warning",
            title: "Can't be scheduled",
            text: "You have already class in this timing",
            // footer: '<a href="#">Why do I have this issue?</a>'
          });
        }
      }
    }
    return flag
  }

  function doesRangeOverlapWithPreviousData(startdate, enddate, starttime, endtime) {


    console.log("dates are", startdate, enddate, starttime, endtime)
    console.log("prevDates are", prevCalculateDateTime)


    const newDate = prevCalculateDateTime.filter((ele) => {
      return ele.startDate === startdate
    })
    if (newDate.length > 0) {
      for (let i = 0; i < newDate.length; i++) {
        const inputTime = new Date(`1970-01-01T${starttime}:00Z`);
        const startingTime = new Date(`1970-01-01T${newDate[i].startTime}:00Z`);
        const endingTime = new Date(`1970-01-01T${newDate[i].endTime}:00Z`);

        endingTime.setMinutes(endingTime.getMinutes() + 15);
        console.log("input time ", inputTime)
        if (inputTime >= startingTime && inputTime < endingTime) {
          // setShowAddStudentButton(false)
          console.log("overlaps")

          Swal.fire({
            icon: "warning",
            title: "Can't be scheduled",
            text: "You have already class in this timing",
            // footer: '<a href="#">Why do I have this issue?</a>'
          });
        } else {
          // setShowAddStudentButton(true)
        }
      }


    } else {
      // setShowAddStudentButton(true)
    }

  }



  const fetchCourseDetails = async () => {
    setLoading(true)
    const coursedfulldata = courseOptions.find(obj => obj.course_name === course);
    const responsedata = await ConstructorEventGet(FetchCourseDetails, { course_id: coursedfulldata.course_id })
    console.log("rrrrrrrrrrr", responsedata)
    setCoursedata(responsedata)
    setStartDate(dateChanger(new Date()))
    setCustomDate(dateChanger(new Date()))
    setEndDate(addWorkingDaysMonWedFri(dateChanger(new Date()), responsedata.total_class))
    // setEndTime(AddDurationToTime("12:30", responsedata.class_duration))
    setShoweventmenu(true)
    setLoading(false)
  }

  function addWorkingDays(date, numDays) {
    const startDate = new Date(date);
    const endDate = new Date(startDate);
    let count = 1;
    let datelist = [dateChanger(startDate)]
    while (count < numDays) {
      endDate.setDate(endDate.getDate() + 1); // Increment the date by 1 day
      if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
        // If the day is not a Sunday (0) or Saturday (6)
        datelist.push(dateChanger(endDate))
        count++;
      }
    }
    //console.log(datelist)
    // setShowAddStudentButton(false)
    setCalenderEventDates(datelist)
    return endDate.toISOString().split('T')[0]; // Return the date in "yyyy-mm-dd" format
  }

  function getStartDateMonWedFri(date, days) {
    console.log("hhhhhhhhh", date, days)
    if (new Date().getDay() === 1 || new Date().getDay() === 3 || new Date().getDay() === 5) {
      let result = new Date();
      console.log("the result iis", dateChanger(result))
      setStartDate(dateChanger(result))
      return result

    } else {
      let result = new Date();
      result.setDate(result.getDate() + days);
      console.log("the result is", result)
      return result;
    }
  }

  function getStartDateTueThurSat(date, days) {
    console.log("hhhhhhhhh", date, days)
    if (new Date().getDay() === 2 || new Date().getDay() === 4 || new Date().getDay() === 6) {
      let result = new Date();
      console.log("the result iis", dateChanger(result))
      return result

    } else {
      let result = new Date();
      result.setDate(result.getDate() + days);
      console.log("the result is", result)
      return result;
    }
  }

  function addWorkingDaysTueThursSat(date, numDays) {
    console.log("the batches are", batchData.batchdata)

    const allBatchData = batchData.batchdata
    const dates = dateChanger(new Date())

    // let sk = allBatchData.filter((ele) => {
    //   return ele.Repeat === `Tue-Thur-Sat` && ele.batchProgress != "Cancelled" && ele.batchProgress != "Discontinued"
    // })

    // if (sk.length > 0) {
    //   const startTime = AddDurationToTime(sk[sk.length - 1].EndTime, '15M')
    //   setStartTime(startTime)
    //   const endTime = AddDurationToTime(sk[sk.length - 1].EndTime, '75M')
    //   setEndTime(endTime)
    // } else {
    //   setStartTime("12:30")
    //   setEndTime("13:30")
    // }

    let batches = allBatchData.filter((ele) => {
      return ele.Repeat === `Tue-Thur-Sat` && ele.batchProgress != "Cancelled" && ele.batchProgress != "Discontinued"
    })

    const sortedBatches = batches.sort((a, b) => {
      const timeA = new Date(`1970-01-01T${a.EndTime}:00`);
      const timeB = new Date(`1970-01-01T${b.EndTime}:00`);
      return timeA - timeB; // For descending order
    });

    console.log("0000000002222", sortedBatches)

    if (sortedBatches.length > 0) {
      const startTime = AddDurationToTime(sortedBatches[sortedBatches.length - 1].EndTime, '15M')
      setStartTime(startTime)
      const endTime = AddDurationToTime(sortedBatches[sortedBatches.length - 1].EndTime, '75M')
      setEndTime(endTime)
    } else {
      setStartTime("12:30")
      setEndTime("13:30")
    }


    const startDate = new Date(getStartDateTueThurSat(date, 1));
    const endDate = new Date(startDate);
    setStartDate(startDate.toISOString().split('T')[0])
    let count = 1;
    let datelist = [dateChanger(startDate)]
    while (count < numDays) {
      endDate.setDate(endDate.getDate() + 1);
      // Increment the date by 1 day
      if (endDate.getDay() !== 1 && endDate.getDay() !== 3 && endDate.getDay() !== 5 && endDate.getDay() !== 0) {
        // If the day is not a Sunday (0) or Saturday (6)
        datelist.push(dateChanger(endDate))
        count++;
      }
    }
    console.log(datelist,)
    // setShowAddStudentButton(false)
    setCalenderEventDates(datelist)
    return endDate.toISOString().split('T')[0]; // Return the date in "yyyy-mm-dd" format
  }

  function addSpecificday(date, DAY) {
    console.log("the day is", date, DAY)
    const startDate = new Date(date);
    const endDate = new Date(startDate);
    console.log("ttt is", startDate, endDate)
    let count = 1;
    let datelist = [dateChanger(startDate)]
    while (count < DAY) {
      console.log("th end date is", startDate.getDay())
      endDate.setDate(endDate.getDate() + 1); // Increment the date by 1 day
      if (endDate.getDay() === startDate.getDay()) {
        // If the day is a Specific day (2)
        datelist.push(dateChanger(endDate))
        count++;
      }
    }
    console.log("the datelist is", datelist)
    // setShowAddStudentButton(false)
    setCalenderEventDates(datelist)
    return endDate.toISOString().split('T')[0]; // Return the date in "yyyy-mm-dd" format
  }

  function addWorkingDaysMonWedFri(date, numDays) {

    console.log("it comes inside the addworkingDaysMonWedFri the batches are", batchData.batchdata)

    const allBatchData = batchData.batchdata
    const dates = dateChanger(new Date())

    let batches = allBatchData.filter((ele) => {
      return ele.Repeat === `Mon-Wed-Fri` && ele.batchProgress != "Cancelled" && ele.batchProgress != "Discontinued"
    })

    const sortedBatches = batches.sort((a, b) => {
      const timeA = new Date(`1970-01-01T${a.EndTime}:00`);
      const timeB = new Date(`1970-01-01T${b.EndTime}:00`);
      return timeA - timeB; // For descending order
    });

    console.log("00000000000", sortedBatches)

    if (sortedBatches.length > 0) {
      const time = new Date(`1970-01-01T${batches[batches.length - 1].EndTime}:00`)
      // Create a Date object for January 1st, 1970
      const specificDate = new Date('1970-01-01T00:00:00Z');

      // Set the time to 9:00 PM (21:00)
      specificDate.setHours(21);   // 9 PM
      specificDate.setMinutes(0);  // 00 minutes
      specificDate.setSeconds(0);  // 00 seconds
      specificDate.setMilliseconds(0);  // 000 milliseconds

      // Convert to ISO string to check the result
      const isoString = specificDate.toISOString();

      console.log('Date set to:', specificDate);
      console.log('ISO String:', isoString);

      if (time >= specificDate) {
        setStartTime("12:30")
        setEndTime("13:30")
      } else {
        if (sortedBatches.length > 0) {
          const startTime = AddDurationToTime(sortedBatches[sortedBatches.length - 1].EndTime, '15M')
          setStartTime(startTime)
          const endTime = AddDurationToTime(sortedBatches[sortedBatches.length - 1].EndTime, '75M')
          setEndTime(endTime)
        } else {
          setStartTime("12:30")
          setEndTime("13:30")
        }
      }
    }





    const startDate = new Date(getStartDateMonWedFri(date, 1));
    const endDate = new Date(startDate);
    setStartDate(startDate.toISOString().split('T')[0])
    let count = 1;
    let datelist = [dateChanger(startDate)]
    while (count < numDays) {
      endDate.setDate(endDate.getDate() + 1);
      // Increment the date by 1 day
      if (endDate.getDay() !== 2 && endDate.getDay() !== 4 && endDate.getDay() !== 6 && endDate.getDay() !== 0) {
        // If the day is not a Sunday (0) or Saturday (6)
        datelist.push(dateChanger(endDate))
        count++;
      }
    }
    console.log(datelist)
    // setShowAddStudentButton(false)
    setCalenderEventDates(datelist)
    return endDate.toISOString().split('T')[0]; // Return the date in "yyyy-mm-dd" format
  }

  function addSpecificday(date, DAY) {
    const startDate = new Date(date);
    const endDate = new Date(startDate);
    let count = 1;
    let datelist = [dateChanger(startDate)]
    while (count < DAY) {
      endDate.setDate(endDate.getDate() + 1); // Increment the date by 1 day
      if (endDate.getDay() === startDate.getDay()) {
        // If the day is a Specific day (2)
        datelist.push(dateChanger(endDate))
        count++;
      }
    }
    // setShowAddStudentButton(false)
    setCalenderEventDates(datelist)
    return endDate.toISOString().split('T')[0]; // Return the date in "yyyy-mm-dd" format
  }

  const ShowInCalender = (calenderEventDatesforcalender) => {
    setShowAddStudentButton(true)
    doesRangeOverlapWithPreviousData(startdate, enddate, starttime, endtime)
    const coursedfulldata = courseOptions.find(obj => obj.course_name === course);
    let events = []
    for (let i = 0; i < coursedata.total_class; i++) {
      events.push({
        title: coursedfulldata.course_name + "(" + (coursedata.number_of_batches + 1).toString() + ")" + "(" + (i + 1).toString() + ")",
        start: calenderEventDatesforcalender[i] + "T" + starttime + ":00",
        end: calenderEventDatesforcalender[i] + "T" + endtime + ":00",
        scheduleNumber: i,
      })
    }
    setCalenderEvents(events.concat(prevEvents))
    return events
  }
  const ShowdefaultDateInCalender = () => {
    const coursedfulldata = courseOptions.find(obj => obj.course_name === course);
    let events = []
    for (let i = 0; i < Customdatelist.length; i++) {
      events.push({
        title: coursedfulldata.course_name + "(" + (coursedata.number_of_batches + 1).toString() + ")" + "(" + (i + 1).toString() + ")",
        start: Customdatelist[i]["startDateTime"],
        end: Customdatelist[i]["endDateTime"],
        scheduleNumber: parseInt(i) + 1,
      })
    }
    setCalenderEvents(events.concat(prevEvents))
    return events
  }
  const setDatainCorrectFormatForStoring = (meetlink) => {

    const response = ShowInCalender(calenderEventDates)
    let changeddata = []
    for (let i = 0; i < response.length; i++) {
      changeddata.push({
        startDateTime: response[i]["start"],
        endDateTime: response[i]["end"],
        scheduleNumber: response[i]["scheduleNumber"] + 1,
        status: "Scheduled",
        meet_link: meetlink
      })
    }
    return changeddata
  }
  const setDefaultDatainCorrectFormatForStoring = () => {
    let events = Customdatelist
    for (let i = 0; i < Customdatelist.length; i++) { events[i]["scheduleNumber"] = i + 1 }
    return events
  }

  const NavigatetoStudentAddPage = (meetLink) => {


    console.log("the generated Mettlink is", meetLink)
    setMeetLink(meetLink)
    const changeddata = Customdatelist.length === 0 ? setDatainCorrectFormatForStoring(meetLink) : setDefaultDatainCorrectFormatForStoring()
    // const userdata = JSON.parse(sessionStorage.getItem('UserData'))
    const userdata = JSON.parse(sessionStorage.getItem('SelectedMentor'));
    const coursedfulldata = courseOptions.find(obj => obj.course_name === course);
    const params = {
      mentorId: userdata.user_id,
      // BatchName: coursedfulldata.course_name + "-" + coursedfulldata.course_id + "-" + (coursedata.number_of_batches + 1),
      BatchName: coursedfulldata.course_name + "-" + (coursedata.number_of_batches + 1),
      BatchId: coursedfulldata.course_id + "-" + (coursedata.number_of_batches + 1),
      batchProgress: "Scheduled",
      courseId: coursedfulldata.course_id,
      courseName: coursedfulldata.course_name,
      EndDate: enddate,
      mentorName: userdata.user_name,
      StartDate: startdate,
      StartTime: starttime,
      EndTime: endtime,
      Repeat: recurrent,
      ClassSchedule: changeddata
    }
    navigate("/codomo/admin/addstudent", { state: { data: params } })
  }

  function scheduleUsingCustomDates() {
    console.log("custom batch is", batchData.batchdata)
    console.log("custom the startdate is",)
    setCustomStartTime(starttime)
    setCustomDate(startdate)
    setCustomBatchShedule(true)

  }

  return (
    <>
      <Header />
      <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container">
          <div className=" grid grid-cols-12 gap-[30px]">
            <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
              <div className="mini-title">New Batch</div>
              {/* <Link to="/googleMeet"><button>GoogleMeet</button></Link> */}
              <h4 className="column-title ">
                Start a Batch <span className="shape-bg">Now</span>
              </h4>
              <div>
                Enhance your scheduling process by choosing a course and seamlessly scheduling it on the available dates for your batch!
              </div>
              <ul className=" list-item space-y-6 pt-8">
                <li className="flex">
                  <div className=" rounded-md w-full">
                    {showeventmenu ?
                      <div className="bg-white shadow-box7 p-8 rounded-md w-full">
                        <div className="fflex-none mr-6 mt-2  focus:ring-0 flex items-center">
                          Course: {course}
                        </div>
                        <div className="fflex-none mr-6 mt-2  focus:ring-0 flex items-center">
                          Total Class : {(coursedata.total_class)}
                        </div>
                        <div className="fflex-none mr-6 mt-2  focus:ring-0 flex items-center">
                          Batch: {(coursedata.number_of_batches + 1)}
                        </div>

                        {CustomBatchShedule ?
                          <div>
                            <div className="mt-4">
                              {Customdatelist.map((data, index) => (
                                <span key={index} className="email-tag">
                                  {data["startDateTime"]}
                                  <span className="remove-tag bg-lightgreen" onClick={() => removeDateFromCustomDateList(data["startDateTime"])}>
                                    &times;
                                  </span>
                                </span>
                              ))}
                            </div>
                            <div className="fflex-none mr-6 mt-4 from-control focus:ring-0 flex items-center">
                              <input value={Customdate} className="from-control-edit focus:ring-0 " required type="date" onChange={(e) => setCustomDate(e.target.value)} />
                              <input value={Customstarttime} className="from-control-edit focus:ring-0 " required type="time" onChange={(e) => { setCustomStartTime(e.target.value); setCustomEndTime(AddDurationToTime(e.target.value, coursedata.class_duration)) }} />
                              {Customdatelist.length < coursedata.total_class ? <div className="resend-otp" onClick={IncludeInCustomDateList}>Add</div> : ""}
                            </div>
                            <button className="btn btn-black mt-4 mr-4" onClick={ShowdefaultDateInCalender}>Show In Calender</button>
                            {Customdatelist.length < coursedata.total_class ? "" : <button className="btn btn-primary mt-4" onClick={createGoogleMeet}>Add Students</button>}
                          </div>

                          :
                          <div>
                            <div className="fflex-none mr-6 mt-4 from-control focus:ring-0 flex items-center">
                              <input value={startdate} className="from-control-edit focus:ring-0 " required type="date"
                                onChange={(e) => {
                                  setStartDate(e.target.value);
                                  setEndDate(recurrent === "Mon-Wed-Fri" ? addWorkingDaysMonWedFri(e.target.value, coursedata.total_class) : addWorkingDaysTueThursSat(e.target.value, coursedata.total_class))
                                }} readOnly /> {' - '}
                              <input value={enddate} className="from-control-edit focus:ring-0 " required type="date" onChange={(e) => setEndDate(e.target.value)} readOnly />
                            </div>
                            <div className="fflex-none mr-6 mt-4 from-control focus:ring-0 flex items-center">
                              <input value={starttime} className="from-control-edit focus:ring-0 " required type="time" onChange={(e) => { setStartTime(e.target.value); setEndTime(AddDurationToTime(e.target.value, coursedata.class_duration)) }} readOnly /> {' - '}
                              <input value={endtime} className="from-control-edit focus:ring-0 " required type="time" onChange={(e) => setEndTime(e.target.value)} readOnly />
                            </div>
                            <div className="flex-none mr-6 mt-4 from-control focus:ring-0 flex items-center" >
                              <select
                                className="from-control-edit focus:ring-0"
                                value={recurrent}

                                //----------------------
                                // onChange={(e) => { setRecurrent(e.target.value); setEndDate(e.target.value === "Mon-Fri" ? addWorkingDays(startdate, coursedata.total_class) : addSpecificday(startdate, coursedata.total_class)) }}
                                // required>

                                // <option value="Mon-Fri">Every Weekday (Mon - Fri)</option>
                                // <option value="specific">Weekly on {dayOfWeek(startdate)}</option>
                                //--------------------

                                onChange={(e) => { setRecurrent(e.target.value); setEndDate(e.target.value === "Mon-Wed-Fri" ? addWorkingDaysMonWedFri(startdate, coursedata.total_class) : addWorkingDaysTueThursSat(startdate, coursedata.total_class)) }}
                                required>
                                <option>Select Days for classes</option>
                                <option value="Mon-Wed-Fri">Every Week (Mon, Wed, Fri)</option>
                                <option value="Tue-Thur-Sat">Every Week (Tues, Thurs, Sat)</option>
                              </select>
                            </div>
                            <div className="resend-otp mt-4" onClick={scheduleUsingCustomDates}>Schedule Batch on Custom dates</div>
                            <button className="btn btn-black mt-4 mr-4" onClick={() => ShowInCalender(calenderEventDates)}>Show In Calender</button>
                            {showAddStudentButton ? <button className="btn btn-primary mt-4" onClick={createGoogleMeet}>Add Students</button> : ""}
                          </div>
                        }
                      </div>
                      :
                      <div className="flex-none mr-6 from-control-batch focus:ring-0 flex items-center" >
                        <select
                          className="from-control-batch-edit focus:ring-0"
                          value={course}
                          onChange={(e) => setCourse(e.target.value)}
                          required>
                          <option value="">Select Course</option>
                          {courseOptions.map((item, index) => <option value={item.course_name}>{item.course_name}</option>)}
                        </select>
                        {loading ?
                          <ClassicSpinner size={25} color="grey" loading={loading} />
                          : <iconify-icon icon="material-symbols:arrow-circle-right-outline" style={{ fontSize: '2em' }} onClick={course && fetchCourseDetails} />
                        }
                      </div>}
                  </div>
                </li>
              </ul>
            </div>

          </div>
          <div className="xl:col-span-7 lg:col-span-6 col-span-12">
            <div className="bg-white shadow-box7 p-8 rounded-md">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                  center: "dayGridMonth,timeGridWeek",
                }}
                weekends={true}
                selectable={true}
                select={(clickInfo) => handleDateSelect(clickInfo)}
                dayMaxEvents={0}
                events={calenderEvents}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBatch;

