import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import { FetchBatchData} from "../../api/aws-api";
import {ConstructorEventGet, ConstructorEventPost} from '../../Event/event';
import { AddNewBlog } from "../../api/aws-api";
import { toast } from 'react-toastify';



const AddBlog = () => {
    const [blogHeading, setBlogHeading] = useState("");
    const [blogBody, setBlogBody] = useState("");
    const [batchoptions,setBatchoptions] = useState([]);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const notify = (message) => toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      pauseOnHover: true,
      progress: undefined,
      theme: "dark",
      });;

    const handleSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        const Params = {
          "Heading": blogHeading,
          "Content": blogBody
        }
        const responsedata = await ConstructorEventPost (AddNewBlog,Params)
        console.log(Params,responsedata,"blog")
        if (responsedata === "Blog_published_succesfully"){setLoading(false);notify("Blog Published Succesfully")}
      };
    return (
        <>
        <Header />
        <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container">
          <div className=" grid grid-cols-12 gap-[30px]">
            <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
              <div className="mini-title">Blog</div>
              <h4 className="column-title ">
                Add New Blog
              </h4>
              <div>
              Add new Blog which is to be listed in Blog menu
              </div>
              <ul className=" list-item space-y-6 pt-8">
                <li className="flex">
                <div className="xl:col-span-7 lg:col-span-6 col-span-12">
              <div className=" rounded-md">
              
                </div>
                </div>
                </li>
              </ul>
            </div>
            <div className="xl:col-span-7 lg:col-span-6 col-span-12">
            <div className="bg-white shadow-box7 p-8 rounded-md">
            <form
                onSubmit={handleSubmit}
                className=" md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6 "
                >
                <div className="md:col-span-2 col-span-1 input-container">
                  <input
                    type="text"
                    className="from-control"
                    value={blogHeading}
                    onChange={(e) => setBlogHeading(e.target.value)}
                    required
                  />
                  <span className={`input-placeholder ${blogHeading && 'active'}`}>
                    Blog Heading *
                  </span>
                </div>

                <div className="md:col-span-2 col-span-1 input-container">
                    <textarea
                    className=" from-control"
                    rows="3"
                    value={blogBody}
                    onChange={(e) => setBlogBody(e.target.value)}
                    required
                    ></textarea>
                    <span className={`input-placeholder ${blogBody && 'active'}`}>
                    Blog Content*
                    </span>
                </div>
                <button className="btn btn-primary mt-[10px]" type="submit" name="submit">
                    {loading ? 'Publishing..' : 'Publish Blog'}
                </button>
                </form>
                </div>
              </div>
          </div>
        </div>
      </div>
        </>
    );
    };

export default AddBlog;