import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import Header from "../Header";
import { FetchStudentDetails } from "../../api/aws-api";
import { ConstructorEventGet } from "../../Event/event";
import { ClassicSpinner } from "react-spinners-kit";

const AccountsManagement = () => {
    const [names, setNames] = useState([]);
    const [selectedName, setSelectedName] = useState('');
    const [courseNames, setCourseNames] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [accountDetails, setAccountDetails] = useState('');
    const [loading, setLoading] = useState(false);
    const [userdata, setUserData] = useState([]);

    const fetchDetailsForAdmin = async () => {
        setLoading(true);
        const response = await ConstructorEventGet(FetchStudentDetails);
        setLoading(false);
        return response;
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const responsedata = await fetchDetailsForAdmin();
        setUserData(responsedata);
        setLoading(false);
        console.log(responsedata);

        let newtest = responsedata.map(item => item.name);
        setNames(newtest);
        console.log(newtest);
    };

    useEffect(() => {
        if (selectedName) {
            const selectedUserData = userdata.find(user => user.name === selectedName);
            if (selectedUserData && selectedUserData.course) {
                setCourseNames(selectedUserData.course);
            } else {
                setCourseNames([]);
            }
        } else {
            setCourseNames([]);
            setSelectedCourse('');
            setAccountDetails('');
        }
    }, [selectedName, userdata]);

    useEffect(() => {
        if (selectedCourse) {
            // Fetch account details or other related data if needed
        } else {
            setAccountDetails('');
        }
    }, [selectedCourse]);

    return (
        <>
            <Header />
            {loading ? (
                <div className="spinner">
                    <div className="spinner-wrapper">
                        <ClassicSpinner size={50} color="black" loading={loading} />
                    </div>
                </div>
            ) : (
                <Container className="nav-tab-wrapper tabs section-padding">
                    <Row className="justify-content-center">
                        <Col xs={12} md={8}>
                            <h2 className="text-center" style={styles.header}>Accounts Management</h2>
                            <Form.Group controlId="nameSelect" className="mb-3">
                                <Form.Label>Select Name:</Form.Label>
                                <Form.Control as="select" value={selectedName} onChange={(e) => setSelectedName(e.target.value)}>
                                    <option value="">--Select Name--</option>
                                    {names.map((name, index) => (
                                        <option key={index} value={name}>
                                            {name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            {courseNames.length > 0 && (
                                <Form.Group controlId="courseSelect" className="mb-3">
                                    <Form.Label>Select Course Type:</Form.Label>
                                    <Form.Control as="select" value={selectedCourse} onChange={(e) => setSelectedCourse(e.target.value)}>
                                        <option value="">--Select Course Type--</option>
                                        {courseNames.map((course, index) => (
                                            <option key={index} value={course.course_name}>
                                                {course.course_name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            )}

                            {selectedName && selectedCourse && (
                                <div style={styles.accountDetails}>
                                    <h3>Account Details</h3>
                                    <p>{accountDetails || "No account details available."}</p>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

const styles = {
    header: {
        margin: '20px 0',
        fontSize: '24px',
        color: '#333',
        fontWeight: 'bolder',
        boxShadow: "0 0 3px 2px #cec7c759",
        padding: '10px',
        backgroundColor: '#fff',
    },
    accountDetails: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
    }
};

export default AccountsManagement;
