import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom"
import Header from "../Header";
import { Table, Input } from "antd";
import { AddNewUser } from "../../api/aws-api";
import { ConstructorEventPost, ConstructorEventGet } from "../../Event/event";
import { ClassicSpinner, MagicSpinner } from "react-spinners-kit";
import { notifySuccess, notifyError } from "../../Common/CommonFunction";
import Swal from 'sweetalert2'

const CreateNewUser = () => {
  const [loading, setLoading] = useState(false)
  const [userName, setUserName] = useState("")
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [parentname, setparentname] = useState("");
  const [address, setaddress] = useState("");
  const [usertype, setusertype] = useState("student")
  const countryPrefix = '+91';

  const navigate = useNavigate();

  const SubmitDataToCloud = async () => {
    if (userName !== "" && email !== "" && address !== "" && phone !== "") {
      setLoading(true)
      const formdata = {
        "Item": {
          "user_name": userName,
          "user_email": email.toLowerCase(),
          "parent_name": parentname,
          "user_address": address,
          "user_mobile": phone,
          "user_type": usertype,
          "verified": true,
          "googleId": "",
          "course": [],
          "user_status": "active",
          "account": [{ "coursename": "foundation", "totalfees": "4000", "ammountpaid": "2000", "remainingamount": "totalfees-amountpaid" }]
        }
      }

      const responsedata = await ConstructorEventPost(AddNewUser, formdata)
      if (responsedata[0] === "user_registered_verified") { notifySuccess("User Created SuccessFully"); navigate("/codomo/admin/usermanagement") }
      else if (responsedata === "user_emailOrphone_already_exists") { setLoading(false); notifyError("Email or Phone Number already exists") }
      else if (responsedata === "emailOrphone_not_provided") { setLoading(false); notifyError("Please Provide Valid Email and Phone Number") }
      setLoading(false)
    }
    else { notifyError("Please Enter All Required Fields") }
  }

  return (
    <>
      <Header />
      {loading ?
        <div className="spinner" >
          <div className="spinner-wrapper">
            <ClassicSpinner size={50} color="black" loading={loading} />
          </div></div> :
        <div className="nav-tab-wrapper tabs  section-padding">
          <div className="container">
            <div className="mini-title">Create New User</div>
            <div className=" grid grid-cols-12 gap-[30px] bg-white shadow-box7 p-8 rounded-md mt-8">
              <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                <input
                  type="text"
                  className="from-control"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required
                />
                <span className={`input-placeholder ${userName && 'active'}`}>
                  Name *
                </span>
              </div>
              <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                <input
                  type="email"
                  className="from-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <span className={`input-placeholder ${email && 'active'}`}>
                  Email *
                </span>
              </div>
              <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                <input
                  type="text"
                  className="from-control"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
                <span className={`input-placeholder ${phone && 'active'}`}>
                  Phone (without Country Code) *
                </span>
              </div>
              <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                <input
                  type="text"
                  className="from-control"
                  value={parentname}
                  onChange={(e) => setparentname(e.target.value)}
                  required
                />
                <span className={`input-placeholder ${parentname && 'active'}`}>
                  Parent name
                </span>
              </div>
              <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                <input
                  type="text"
                  className="from-control"
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                  required
                />
                <span className={`input-placeholder ${address && 'active'}`}>
                  Address *
                </span>
              </div>

              <div className=" xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                <select
                  className="from-control p-4 bg-[#ECECEC] text-[#827878]"
                  value={usertype}
                  onChange={(e) => setusertype(e.target.value)}
                  required
                >
                  <option key="student" value="student">Student</option>
                  <option key="mentor" value="mentor">Mentor</option>
                  <option key="admin" value="admin">Admin</option>

                </select>
              </div>
              <div className=" mt-8 xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                <button className="btn btn-black" onClick={() => navigate("/codomo/admin/usermanagement")} >CANCEL</button>
                <button className="btn ml-4 btn-primary" onClick={SubmitDataToCloud}>SAVE</button>
              </div>
            </div>
          </div>
        </div>}

    </>
  )

}
export default CreateNewUser;