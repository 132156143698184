import React, { useState } from 'react';
// import $ from 'jquery';
import "../../assets/css/newacitivity.css"
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';



function Birthdayactivity() {
    const [questionNumber, setQuestionNumber] = useState(0);
    const [Activitystarted, setActivitystarted] = useState(false);
    const [date, setChangeDate] = useState(new Date());
    const [displaytext, setDisplaytext] = useState("");
    var btn = ('#top');

    // const Questions = {
    //     "1": {
    //         "Question": "Do You like Pets ?",
    //         "options": ["Yes Of Course", "No i donot like"],
    //         "Response": ""
    //     },
    //     "2": {
    //         "Question": "Do You Like Cats Or Dogs ?",
    //         "options": ["I Am A Dog Person", "I Love Cats "],
    //         "Response": ""
    //     }
    // };

    function changeValue(val) {

        setChangeDate(val);
    }

    function navigateToPage() {

        window.location.href = 'http://localhost:3000/';
    }


    function allowDrop(event) {
        event.preventDefault();
    }

    function drag(event) {
        event.dataTransfer.setData("text/plain", event.target.outerHTML);
    }


    function drop(event) {
        event.preventDefault();
        var data = event.dataTransfer.getData("text/plain");
        var draggedItem = new DOMParser().parseFromString(data, "text/html").body.firstChild;
        event.target.appendChild(draggedItem);
    }
    function bday() {
        setQuestionNumber(questionNumber + 1)
        let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const TodaysDate = (new Date).toLocaleDateString(undefined, options)
        const CheckDate = date.toLocaleDateString(undefined, options)

        if (TodaysDate == CheckDate) {
            setDisplaytext("Happy   Birthday")
        } else if (TodaysDate < CheckDate) {
            setDisplaytext("It's not your birthday yet this year !")
        } else {
            setDisplaytext("It Was Already Your Birthday This Year")
        }
    }

    return (
        <div className='Newbody ' style={{overflow : "hidden"}}>
            <div className='Newheader container'>
                <div className='grid grid-cols-12 mt-8'>
                    <div className=' lg:col-span-3 col-span-12'>
                        <div className="design-vew22 ">
                            <div className="fontq ">
                                {questionNumber == 0 ?
                                    <>
                                        <h5>Choose Your Birthday!</h5>
                                        <div className='react-calenderdate'>
                                            <Calendar onChange={changeValue} value={date} />
                                            <p>The selected date is - {date.toLocaleDateString()}</p>
                                        </div>

                                        <button className='button-86' onClick={Activitystarted ? bday : null}>Check</button>
                                    </>
                                    :
                                    <div className='Acitivityon'>{displaytext}</div>

                                }
                            </div>
                        </div>
                        {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}
                            <button className="button-12 mr-2" style={{ textAlign: "center" }} onClick={() => Activitystarted ? (setQuestionNumber(0), setActivitystarted(false)) : setActivitystarted(true)}>{Activitystarted ? "RESET" : "RUN"}</button>
                        {/* </div> */}
                    </div>


                    <div class="lg:col-span-9 col-span-12">
                        <div className='whiteboard'>
                            <div class="headerwhitebox"></div>
                            <h4 class="rightheading">Is it Your Birthday  </h4>
                            <div class="rightpaneul">
                                Try out this app that tells you whether or not it is your birthday
                                <ul >

                                    <li>1. What input does this app use?</li>
                                    <li>2. What output does it display?</li>
                                    <li>3. How does it decide which output message to display?</li>
                                    <hr></hr>
                                    <hr></hr>
                                    <hr></hr>
                                    <li>

                                        To start using the app,press the "Run" button</li>

                                    <hr></hr>

                                </ul>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    );
}

export default Birthdayactivity;

