import React, { useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CircleSpinner} from "react-spinners-kit";
import { ConstructorEventPost } from "../../Event/event";
import { AddNewUser } from "../../api/aws-api";
import { notifyError } from "../../Common/CommonFunction";


const SignUpForm = (props) => {
  const [phone, setPhone] = useState("");
  const [parentName,setParentName] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async(e) => {
    setLoading(true);
    e.preventDefault();
    const formdata = {"Item" :{ 
      "user_name" : props.name,
      "user_email" : props.email.toLowerCase(),
      "parent_name" : parentName,
      "user_address" : address,
      "user_mobile" : phone,
      "user_type" : "student",
      "verified" : props.usedGoogle,
      "googleId" : props.googleId,
      "user_status" : "active"
    }}
    
    const responsedata = await ConstructorEventPost(AddNewUser,formdata)
    if (responsedata[0] === "user_registered_verified") {
      navigate("/codomo/home");localStorage.setItem('authToken',responsedata[2]);localStorage.setItem('lastLoginTime', Date.now())
      setLoading(false);localStorage.setItem('UserKey', JSON.stringify({user_id : responsedata[1]}))
    }
    else if (responsedata[0] === "user_registered"){setLoading(false);
      localStorage.setItem('UserKey', JSON.stringify({user_id : responsedata[1]}));navigate("/confirmemail")}
    else if (responsedata === "user_emailOrphone_already_exists"){setLoading(false);notifyError("Email or Phone Number already exists")}
    else if (responsedata === "emailOrphone_not_provided"){setLoading(false);notifyError("Please Provide Valid Email and Phone Number")}
  };
  const countryPrefix = '+91'; // Replace with the desired country code


  return (
    <form
      onSubmit={handleSubmit}
      className=" md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6 "
    >
      <div className="input-container">
        <input
          type="text"
          className="from-control"
          value={props.name}
          onChange={(e) => props.setName(e.target.value)}
          required
        />
        <span className={`input-placeholder ${props.name && 'active'}`}>
          Name *
        </span>
      </div>

      
      <div className="input-container">
        <input
          type="email"
          className=" from-control"
          value={props.email}
          onChange={(e) => props.setEmail(e.target.value)}
          required
        />
        <span className={`input-placeholder ${props.email && 'active'}`}>
          Email *
        </span>
      </div>
      <div className="input-container-phone">
      {phone ? <span class="input-group-addon">{phone && countryPrefix}</span> : "" }
        <input
          type="text"
          className={`from-control-phone ${!phone && 'pl-5'}`}
          value={phone}
          pattern="[0-9]{10}"
          onChange={(e) => setPhone(e.target.value) }
          required
        />
        <span className={`input-placeholder ${phone && 'active'}`}>
        Phone *
        </span>
      </div>
      
      <div className="input-container">
        <input
          type="text"
          className=" from-control"
          value={parentName}
          onChange={(e) => setParentName(e.target.value)}
        />
        <span className={`input-placeholder ${parentName && 'active'}`}>
        Parent name (optional)
        </span>
      </div>
      <div className="md:col-span-2 col-span-1 input-container">
        <textarea
          className=" from-control"
          rows="3"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
        ></textarea>
          <span className={`input-placeholder ${address && 'active'}`}>
          Address *
        </span>
      </div>
      <button className="btn btn-primary " type="submit" name="submit" disabled={loading}>
        {loading ? 
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircleSpinner size={25} color ="white" loading={loading} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}/>
          </div>
        :
        "Start Learning"
      }

      </button>
    </form>
  );
};

export default SignUpForm;
