import React, { useState } from 'react';
// import $ from 'jquery';
import "../../assets/css/newacitivity.css"

function NewCourseActvitythree() {
  const [questionNumber, setQuestionNumber] = useState(1);
  const [words, setWords] = useState({})
  const [Activitystarted, setActivitystarted] = useState(false)
  var btn = ('#top');

  const Questions = {
    "3": `"my name is ${words["name"]}.I am the ruler of ${words["city"]}. I am always ${words["emotion"]}.I have a ${words["colour"]}suit which shoots laser.If iam bored i will eat my ${words["food"]}"`,
    "4": `"The dusty streets of ${words["city"]} were lined with wooden buildings, their facades painted a rustic shade of ${words["colour"]}. Each morning, ${words["name"]} would stroll down Main Street, tipping the hat to the locals, who always greeted with a warm smile.${words["name"]} loves his ${words["food"]}.${words["name"]} is always ${words["emotion"]}"`,
  };
  function navigateToPage() {

    window.location.href = 'http://localhost:3000/';
  }


  function allowDrop(event) {
    event.preventDefault();
  }

  function drag(event) {
    event.dataTransfer.setData("text/plain", event.target.outerHTML);
  }


  function drop(event) {
    event.preventDefault();
    var data = event.dataTransfer.getData("text/plain");
    var draggedItem = new DOMParser().parseFromString(data, "text/html").body.firstChild;
    event.target.appendChild(draggedItem);
  }

  const setResultToState = (AttributeName, value) => {
    let temp = words
    words[AttributeName] = value
    setWords(temp)
  }

  return (
    <div className='Newbody' style={{ overflow: 'hidden' }}>
      {/* <div className='Newheader'> */}
        <div className="container" >
          <div className='grid grid-cols-12'>
            <div className="lg:col-span-3 col-span-12 ">
              <div className={questionNumber < 5 ? "design-vew22" : "design-vew2"}  >
                {/* <div className=' mt-4'> */}
                  <div className='Acitivityone'>
                    <div>

                      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap" />
                      <link rel="stylesheet" href="styles.css" />
                      {questionNumber == 5 ? <h3 className='sucesstext'>Sucessfully Completed....!!</h3>

                        : questionNumber == 1 ?
                          <div className='arcade-text'>
                            <h3>"Let's play "</h3>
                          </div>
                          : questionNumber == 2 ?
                            <div className='fontr container'>

                              <label >
                                Name
                              </label>
                              <input type="text" className="form-control " placeholder="Type Your Name" onChange={(e) => setResultToState("name", e.target.value)} />

                              <label >
                                Colour
                              </label>
                              <input type="text" className="form-control" placeholder="Type Your colour" onChange={(e) => setResultToState("colour", e.target.value)} />


                              <label >
                                Food
                              </label>
                              <input type="text" className="form-control" placeholder="Type Your food" onChange={(e) => setResultToState("food", e.target.value)} />
                              <label >
                                Emotion
                              </label>
                              <input type="text" className="form-control" placeholder="Type Your emotion" onChange={(e) => setResultToState("emotion", e.target.value)} />

                              <label >
                                City
                              </label>
                              <input type="text" className="form-control" placeholder="Type Your city" onChange={(e) => setResultToState("city", e.target.value)} />
                            </div>

                            : ""}

                      <div id='content'>
                        {questionNumber == 1 ?
                          <>
                            <button class="button-86 mr-2 p-3 w175 mt-8 button-gap-t" role="button" onClick={() => Activitystarted && setQuestionNumber(questionNumber + 1)}>Press Here </button>

                          </>
                          :
                          questionNumber == 2 ?
                            <>
                              <button className="button-81 mr-2 p-3 w175 mt-8 button-gap" onClick={() => setQuestionNumber(questionNumber + 1)}>SCI FI</button>
                              <button className="button-81 mr-2 p-3 w175 mt-8 button-gap" onClick={() => setQuestionNumber(questionNumber + 2)}>WESTERN</button>

                            </>

                            :
                            questionNumber == 3 ?
                              <>
                                <div class="arcade-text">{Questions[3]}</div>
                                <iframe className='button-gap-2' src="https://giphy.com/embed/Vs0EhKabE54Lm" width="200" height="200" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
                              </>
                              :
                              questionNumber == 4 ?
                                <>
                                  <div class="arcade-text2">{Questions[4]}</div>
                                  {/* <iframe className='"button-gap-2' src="https://giphy.com/embed/UqdSupDxiAdXsmHiW5" width="210" height="200" frameBorder="0" class="giphy-embed" allowFullScreen></iframe> */}
                                </>


                                : ""}
                      </div>
                    </div>
                    
                    {/* <img src="/images/mobile.png" class="img-fluid" /> */}
                    {/* <img src="/images/design.png" class="img-fluid" /> */}
                    {/* </div> */}
                    {/* <div class="nav-controls justify-content-between align-items-center"> */}
                    {/* <button className="runbttn mr-3 ">RUN</button> */}
                    {/* <a href="#"><img src={require("./images/play-icon.png")} /> </a> */}
                    {/* <a href="#"><img src={require("./images/run-icon.png")} /> </a> */}
                    {/* <a href="#"><img src={require("./images/Pause-icon.png")} /> </a> */}
                    {/* <a href="#"><img src={require("./images/stop-icon.png")} /> </a> */}
                  </div>
                  {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}
                  {/* <a href="#"><img src={require("./images/left-arrow.png")} width="35" /> </a> */}
                  {/* <a href="#"><img src={require("./images/right-arrow.png")} width="35" /> </a> */}
                  {/* <button className="runbttn mr-3 " style={{ textAlign: "center" }} onClick={() => Activitystarted ? (setQuestionNumber(1), setActivitystarted(false)) : setActivitystarted(true)}>{Activitystarted ? "RESET" : "RUN"}</button> */}
                  {/* <a href="#"><img src={require("./images/reset3.png")} width="40" /> </a> */}

                  {/* <a href="#"><img src={require("./images/bottom-arrow.png")} width="40" /> </a> */}


                  {/* </div> */}
                  {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}

                  {/* <button className="runbttn mr-3 " style={{ textAlign: "center" }} onClick={() => setQuestionNumber(5)}>FINISH</button> */}

                  {/* </div> */}
                {/* </div> */}
                
              </div>
              <button className="button-12 " style={{ textAlign: "center" }} onClick={() => Activitystarted ? (setQuestionNumber(1), setActivitystarted(false)) : setActivitystarted(true)}>{Activitystarted ? "RESET" : "RUN"}</button>
          <button className="button-12 " style={{ textAlign: "center" }} onClick={() => setQuestionNumber(5)}>FINISH</button>
            </div>
            <div className='whiteboard'>
              <div class="lg:col-span-9 col-span-12">
                <div class="headerwhitebox">Instructions</div>
                <h4 class="rightheading">Questionare</h4>
                <div class="rightpaneul">
                  Try Out this App that will give you suggestions on how to spend your freetime.
                  <ul >
                    <li>What are the Inputs?</li>
                    <li>What are the Outputs?</li>
                  </ul>
                </div>
              </div>
              
            </div>
            
          </div>
          {/* <button className="button-12 mb-2 " style={{ textAlign: "center" }} onClick={() => Activitystarted ? (setQuestionNumber(1), setActivitystarted(false)) : setActivitystarted(true)}>{Activitystarted ? "RESET" : "RUN"}</button> */}
          {/* <button className="button-12 " style={{ textAlign: "center" }} onClick={() => setQuestionNumber(5)}>FINISH</button> */}
        </div>

      

      {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}
        {/* <a href="#"><img src={require("./images/left-arrow.png")} width="35" /> </a> */}
        {/* <a href="#"><img src={require("./images/right-arrow.png")} width="35" /> </a> */}
        {/* <button className="runbttn mr-3 " style={{ textAlign: "center" }} onClick={() => Activitystarted ? (setQuestionNumber(1), setActivitystarted(false)) : setActivitystarted(true)}>{Activitystarted ? "RESET" : "RUN"}</button> */}
        {/* <a href="#"><img src={require("./images/reset3.png")} width="40" /> </a> */}

        {/* <a href="#"><img src={require("./images/bottom-arrow.png")} width="40" /> </a> */}


      {/* </div> */}
      {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}

        {/* <button className="runbttn mr-3 " style={{ textAlign: "center" }} onClick={() => setQuestionNumber(5)}>FINISH</button> */}

      {/* </div> */}


    </div >
  );
}

export default NewCourseActvitythree;