import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header";
import { FetchBatchData, updateBatchStatus } from "../../api/aws-api";
import { ConstructorEventGet, ConstructorEventPost } from "../../Event/event";
import { ClassicSpinner } from "react-spinners-kit";
import Swal from 'sweetalert2'
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';


const MentorActivities = () => {
  const [batch, setBatch] = useState([])
  const [loading, setLoading] = useState(false)
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [status, setStatus] = useState("Scheduled")
  const navigate = useNavigate()
  useEffect(() => {
    FetchBatchList()
  }, [])

  const FetchBatchList = async () => {
    setLoading(true)
    const id = JSON.parse(localStorage.getItem('UserKey'))["user_id"];
    const responsedata = await ConstructorEventGet(FetchBatchData, { "mentorId": id })
    console.log(responsedata)
    setBatch(responsedata ? responsedata : [])
    setLoading(false)
  }

  return (
    <>
      <Header />
      {loading ? (
        <div className="spinner">
          <div className="spinner-wrapper">
            <ClassicSpinner size={50} color="black" loading={loading} />
          </div>
        </div>
      ) : (
        <div className="section-padding">
          <div className="container">
            <div className="text-center">
              <div className="mini-title">Batches</div>
              <div className="column-title">
                Choose <span className="shape-bg">Preferred</span> Batch
              </div>
            </div>
            <div className="flex gap-[30px]">
              <div className="input-container">
                <select
                  className="from-control-status p-4 mr-2 bg-[#ECECEC] text-[#827878]"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  required
                >
                  <option key="Scheduled" value="Scheduled">Scheduled</option>
                  <option key="InProgress" value="InProgress">InProgress</option>
                  <option key="Completed" value="Completed">Completed</option>
                  <option key="Cancelled" value="Cancelled">Cancelled</option>
                </select>

                {/* <select
                  className="from-control-status p-4 bg-[#ECECEC] text-[#827878]"
                  required
                  value={selectedMentor}
                  onChange={(event) => {
                    setSelectedMentor(event.target.value);
                  }}>
                  <option value="">Select Mentor</option>
                  {mentor.map((item) => (
                    <option key={item.user_id} value={item.user_id}>
                      {item.user_name}
                    </option>
                  ))}
                </select> */}
              </div>
              {/* <Link
                className="btn btn-primary rounded-full right-0 pl-4"
                to="/codomo/admin/addbatch"
                state={{ "batchdata" : batch }}
              >
                <iconify-icon icon="uiw:usergroup-add" style={{ fontSize: '1em' }} /> Add new batch
              </Link> */}
            </div>

            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">
              {Array.isArray(batch) && batch.length > 0 ? (
                batch.map((item, index) => (
                  item.batchProgress === status ? (
                    <Link
                      to="/codomo/mentor/editbatch"
                      state={{ "batch": item }}
                      key={index}
                      value={console.log('data',item)}
                    >
                      <div
                        className="rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8] text-center hover:-translate-y-2 pt-4"
                      >
                        <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                          <iconify-icon icon={status === 'Completed' ? 'fluent-mdl2:completed-solid' : status === 'InProgress' ? 'grommet-icons:in-progress' : status === 'Scheduled' ? 'mdi:clipboard-text-date' : 'mdi:clipboard-text-date'} style={{ fontSize: '2em' }} />
                        </div>
                        <Tooltip id={item.BatchId} className="bg-secondary">
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>Students:</p><br />
                            <ul>
                              {item.StudentsEndrolled.map((student, index) => (
                                <li key={index}><b>{student.name}</b></li>
                              ))}
                            </ul>
                          </div>
                        </Tooltip>
                        <div className="course-content">
                          <h4 className="text-2xl mb-2 font-bold">{item.courseName}</h4>
                          <h4 className="text-xl">{item.BatchName.split("-").pop()}</h4>
                          <p>{item.StartDate + " → " + item.EndDate}</p>
                          <p>{item.StartTime + " → " + item.EndTime}</p>
                        </div>
                      </div>
                    </Link>
                  ) : null
                ))
              ) : (
                <div>No batches available</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MentorActivities;

  