import React, { useState } from "react";
import "../../assets/css/newacitivity.css";

function Dropdown() {
  // Define the options for the dropdown
  const options = [
    {
      value:
        'Based On your Preferences, We Think You Should Watch "SPIDER-MAN" ',
      label: "Avengers",
    },
    {
      value:
        'Based On your Preferences, We Think You Should Watch "The Wizard of Oz"',
      label: "Avatar",
    },
    {
      value: 'Based On your Preferences, We Think You Should Watch "Dumbo"',
      label: " The lion King",
    },
    {
      value:
        'Based On your Preferences, We Think You Should Watch "Cinderella"',
      label: " Frozen",
    },
  ];
  const optionstwo = [
    { value: "So You Like Action Movies", label: "Action" },
    { value: "So You Like Fantasy Movies", label: "Fantasy" },
    { value: "So You Like Comedy Movies", label: "Comedy" },
  ];
  const optionsthree = [
    { value: "There You Have It!", label: "Yes" },
    { value: "There You Have It!", label: "No" },
  ];

  // Initialize state to store the selected value
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLanguageone, setSelectedLanguageone] = useState("");
  const [selectedLanguagetwo, setSelectedLanguagetwo] = useState("");
  const [showimage, setShowimage] = useState(false);
  const [activitystarted, setActivitystarted] = useState(false);

  // Event handler for dropdown change
  const handleDropdownChange = (event) => {};

  return (
    <div className="container">
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="design-vew22">
            <div className="dropdownoutfit "></div>
            {showimage == false ? (
              <div className="dropdown-container">
                <h5>Choose a kids Movie: Great For Babysitters</h5>
                <div className="mt-3">
                  <h6>Select your Favorite Movie</h6>
                  <label htmlFor="languageDropdown">Select </label>
                  <select
                    id="languageDropdown"
                    value={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-3">
                  <h6>What are you in the mood for?</h6>
                  <label>Select </label>
                  <select
                    value={selectedLanguageone}
                    onChange={(e) => {
                      setSelectedLanguageone(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <option value="">Select an option</option>
                    {optionstwo.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-3">
                  <h6>Do You Want An Animated Movie</h6>
                  <label htmlFor="languageDropdown">Select </label>
                  <select
                    id="languageDropdown"
                    value={selectedLanguagetwo}
                    onChange={(e) => (
                      setSelectedLanguagetwo(e.target.value),
                      console.log(e.target.value)
                    )}
                  >
                    <option value="">Select an option</option>
                    {optionsthree.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  className="button-86 mt-5 ml-8"
                  onClick={() => setShowimage(true)}
                >
                  generate
                </button>
              </div>
            ) : (
              <div>
                {selectedLanguage}
                {selectedLanguageone}
                {selectedLanguagetwo}
              </div>
            )}
          </div>
          {/* <div className="dropdown-container">
                        <h4>How Many Countries</h4>
                        <div>
                            <label htmlFor="languageDropdown">Select </label>
                            <select
                                id="languageDropdown"
                                value={selectedLanguage}
                                onChange={handleDropdownChange}
                            >
                                <option value="">Select an option</option>
                                {options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='small-box'>
                            {selectedLanguage && (
                                <p> {selectedLanguage}</p>
                            )}
                        </div>
                    </div> */}
          <button
            className="button-12 mr-2"
            onClick={() =>
              activitystarted
                ? (setShowimage(false), setActivitystarted(false))
                : setActivitystarted(true)
            }
          >
            {activitystarted ? "RESET" : "RUN"}
          </button>
        </div>

        <div class="lg:col-span-9 col-span-12">
          <div className="whiteboard">
            <div class="headerwhitebox"></div>
            <h4 class="rightheading">Kids Movie Recommender </h4>
            <div class="rightpaneul">
              <ul>
                <h4>Input:</h4>
                <li>1. Favorite movie</li>
                <li>2. Genre</li>
                <li>3. Animated movie or not?</li>
                <hr></hr>
                <h4>Output</h4>
                <li>Movie recommendation</li>
                <hr></hr>
                <h4>Processing</h4>
                <li>
                  IF they want an animated movie Then Choose From Animated
                  movies Else From the live action movies
                </li>
                <li>MATCH the genre they want with a movie of that genre</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
