import React, { useState, useRef } from "react";
import "../../assets/css/newacitivity.css";
import backgroundImage from "../../assets/images/NewActivityImages/background.jpg";


export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();

  const [questionNumber, setQuestionNumber] = useState(0);
  const [Activitystarted, setActivitystarted] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [ClickedNext, setClickedNext] = useState(false);
  const [dragging, setDragging] = useState(true);
  const [workspaceItems, setWorkspaceItems] = useState([]); // Store the dropped items in the workspace
  const [ValueDropdown, setValueDropdown] = useState("");
  const [selectedColorbutton, setSelectedColorbutton] = useState("red");
  const [selectedpopup, setSelectedpopup] = useState(false);

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  // Handle dropdown change event
  const handleColorChange = (event) => {
    if (Activitystarted == true) {
      setSelectedColor(event.target.value);
    }
  };

  const handleDragStart = (e, itemType) => {
    e.dataTransfer.setData("itemType", itemType);
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemType = e.dataTransfer.getData("itemType");

    if (droppedItemType === "setProperty") {
      const newItem = {
        id: workspaceItems.length + 1, // Unique id
        type: "setProperty",
        selectedColorbutton: "",
        selectedColor: "",
        ValueDropdown: "",
      };

      setWorkspaceItems([...workspaceItems, newItem]); // Add the new item to the workspace
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handlePropertyChange = (e, index, field) => {
    const updatedItems = [...workspaceItems];
    updatedItems[index][field] = e.target.value;
    setWorkspaceItems(updatedItems);
  };

  const divStyle = {
    backgroundColor:
      selectedColorbutton == "Screen" &&
      selectedColor == "background_color" &&
      Activitystarted
        ? ValueDropdown
        : "white",
    height: "121.5%",
    width: "111%",
    borderRadius: "30px",
    marginLeft: "-11px",
    marginTop: "-45px",
    border: selectedpopup ? null : "3px solid black",
    display: "flex",
  };

  const data = {
    Screen: {
      background_color: ["red", "blue", "green"],
    },
    Buttons: {
      "button Color": ["red", "blue", "green"],
    },
    Button1: {
      "button Color": ["red", "blue", "green"],
    },
    Button2: {
      "button Color": ["red", "blue", "green"],
    },
  };

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    setHtmlCode(data);
  }

  return (
    <div className="container" style={{ overflow: "hidden",backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="Newbody">
            <div className="Newheader">
              <div className="design-vew22" ref={getHtmlCode}>
                <div className="toolboxcss" style={divStyle}>
                  <button
                    className="buttonsessionActivity3"
                    style={{
                      backgroundColor:
                        selectedColorbutton === "Button1" && Activitystarted
                          ? selectedColor == "button Color"
                            ? ValueDropdown
                            : "white"
                          : selectedColorbutton == "Buttons" &&
                            selectedColor == "button Color" &&
                            Activitystarted
                          ? ValueDropdown
                          : "white",
                    }}
                  >
                    Make me Red!
                  </button>
                  <button
                    className="buttonsessionActivity3"
                    style={{
                      backgroundColor:
                        selectedColorbutton === "Button2" && Activitystarted
                          ? selectedColor == "button Color"
                            ? ValueDropdown
                            : "white"
                          : selectedColorbutton == "Buttons" &&
                            Activitystarted &&
                            selectedColor == "button Color"
                          ? ValueDropdown
                          : "white",
                    }}
                  >
                    Make me Blue!
                  </button>
                </div>
              </div>
              <button
                className="button-12 mt-2"
                style={{ textAlign: "center" }}
                onClick={() =>
                  Activitystarted
                    ? (setClickedNext(false), setActivitystarted(false))
                    : setActivitystarted(true)
                }
              >
                {Activitystarted ? "RESET" : "RUN"}
              </button>
            </div>
          </div>
        </div>

        <div className="lg:col-span-9 col-span-12 mt-5">
          <i className="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>
          <div className="NavbarApp">&emsp;Instructions</div>
          <div>
            <p>
              <strong>Goal:</strong> Make the buttons "red" and "blue". Then
              change the text in the large orange label.{" "}
            </p>
            <p>
              <strong>How:</strong> Remember you can hover over elements to see
              their "id". Set the "background-color" of the buttons to "red" and
              "blue".
            </p>
          </div>

          <div className="row">
            <div className="col-6 ">
              <div className="box">
                <div className="box-head">
                  &emsp;Toolbox
                  <a className="yellow-btn">
                    <i className="fa-solid fa-minus"></i>
                  </a>
                  {/* <iconify-icon
                    icon="icon-park-twotone:right-c"
                    width="20"
                    className="iconify-icon-right"
                  ></iconify-icon>
                  <iconify-icon
                    icon="ant-design:setting-outlined"
                    width="20"
                    className="iconify-icon-left"
                  ></iconify-icon> */}
                </div>

                <div className="box-content p-3">
                  <div className="controlhead">UI Controls</div>
                  <div>&emsp;</div>
                  <div
                    onDragStart={(e) => handleDragStart(e, "setProperty")}
                    onDragEnd={handleDragEnd}
                  >
                    <div
                      className={selectedpopup ? null : "pin"}
                      draggable="true"
                      id="setProperty"
                    >
                      <div
                        className="divtochange flex"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        setProperty
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-9">
              <div className="box">
                <div className="box-head">
                  &emsp;Workspace
                  <a
                    className="btn "
                    onClick={() => {
                      popupvalue();
                      handleHtmlCode();
                    }}
                  >
                    <button>Show Code</button>
                  </a>
                  <a
                    className="btn me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#show-code-popup"
                  >
                    <button>Version History</button>
                  </a>
                  {selectedpopup && (
                    <div className="modal">
                      <div className="overlay">
                        <div className="modal-content">
                          {htmlCode ? htmlCode : null}
                          <button
                            className="btn"
                            style={{ marginTop: "20px" }}
                            onClick={popupvalue}
                          >
                            close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="box-content p-3"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div className="plug-board work-space" id="drop-zone">
                    {workspaceItems.map((item, index) => (
                      <div key={item.id} className="divtochange flex pin">
                        setProperty
                        <div>
                          (
                          <select
                            className="selectinpin"
                            onChange={(e) =>
                              handlePropertyChange(e, index, "selectedColorbutton")
                            }
                            value={item.selectedColorbutton}
                          >
                            <option value="">Select a Button</option>
                            {Object.keys(data).map((item, idx) => (
                              <option key={idx} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <select
                            className="selectinpin"
                            onChange={(e) =>
                              handlePropertyChange(e, index, "selectedColor")
                            }
                            value={item.selectedColor}
                          >
                            <option value="">Select a Property</option>
                            {item.selectedColorbutton &&
                              Object.keys(data[item.selectedColorbutton]).map(
                                (property, idx) => (
                                  <option key={idx} value={property}>
                                    {property}
                                  </option>
                                )
                              )}
                          </select>
                          <select
                            className="selectinpin"
                            onChange={(e) =>
                              handlePropertyChange(e, index, "ValueDropdown")
                            }
                            value={item.ValueDropdown}
                          >
                            <option value="">Select a Value</option>
                            {item.selectedColorbutton &&
                              item.selectedColor &&
                              data[item.selectedColorbutton][
                                item.selectedColor
                              ]?.map((value, idx) => (
                                <option key={idx} value={value}>
                                  {value}
                                </option>
                              ))}
                          </select>
                          )
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appone;
