import React, { useState, useRef } from "react";
import "../../assets/css/session.css";
import { Uploadhex, DownloadFileFromS3 } from "../../api/aws-api";
import { ConstructorEventPost } from "../../Event/event";
import axios from "axios";
import backgroundImage from "../../assets/images/NewActivityImages/background.jpg";


export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();
  const [Activitystarted, setActivitystarted] = useState(false);
  const [Finalscreen, setFinalscreen] = useState(true);
  const [selectedpopup, setSelectedpopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workspaceItems, setWorkspaceItems] = useState([]);

  // Data for dropdowns
  const data = {
    Label: {
      background_color: ["red", "blue", "green", "yellow"],
      fontSize: ["8", "15", "20", "24", "30"],
    },
    Screen: { background_color: ["red", "blue", "green", "yellow"] },
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.id);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const newWorkspaceItem = {
      id: workspaceItems.length + 1, // unique ID for each dropped item
      screen: "Label", // Default to "Label" to avoid null or undefined
      attribute: "",
      value: "",
    };

    setWorkspaceItems((prevItems) => [...prevItems, newWorkspaceItem]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };


  const handleWorkspaceScreenChange = (e, index) => {
    const updatedItems = [...workspaceItems];
    updatedItems[index].screen = e.target.value || "Label";
    updatedItems[index].attribute = ""; // Reset attribute when screen changes
    updatedItems[index].value = ""; // Reset value when screen changes
    setWorkspaceItems(updatedItems);
  };

  const handleWorkspaceAttributeChange = (e, index) => {
    const updatedItems = [...workspaceItems];
    updatedItems[index].attribute = e.target.value || "";
    updatedItems[index].value = ""; // Reset value when attribute changes
    setWorkspaceItems(updatedItems);
  };

  const handleWorkspaceValueChange = (e, index) => {
    const updatedItems = [...workspaceItems];
    updatedItems[index].value = e.target.value || "";
    setWorkspaceItems(updatedItems);
  };

  const getScreenStyle = () => {
    let style = {};
    workspaceItems.forEach((item) => {
      if (item.attribute === "background_color") {
        style.backgroundColor = item.value;
      }
      if (item.attribute === "fontSize") {
        style.fontSize = `${item.value}px`;
      }
    });
    return style;
  };

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    setHtmlCode(data);
  }

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  return (
    <div className="container" style={{  overflow: "hidden",backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="Newbody">
            <div className="Newheader">
              {Finalscreen === true ? (
                <div className="design-vew22" ref={getHtmlCode}>
                  <div style={{
                    ...getScreenStyle(), height: "121.5%", // Inherit the height from the parent
                    width: "111%",
                    borderRadius: "30px",
                    marginLeft: "-11px",
                    marginTop: "-45px",
                  }}>
                    {Activitystarted ? (
                      <p
                        style={{
                          padding: "20px",
                        }}
                      >
                        Make Me Bigger........
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <button
                className="button-12 mt-2"
                style={{ textAlign: "center" }}
                onClick={() =>
                  Activitystarted
                    ? (setActivitystarted(false), setWorkspaceItems([]))
                    : setActivitystarted(true)
                }
              >
                {Activitystarted ? "RESET" : "RUN"}
              </button>




            </div>
          </div>
        </div>
        <div className="lg:col-span-9 col-span-12 mt-5">
          <i className="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="NavbarApp">&emsp;Instructions</div>
          </div>
          <div>
            <p>
              <strong>Goal:</strong> Make the screen green.{" "}
            </p>
            <p>
              <strong>How:</strong> Drag in a <code>setProperty()</code> block.
              Use the dropdowns to set the screen's{" "}
              <code>"background-color"</code> to <code>"green"</code>. Then hit
              "Run".
            </p>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="box">
                <div className="box-head">
                  &emsp;Toolbox
                  <a className="yellow-btn">
                    <i className="fa-solid fa-minus"></i>
                  </a>
                  {/* <iconify-icon
                    icon="icon-park-twotone:right-c"
                    width="20"
                    className="iconify-icon-right"
                  ></iconify-icon>
                  <iconify-icon
                    icon="ant-design:setting-outlined"
                    width="20"
                    className="iconify-icon-left"
                  ></iconify-icon> */}
                </div>

                <div className="box-content p-3">
                  <div className="controlhead">UI Controls</div>
                  <div>&emsp;</div>
                  <div className={selectedpopup ? null : "pin"} draggable="true" id="setProperty" onDragStart={handleDragStart} >
                    <div
                      className="divtochange flex"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      setProperty
                    </div>
                  </div>
                  <div
                  draggable="true"
                  id="setProperty"
                  onDragStart={handleDragStart}
                >
                  </div>
                </div>
                
              </div>
            </div>

            <div className="col-9">
              <div className="box">
                <div className="box-head">
                  &emsp;Workspace
                  <a
                    className="btn "
                    onClick={() => {
                      popupvalue();
                      handleHtmlCode();
                    }}
                  >
                    <button>Show Code</button>
                  </a>{" "}
                  <a
                    className="btn me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#show-code-popup"
                  >
                    <button>Version History</button>
                  </a>
                  {selectedpopup && (
                    <div className="modal">
                      <div className="overlay">
                        <div className="modal-content">
                          {htmlCode ? htmlCode : null}
                          <button
                            className="btn"
                            style={{ marginTop: "30px" }}
                            onClick={popupvalue}
                          >
                            close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="box-content p-3" onDrop={handleDrop} onDragOver={handleDragOver} style={{ minHeight: "300px" }}>
                  {workspaceItems.map((item, index) => (
                    <div key={item.id} >
                      <div className="divtochange flex pin" style={{ whiteSpace: "nowrap" }}>
                        setProperty
                        <div>
                          (
                          <select
                            className="selectinpin"
                            onChange={(e) =>
                              handleWorkspaceScreenChange(e, index)
                            }
                            value={item.screen}
                          >
                            <option value="Label">Label</option>
                            {Object.keys(data).map((screenKey, idx) => (
                              <option key={idx} value={screenKey}>
                                {screenKey}
                              </option>
                            ))}
                          </select>
                          <select
                            className="selectinpin"
                            onChange={(e) =>
                              handleWorkspaceAttributeChange(e, index)
                            }
                            value={item.attribute}
                          >
                            <option value="">Select an Option</option>
                            {item.screen &&
                              Object.keys(data[item.screen] || {}).map(
                                (attr, idx) => (
                                  <option key={idx} value={attr}>
                                    {attr}
                                  </option>
                                )
                              )}
                          </select>
                          <select
                            className="selectinpin"
                            onChange={(e) =>
                              handleWorkspaceValueChange(e, index)
                            }
                            value={item.value}
                          >
                            <option value="">Select</option>
                            {item.screen &&
                              item.attribute &&
                              data[item.screen][item.attribute]?.map(
                                (val, idx) => (
                                  <option key={idx} value={val}>
                                    {val}
                                  </option>
                                )
                              )}
                          </select>
                          )
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appone;
