import React, { useState ,Fragment } from "react";
import { SwapSpinner } from "react-spinners-kit";
import { AddReview } from "../../api/aws-api";
import { ConstructorEventPost } from "../../Event/event";


const Course_Review = (props) => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState();
  const [star,setStar] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const date = new Date();
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    setLoading(true);
    let params = {course_id:"1"}
    const templateParams = {
      date : formattedDate,
      reviewer_name: name,
      message: message,
      star : star,
      status : false
    };
    params["review"] = templateParams
    const responsedata = await ConstructorEventPost (AddReview,params)
    if (responsedata === "done"){setLoading(false);props.setShowreviewtab(false)}
    console.log(responsedata);
  };
  return (
    <div className=" mt-14">
    <h3 className=" mb-4">Can you describe the highlights of your experience?</h3>
    <div>
    A review from you would mean everything.
    </div>
    <form
        onSubmit={handleSubmit}
      className=" md:grid-cols-1 grid grid-cols-1 gap-[30px] mt-6 "
    >
    <div  >
        <div className="flex space-x-3 ">
            {[1, 2, 3, 4, 5].map((count, index) => (
            <Fragment key={index}>
                <iconify-icon
                icon="heroicons:star-20-solid"
                class={count - star > 0  ? "text-[#E6E6E6]" :"text-tertiary" }
                onClick={()=>setStar(count)}
                style={{ fontSize: '1.5em'}}
                ></iconify-icon>
            </Fragment>
            )) }
        </div>  
      </div>

      <div>
        <input
          type="text"
          className=" from-control rounded-lg "
          placeholder="Name*"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>

      <div className="md:col-span-1 col-span-1">
        <textarea
          className=" from-control rounded-lg"
          placeholder="Your Message*"
          rows="3"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>
      </div>
      {star >= 1 ?  
      <button className="btn btn-primary mt-[10px]" type="submit" name="submit" disabled={loading}>
        {loading ? 
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SwapSpinner  size={35} color ="white" loading={loading} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}/>
          </div>  : 
        'Submit FeedBack'} 
      </button> : "" }
    </form>
    </div>
  );
};

export default Course_Review;

