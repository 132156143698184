import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContactUsEmailSend } from "../../api/aws-api";
import { ConstructorEventPost } from '../../Event/event';
import { toast } from 'react-toastify';


const ContactForm = (props) => {
  // const mentor_mail_data = JSON.parse(process.env.REACT_APP_MENTOR_MAIL_DETAILS);
  useEffect(() => {
    if (props.data) {
      setCourse(props.data.course)
      setMentor(props.data.mentor)


    }
    const storedCourseData = sessionStorage.getItem('CourseKey');
    if (storedCourseData !== "undefined" && storedCourseData !== "null" && storedCourseData !== undefined && storedCourseData !== null) { setCourseoptions(JSON.parse(storedCourseData)) }
    const storedMentorData = sessionStorage.getItem('MentorName');
    if (storedMentorData !== "undefined" && storedMentorData !== "null" && storedMentorData !== undefined && storedMentorData !== null) { setMentoroptions(JSON.parse(storedMentorData)) }
  }, [])
  const [name, setName] = useState("");
  const [email, setEmail] = useState();
  const [course, setCourse] = useState();
  const [courseoptions, setCourseoptions] = useState([]);
  const [mentoroptions, setMentoroptions] = useState([]);
  const [mentor, setMentor] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  console.log("rrrrrrr", mentoroptions)
  const notify = (message) => toast.success(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    pauseOnHover: true,
    progress: undefined,
    theme: "dark",
  });;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const mentorfulldata = mentoroptions.find(obj => obj.user_id === mentor);
    setLoading(true);
    const Params = {
      mentor_name: mentorfulldata.user_name,
      student_name: name,
      course: course,
      message: message,
      student_address: email,
      mentor_address: mentorfulldata.user_email,
      thirdPartyMail: "easabir786@gmail.com"
    };
    const responsedata = await ConstructorEventPost(ContactUsEmailSend, Params)
    if (responsedata === "Done") { setLoading(false); notify("Your message will be forwarded") }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className=" md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6 "
    >
      <div className="input-container">
        <input
          type="text"
          className=" from-control"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <span className={`input-placeholder ${name && 'active'}`}>
          Name*
        </span>
      </div>
      <div className="input-container">
        <input
          type="email"
          className=" from-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <span className={`input-placeholder ${email && 'active'}`}>
          Email*
        </span>
      </div>
      <div className="input-container">
        <select
          className="from-control"
          value={course}
          onChange={(e) => setCourse(e.target.value)}
          required
        >
          <option value=""></option>
          {courseoptions.map((item, index) => (
            <option key={index} value={item.course_id}>
              {item.course_name}
            </option>
          ))}

        </select>
        <span className={`input-placeholder ${course && 'active'}`}>
          Select Course*
        </span>
      </div>
      <div className="input-container">
        <select
          className="from-control"
          value={mentor}
          onChange={(e) => setMentor(e.target.value)}
          required
        >
          <option value=""></option>
          {mentoroptions.map((item, index) => (
            <option key={index} value={item.user_id}>
              {item.user_name}
            </option>
          ))}

        </select>
        <span className={`input-placeholder ${mentor && 'active'}`}>
          Select Mentor*
        </span>
      </div >
      <div className="md:col-span-2 col-span-1 input-container">
        <textarea
          className=" from-control"
          rows="3"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <span className={`input-placeholder ${message && 'active'}`}>
          Your Message*
        </span>
      </div>
      <button className="btn btn-primary mt-[10px]" type="submit" name="submit">
        {loading ? 'sending..' : 'Send Message'}
      </button>
    </form>
  );
};

export default ContactForm;
