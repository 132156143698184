/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useContext } from "react";
import { VerifyEmailId } from "../../api/aws-api";
import { ClassicSpinner } from "react-spinners-kit";
import { ConstructorEventPost } from '../../Event/event';
import {useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';


const EmailConfirm = () => {
    const navigate = useNavigate()
    const [otp, setOtp] = useState('');
    const [proceedLoading,setProceedLoading] = useState(false)
    const [otpsent,setOtpsent] = useState(false)

    const notify = (message) => toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      pauseOnHover: true,
      progress: undefined,
      theme: "light",
      });;

    const fetchdata = async(data) => {
        const storedData = localStorage.getItem('UserKey');
        if (storedData !== "undefined" && storedData !== "null" && storedData !== undefined && storedData !== null){
            const params = JSON.parse(storedData)
            if (data !== "undefined" && data !== "null" && data !== undefined && data !== null) {params["user_otp"] = data}            
            setProceedLoading(true)
            const responsedata = await ConstructorEventPost(VerifyEmailId,params)
            console.log(responsedata,params)
            if (responsedata === "otp-sent"){setOtpsent(true)}
            else if (responsedata === "otp-not-sent"){notify("Otp Sending Failed")}
            else if (responsedata === "otp-verification-failed"){notify("Otp Verification Failed")}
            else if (responsedata === "otp-expired"){notify("Otp is Expired")}
            else if (responsedata[0] === "otp-verified"){navigate("/codomo/home");localStorage.setItem('authToken',  responsedata[1]);localStorage.setItem('lastLoginTime', Date.now());console.log(responsedata[1])}
            setProceedLoading(false)
        }
    }

    function otpChange(event) {
        const otpPattern = /^\d{6}$/
        if (otpPattern.test(event.target.value)){
          setOtp(event.target.value);
        }else{setOtp()}
      }


  return (
    <>
      <div className=" min-h-screen bg-[url('../images/all-img/404-bg.png')] bg-cover bg-no-repeat bg-center">
      {otpsent ? 
        <div className="max-w-[570px] mx-auto flex flex-col items-center text-center justify-center min-h-screen px-4">
          <h2 className=" mb-6">Confirm Your Email</h2>
          <div className="mb-10">
          An activation code has been dispatched to your email address
          </div>
          <div className="flex-none mr-4 from-control-1 focus:ring-0" style={{ display: 'flex', alignItems: 'center'  }}>
                  <input
                  type="text"
                  placeholder="Code"
                  className="from-control-edit focus:ring-0"
                  maxLength={6}
                  onChange={otpChange}
                  disabled={proceedLoading}
                  onKeyDown={event => {
                    if (event.key === 'Enter' || event.code === 'Enter' || event.key === 'NumpadEnter' || event.code === 'NumpadEnter') {
                        otp && fetchdata(otp);
                    }
                  }}
                /> 
                {proceedLoading ?
                        <ClassicSpinner size={25} color ="grey" loading="false"/> 
                      :<iconify-icon icon="material-symbols:arrow-circle-right-outline" style={{ fontSize: '2em' }} 
                      onClick={() => otp && fetchdata(otp)}/>
                }

            </div>
            <div className="resend-otp-text"> Haven't received the code yet?
                  <div className="resend-otp" onClick={fetchdata}>Resend Code</div>
                </div>
                
        </div>
        :
        <div className="max-w-[570px] mx-auto flex flex-col items-center text-center justify-center min-h-screen px-4">
            <h2 className=" mb-6">Confirm Your Account</h2>
          <div className="mb-10">
          To complete the Registration, it is necessary to verify the email address associated with it.
          </div>
          <button className="btn btn-primary" onClick={() => {fetchdata()}}>
            
            {proceedLoading ? <ClassicSpinner size={25} color ="white" loading="false"/> :"Send Verification Code"}
          </button>
        </div>
        }
      </div>
    </>
  );
};

export default EmailConfirm;