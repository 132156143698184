import React, { useState } from "react";
import "../../assets/css/newacitivity.css";

function Dropdown() {
  // Define the options for the dropdown
  const options = [
    {
      value: "Muhammad Ali ,Benjamin Franklin ,Martin Luther King Jr.",
      label: "January",
    },
    {
      value: "Charles Darwin ,Steve Jobs ,Galileo Galilei ,Jules Verne",
      label: "February",
    },
    {
      value: "Albert Einstein ,Michelangelo ,Harry Houdini ,Dr.Seuss",
      label: "march",
    },
    { value: "Charlie Chaplin,William Shakespeare,Kofi Annan", label: "April" },
    {
      value: "Florence Nightingale,Queen Victoria ,Jawaharlal Nehru ",
      label: "May",
    },
    { value: "Lionel Messi,Anne Frank ,Alan Turing ", label: "June" },
    {
      value: "Nikola Tesla,J.K. Rowling,Julius Caesar,Rosalind Franklin",
      label: "July",
    },
    {
      value: "Napoleon ,Neil Armstrong , ,Alexander the Great",
      label: "August",
    },
    { value: "Marco Polo ,Leo Tolstoy ,Stephen King", label: "September" },
    { value: "Mahatma Gandhi ,E. E. Cummings , Pelé", label: "October" },
    { value: "Marie Curie ,George Eliot ,C. V. Raman", label: "November" },
    { value: "Walt Disney ,Ratan Tata ,Joseph Stalin", label: "December" },
  ];

  // Initialize state to store the selected value
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [ClickedNext, setClickedNext] = useState(false);
  const [activitystarted, setActivitystarted] = useState(false);
  // Event handler for dropdown change
  const handleDropdownChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  return (
    <div className="container">
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="design-vew22">
            <div className="dropdown-container">
              <h4>Your Famous Birthday</h4>
              {!ClickedNext ? (
                <div>
                  <label>Select </label>
                  <select
                    value={selectedLanguage}
                    onChange={handleDropdownChange}
                  >
                    <option value="">Select an option</option>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <button
                    className="button-86 mt-6"
                    onClick={() => activitystarted && setClickedNext(true)}
                  >
                    Check
                  </button>
                </div>
              ) : (
                <div className="small-box1">
                  {selectedLanguage && <p> {selectedLanguage}</p>}
                </div>
              )}
            </div>
          </div>
          {/* <div class="nav-controls justify-content-between align-items-center"> */}
          <button
            className="button-12 mr-2"
            style={{ textAlign: "center" }}
            onClick={() =>
              activitystarted
                ? (setClickedNext(false), setActivitystarted(false))
                : setActivitystarted(true)
            }
          >
            {activitystarted ? "RESET" : "RUN"}
          </button>
          {/* </div> */}
          {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}
          {/* <button className="runbttn mr-3 " style={{ textAlign: "center" }} onClick={() => Activitystarted && ActivityData && ActivityData["FinishScreen"] && setScreenNumber(ActivityData["FinishScreen"])} >FINISH</button> */}
        </div>
        <div className="whiteboard">
          <div class="lg:col-span-9 col-span-12">
            <div class="headerwhitebox"></div>
            <h4 class="rightheading">My Famous Birthday </h4>
            <div class="rightpaneul">
              This app uses your birthdate to tell you a famous writer that was
              born on the same month
              <ul>
                <h4>Input:</h4>
                <li>1. User birthday(user input)</li>
                <li>
                  2. Calender of datws,including the day of the week(Internet)
                </li>
                <li>
                  3. List of influential figures and the month they were
                  born(internet or stored in the app)
                </li>
                <hr></hr>
                <h4>Output</h4>
                <li>An influential figure born on the same month as user</li>
                <hr></hr>
                <h4>Processing</h4>
                <li>
                  Out of the Four types of processing that you have learned
                  about,pick one and exlain how it can be used to change the
                  input into output
                </li>
                <li>*if/then</li>
                <li>*counting</li>
                <li>*finding a match</li>
                <li>*comparing</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
