import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt"
import ChatScreen from "./ChatScreen";





const VideoCallActivity = () => {

    const location = useLocation();
    const { userId, userName, mentorName, userType } = location.state || {};
    const [roomId, setRoomId] = useState("")
    const [studentRoomId, setStudentRoomId] = useState("")
    const [isJoinClicked, setIsJoinClicked] = useState(false)
    const [toggleMessagePopUp, setToggleMessagePopUp] = useState(false)

    useEffect(() => {

        if (userType == "mentor") {
            generateRoomId()
        }

    }, [])


    function generateRoomId() {
        console.log(`the usertype is mentor it comes inside the generateRoomID`)
        setRoomId("naveenmentor")
    }


    function handleChange(e) {
        setStudentRoomId(e.target.value)
    }

    function joinRoom() {
        setIsJoinClicked(true)
    }


    const myMeeting = async (ele) => {
        console.log(`ttttttttttt is ${roomId}`)
        const studentId = userId.replaceAll("/", "")
        const roomID = userType == "mentor" ? `${roomId}` : studentRoomId;
        const appID = 1766781274;
        const serverSecret = "28c7e9a71ececcfb48c90bb5caa363cc";
        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, Date.now().toString(), `${userName}`)
        const zc = ZegoUIKitPrebuilt.create(kitToken);

        const fullUrl = window.location.origin + location.pathname + location.search + location.hash;

        console.log(`the path url is ${fullUrl}`)

        zc.joinRoom({
            showPreJoinView: userType == "student" ? false : true,
            // turnOnCameraWhenJoining: false,
            // showMyCameraToggleButton: false,
            // showAudioVideoSettingsButton: false,
            container: ele,
            sharedLinks: [
                {
                    name: "Copy Link",
                    url: `${roomID}`
                }
            ],
            scenario: {
                mode: ZegoUIKitPrebuilt.GroupCall
            },
            showScreenSharingButton: false,
        })



    }

    function openMessage() {
        console.log("opening the message")
        setToggleMessagePopUp(!toggleMessagePopUp)
    }



    return (
        <div>

            {userType == "mentor" && roomId ?

                <div>
                    <div ref={myMeeting}
                        style={{ width: '100vw', height: '100vh' }}
                    />
                    {toggleMessagePopUp ?
                        <div className="message-box">
                            <ChatScreen userId={userId} userName={userName} />
                        </div> : ""}
                    <span className="message-icon" onClick={openMessage}>
                        <iconify-icon icon={toggleMessagePopUp ? "ion:close" : "ic:baseline-chat"}></iconify-icon>
                    </span>

                </div> :


                isJoinClicked ? <div ref={myMeeting}
                    style={{ width: '100vw', height: '100vh' }}
                /> : userType ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <div>
                        <input type="text" placeholder="Enter Your RoomID" style={{ borderRadius: "10px" }} onChange={handleChange} />
                        <button style={{ width: "50vw", backgroundColor: "blue", color: "white", marginTop: "10px", height: "40px", borderRadius: "10px" }} onClick={joinRoom}>Join</button>
                    </div>
                    {toggleMessagePopUp ?
                        <div className="message-box">
                            <ChatScreen />
                        </div> : ""}
                    <span className="message-icon" onClick={openMessage}>
                        <iconify-icon icon={toggleMessagePopUp ? "ion:close" : "ic:baseline-chat"}></iconify-icon>
                    </span>
                </div> : ""
            }


        </div>
    )
}


export default VideoCallActivity;