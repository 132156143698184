import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './constant/importedCss'
import { GoogleOAuthProvider} from '@react-oauth/google';
import { BrowserRouter } from "react-router-dom";
import { googleauthcredentials } from './credentials/googleauth';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={googleauthcredentials["clientId"]}>

        <App />
    </GoogleOAuthProvider>
  </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

