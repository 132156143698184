import React, { useState, useRef } from "react";
import "../../assets/css/newacitivity.css";
import backgroundImage from "../../assets/images/NewActivityImages/background.jpg";


export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();
  const [Activitystarted, setActivitystarted] = useState(false);
  const [divtochangeContent, setDivtochangeContent] = useState([]);
  const [selectedpopup, setSelectedpopup] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("");

  // Handle Drag Start
  const handleDragStart = (e) => {
    if (Activitystarted === true) {
      e.dataTransfer.setData("text/plain", e.target.id);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");

    if (droppedItemId === "setProperty") {
      setDivtochangeContent((prevItems) => [
        ...prevItems,
        {
          id: prevItems.length + 1,
          type: "setProperty",
          screen: "",
        },
      ]);
    } else if (droppedItemId === "onEvent") {
      setDivtochangeContent((prevItems) => [
        ...prevItems,
        {
          id: prevItems.length + 1,
          type: "onEvent",
          event: "Click",
        },
      ]);
    }
  };

  const handleScreenChange = (e, index) => {
    const updatedItems = [...divtochangeContent];
    updatedItems[index].screen = e.target.value;
    setDivtochangeContent(updatedItems);
    setCurrentScreen(e.target.value);
  };

  const handleHtmlCode = () => {
    const data = getHtmlCode.current.outerHTML;
    setHtmlCode(data);
  };

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  const renderScreenContent = () => {
    if (!Activitystarted) {
      return (
        <div className="design-vew7box">
          Choose Your Adventure! Do you Want to go LEFT or RIGHT
        </div>
      );
    }

    switch (currentScreen) {
      case "Left":
        return (
          <div>
            <div className="design-vew7box" style={{ paddingTop: "65px" }}>
              You Went to Left Screen
            </div>
            <button
              className="buttonsessionActivity9"
              style={{ marginLeft: "70px" }}
              value="Right"
              onClick={() => setCurrentScreen("Right")}
            >
              Right
            </button>
          </div>
        );
      case "Right":
        return (
          <div>
            <div className="design-vew7box" style={{ paddingTop: "65px" }}>
              You Went to Right Screen
            </div>
            <button
              className="buttonsessionActivity9"
              style={{ marginLeft: "70px" }}
              value="Left"
              onClick={() => setCurrentScreen("Left")}
            >
              Left
            </button>
          </div>
        );
      default:
        return (
          <div>
            <div className="design-vew7box">
              Choose Your Adventure! Do you Want to go LEFT or RIGHT
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                className="buttonsessionActivity9"
                value="Left"
                onClick={() => setCurrentScreen("Left")}
              >
                Left
              </button>
              <button
                className="buttonsessionActivity9"
                value="Right"
                onClick={() => setCurrentScreen("Right")}
              >
                Right
              </button>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="container" style={{  overflow: "hidden",backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="Newbody">
            <div className="Newheader">
              <div className="design-vew7" ref={getHtmlCode}>
                {renderScreenContent()}
                {!Activitystarted && <div className="mt-14"></div>}
              </div>
              <button
                className="button-12 mt-2"
                style={{ textAlign: "center" }}
                onClick={() => setActivitystarted(!Activitystarted)}
              >
                {Activitystarted ? "RESET" : "RUN"}
              </button>
            </div>
          </div>
        </div>
        <div className="lg:col-span-9 col-span-12 mt-5">
          <div className="NavbarApp">&emsp;Instructions</div>
          
  <div className="NavbarApp">
   
  </div>
  <div className="instructions-content">
    <p>
      <strong>Goal:</strong> The screen switches to "rightScreen" when the user
      clicks the "rightButton".
    </p>
    <p>
      <strong>How:</strong> In Code Mode, add a new onEvent() to your program
      for your "Right!" button. Add a setScreen() block inside of it that
      switches screens to "rightScreen".
    </p>
  </div>


          <div className="row">
            <div className="col-6">
              <div className="box">
                <div className="box-head">
                  &emsp;Toolbox
                  <a className="yellow-btn">
                    <i className="fa-solid fa-minus"></i>
                  </a>
                </div>

                <div className="box-content p-3">
                  <div className="controlhead">UI Controls</div>
                  <div>&emsp;</div>
                  <div>
                    {/* setProperty block */}
                    <div
                      className={selectedpopup ? null : "pin"}
                      draggable="true"
                      id="setProperty"
                      onDragStart={handleDragStart}
                    >
                      <div
                        className="divtochange flex"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        setProperty
                      </div>
                    </div>

                    {/* onEvent block */}
                    <div className={selectedpopup ? null : "plug-board work-space"} draggable="true" id="onEvent" onDragStart={handleDragStart}>


                    <div class="plug-board-top">
                      OnEvent (id, type, callback)
                      <div class="function">Function</div>
                    </div>

                    <div class="plug">
                      <div
                        class="plug-blue"
                        ondrop="drop(event)"
                        ondragover="allowDrop(event)"
                      ></div>
                    </div>

                    <div class="plug-board-bottom">
                      <div class="bottom-blue"></div>
                    </div>

                  </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-9">
              <div className="box">
                <div className="box-head">
                  &emsp;Workspace
                  <a
                    className="btn"
                    onClick={() => {
                      popupvalue();
                      handleHtmlCode();
                    }}
                  >
                    <button>Show Code</button>
                  </a>{" "}
                  <a
                    className="btn me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#show-code-popup"
                  >
                    <button>Version History</button>
                  </a>
                  {selectedpopup && (
                    <div className="modal">
                      <div className="overlay">
                        <div className="modal-content">
                          {htmlCode ? htmlCode : null}
                          <button
                            className="btn"
                            style={{ marginTop: "30px" }}
                            onClick={popupvalue}
                          >
                            close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Workspace */}
                <div
                  className="box-content p-3"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  style={{ minHeight: "300px" }}
                >
                  {/* Rendering the dragged blocks */}
                  {divtochangeContent.map((item, index) => (
                    <div key={item.id} className={item.type === "setProperty" ? "divtochange flex pin" : "divtochange flex"}  style={{
                      marginLeft:
                        item.type === "setProperty"
                          ? "25px"
                          
                          
                          : "0px", // Add margin for specific types
                    }}>
                      {item.type === "setProperty" && (
                        <>
                          setProperty
                          <div>
                            (
                            <select
                              className="dropdown"
                              onChange={(e) => handleScreenChange(e, index)}
                              value={item.screen}
                            >
                              <option value="">Select Screen</option>
                              <option value="Left">Left Screen</option>
                              <option value="Right">Right Screen</option>
                            </select>
                            )
                          </div>
                        </>
                      )}

{item.type === "onEvent" && (

<div
  class={selectedpopup ? null : "plug-board work-space"}
  onDrop={handleDrop}
  onDragOver={handleDragOver}
>
  <div class="plug-board-top">
    OnEvent (id, type, callback)
    <div class="function">Function</div>
  </div>

 

  
</div>
)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appone;
