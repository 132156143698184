import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import { DownloadFileFromS3, FetchSessionDetails } from "../../api/aws-api";
import { ConstructorEventPost, ConstructorEventGet } from "../../Event/event";
import { ClassicSpinner, MagicSpinner } from "react-spinners-kit";

const Downloads = (props) => {
  const location = useLocation();
  const { course } = location.state;
  const [session, setSession] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadingLoading, setDownloadingLoading] = useState("");

  useEffect(() => {
    SessionDetails();
    setLoading(true);
  }, [props]);

  const SessionDetails = async () => {
    const temp = sessionStorage.getItem("Session" + course.course_id);
    const parsedTemp = temp ? JSON.parse(temp) : null;
    if (parsedTemp) {
      setLoading(false);
      setSession(parsedTemp);
    }
    const responsedata = await ConstructorEventGet(FetchSessionDetails, {
      course_id: course.course_id,
    });
    sessionStorage.setItem(
      "Session" + course.course_id,
      JSON.stringify(responsedata)
    );
    setSession(responsedata ? responsedata : []);
    setLoading(false);
  };

  const downloadfile = async (item) => {
    setDownloadingLoading(item.FileKey);
    console.log(item.filekey);
    const s3FileName = course.course_id + "/downloads/" + item.FileKey;
    console.log(s3FileName);
    const responsedata = await ConstructorEventPost(DownloadFileFromS3, {
      file_name: s3FileName,
    });
    downloadPDFFromURL(responsedata);
  };
  const downloadPDFFromURL = async (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloadingLoading("");
  };

  return (
    <>
      <Header />
      {loading ? (
        <div className="spinner">
          <div className="spinner-wrapper">
            <ClassicSpinner size={50} color="black" loading={loading} />
          </div>
        </div>
      ) : (
        <div className=" section-padding">
          <div className="container">
            <div className="text-center">
              <div className="mini-title">Downloads</div>
              <div className="column-title ">
                Select <span className="shape-bg">Topics</span> To Download
              </div>
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-2  grid-cols-1 gap-[30px] pt-10">
              {session.map((item, index) => (
                <button
                  className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center px-6 py-[65px] hover:-translate-y-2"
                  key={index}
                  onClick={() => !downloadingLoading && downloadfile(item)}
                >
                  <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8]">
                    {downloadingLoading === item.FileKey ? (
                      <MagicSpinner size={40} color="black" loading="true" />
                    ) : (
                      <iconify-icon
                        icon="material-symbols:cloud-download-rounded"
                        style={{ fontSize: "2em" }}
                      />
                    )}
                  </div>
                  <div className="course-content">
                    <h4 className=" text-2xl  mb-2 font-bold">
                      {item.SessionName}
                    </h4>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Downloads;
