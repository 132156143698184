import { toast } from 'react-toastify';

export const notifyError = (message) => 
toast.error(message, {
    className: 'my-custom-toast-error',
    position: "top-left",
    autoClose: 10000,
    hideProgressBar: false,
    pauseOnHover: true,
    progress: undefined,
    theme: "light",
    }
);;

export const notifySuccess = (message) => 
toast.success(message, {
    className: 'my-custom-toast-success',
    position: "top-left",
    autoClose: 10000,
    hideProgressBar: false,
    pauseOnHover: true,
    progress: undefined,
    theme: "light",
    }
);;