import React, { useState } from "react";
import "../../assets/css/newacitivity.css";

function Dropdown() {
  // Define the options for the dropdown
  const options = [
    { value: "", label: "Select an option" },
    {
      value: require("../../assets/images/NewActivityImages/New folder/red shirt.jpg"),
      label: "Red",
    },
    {
      value: require("../../assets/images/NewActivityImages/New folder/green shirt.jpg"),
      label: "Green",
    },
    {
      value: require("../../assets/images/NewActivityImages/New folder/black shirt.jpg"),
      label: " Black",
    },
    {
      value: require("../../assets/images/NewActivityImages/New folder/blueshirt.jpg"),
      label: "Blue",
    },
  ];
  const optionstwo = [
    { value: "", label: "Select an option" },
    {
      value: require("../../assets/images/NewActivityImages/New folder/springpant.jpg"),
      label: "Spring",
    },
    {
      value: require("../../assets/images/NewActivityImages/New folder/winterpant.jpg"),
      label: "Winter",
    },
    {
      value: require("../../assets/images/NewActivityImages/New folder/raincoat.jpg"),
      label: "Monsoon",
    },
    {
      value: require("../../assets/images/NewActivityImages/New folder/summerpant.jpg"),
      label: "Summer",
    },
  ];
  const optionsthree = [
    { value: "", label: "Select an option" },
    {
      value: require("../../assets/images/NewActivityImages/New folder/sunglasses.jpg"),
      label: "sunny",
    },
    {
      value: require("../../assets/images/NewActivityImages/New folder/rainy.jpg"),
      label: "rainy",
    },
    {
      value: require("../../assets/images/NewActivityImages/New folder/stormy.jpg"),
      label: "stormy",
    },
    {
      value: require("../../assets/images/NewActivityImages/New folder/cap.jpg"),
      label: "cloudy",
    },
  ];

  // Initialize state to store the selected value
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLanguageone, setSelectedLanguageone] = useState("");
  const [selectedLanguagetwo, setSelectedLanguagetwo] = useState("");
  const [showimage, setShowimage] = useState(false);
  const [activitystarted, setActivitystarted] = useState(false);

  // Event handler for dropdown change
  const handleDropdownChange = (event) => {};

  return (
    <div className="container">
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="design-vew22">
            <div className="dropdownoutfit "></div>
            {showimage == false ? (
              <div className="dropdown-container">
                <h4>Outfit Picker</h4>

                <div className="mt-5">
                  <h6>What is your Favourite Color?</h6>
                  <label>Select</label>
                  <select
                    value={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                  >
                    {options.map((ele, index) => {
                      return (
                        <option key={index} value={ele.value}>
                          {ele.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mt-5">
                  <h6>What Season is it?</h6>
                  <label>Select</label>
                  <select
                    value={selectedLanguageone}
                    onChange={(e) => setSelectedLanguageone(e.target.value)}
                  >
                    {optionstwo.map((ele, index) => {
                      return (
                        <option key={index} value={ele.value}>
                          {ele.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mt-5">
                  <h6>What is the weather like today?</h6>
                  <label>Select</label>
                  <select
                    value={selectedLanguagetwo}
                    onChange={(e) => setSelectedLanguagetwo(e.target.value)}
                  >
                    {optionsthree.map((ele, index) => {
                      return (
                        <option key={index} value={ele.value}>
                          {ele.label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <button
                  className="button-86 mt-2"
                  onClick={() => setShowimage(true)}
                >
                  generate
                </button>
              </div>
            ) : (
              <div>
                <img src={selectedLanguage} />
                <img src={selectedLanguageone} />
                <img src={selectedLanguagetwo} />
              </div>
            )}
          </div>

          <button
            className="button-86 mt-3"
            onClick={() =>
              activitystarted
                ? (setShowimage(false),
                  setActivitystarted(false),
                  setSelectedLanguage(""),
                  setSelectedLanguageone(""),
                  setSelectedLanguagetwo(""))
                : setActivitystarted(true)
            }
          >
            {activitystarted ? "RESET" : "RUN"}
          </button>
        </div>

        <div class="lg:col-span-9 col-span-12">
          <div className="whiteboard">
            <div class="headerwhitebox"></div>
            <h4 class="rightheading">OutFit Picker </h4>
            <div class="rightpaneul">
              <ul>
                <h4>Input:</h4>
                <li>1. Favorite Colour</li>
                <li>2. Season</li>
                <li>3. Weather</li>
                <hr></hr>
                <h4>Output</h4>
                <li>Shirt</li>
                <li>Pant</li>
                <li>Items to bring</li>
                <hr></hr>
                <h4>Processing</h4>
                <li>If it is raining. Then Carry an umbrella</li>
                <li>If it is autumn or winter,Then wear long pants</li>
                <li>MATCH favorite colour to shirt colour</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
