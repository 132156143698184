import Lottie from "lottie-react";
import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/newacitivity.css";
import animationData from "../../assets/animation/animation_lnee9hpc.json";
import backgroundImage from "../../assets/images/NewActivityImages/background.jpg";


export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();
  const [Activitystarted, setActivitystarted] = useState(false);
  const [workspaceItems, setWorkspaceItems] = useState([]);
  const [selectedAudio, setSelectedAudio] = useState("");
  const [selectedpopup, setSelectedpopup] = useState(false);

  const data = {
    Button1: {
      image: [
        "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/Beautiful_White_Horse.jpg",
      ],
    },
    Button2: {
      image: [
        "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/Lion-HD-Images-Pictures-Wallpapers-Pictures-Images-.jpg",
      ],
    },
    Button3: {
      image: ["https://d3e3enpv2x7kmj.cloudfront.net/TestImages/monkey.jpg"],
    },
    Button4: {
      image: ["https://d3e3enpv2x7kmj.cloudfront.net/TestImages/gray-wolf.jpg"],
    },
  };

  const dataone = {
    Horse: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/horse-123780.mp3",
    Lion: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/lion-roar-6011.mp3",
    Monkey:
      "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/monkey-128368.mp3",
    Wolf: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/wolf-howl-6310.mp3",
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.id);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");
    const newWorkspaceItem = {
      id: workspaceItems.length + 1,
      type: droppedItemId,
      button: "",
      property: "",
      value: "",
      sound: "",
    };

    setWorkspaceItems([...workspaceItems, newWorkspaceItem]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleWorkspaceButtonChange = (e, index) => {
    const updatedItems = [...workspaceItems];
    updatedItems[index].button = e.target.value;
    setWorkspaceItems(updatedItems);
  };

  const handleWorkspacePropertyChange = (e, index) => {
    const updatedItems = [...workspaceItems];
    updatedItems[index].property = e.target.value;
    setWorkspaceItems(updatedItems);
  };

  const handleWorkspaceValueChange = (e, index) => {
    const updatedItems = [...workspaceItems];
    updatedItems[index].value = e.target.value;
    setWorkspaceItems(updatedItems);
  };
  const handleWorkspaceSoundChange = (e, index) => {
    const updatedItems = [...workspaceItems];
    updatedItems[index].sound = e.target.value;
    setWorkspaceItems(updatedItems);
    
    if (e.target.value) {
      const audio = new Audio(e.target.value);
      audio.play().catch((error) => {
        console.error("Audio playback failed: ", error);
      });
    }
  };


  const getButtonStyle = (buttonName) => {
    let style = {
      backgroundSize: 'cover', // Ensures the image covers the entire button
    backgroundPosition: 'center', // Centers the image in the button
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    width: '100%', // Ensures the button takes up full width
    height: '100%',
    };
    workspaceItems.forEach((item) => {
      if (item.button === buttonName && item.property === "image") {
        style.backgroundImage = `url(${item.value})`;
      }
    });
    return style;
  };

  const handleHtmlCode = () => {
    const data = getHtmlCode.current.outerHTML;
    setHtmlCode(data);
  };

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  useEffect(() => {
    if (Activitystarted && selectedAudio) {
      const audio = new Audio(selectedAudio);
      audio.play();
    }
  }, [Activitystarted, selectedAudio]);

  return (
    <div className="container" style={{  overflow: "hidden",backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12">
          <div className="Newbody">
            <div className="Newheader">
              <div className="design-vew22" ref={getHtmlCode}>
                <div className="toolboxcss" style={{ width: "100%", height: "600px", borderRadius: "30px" }}>
                  <div>
                    <Lottie animationData={animationData} />{" "}
                  </div>
                  <div style={{ display: "grid", gridTemplateColumns: "auto auto",marginLeft:"-1rem" }}>
                    <button className="buttonsession1 mr-1o
                    " style={getButtonStyle("Button1")}>
                      {workspaceItems.some(
                        (item) => item.button === "Button1" && item.property === "image"
                      ) ? null : <p>Horse</p>}
                    </button>
                    <button className="buttonsession1" style={getButtonStyle("Button2")}>
                      {workspaceItems.some(
                        (item) => item.button === "Button2" && item.property === "image"
                      ) ? null : <p>Lion</p>}
                    </button>
                    <button className="buttonsession1" style={getButtonStyle("Button3")}>
                      {workspaceItems.some(
                        (item) => item.button === "Button3" && item.property === "image"
                      ) ? null : <p>Monkey</p>}
                    </button>
                    <button className="buttonsession1" style={getButtonStyle("Button4")}>
                      {workspaceItems.some(
                        (item) => item.button === "Button4" && item.property === "image"
                      ) ? null : <p>Wolf</p>}
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="button-12 mt-2"
                style={{ textAlign: "center" }}
                onClick={() =>
                  Activitystarted ? setActivitystarted(false) : setActivitystarted(true)
                }
              >
                {Activitystarted ? "RESET" : "RUN"}
              </button>
            </div>
          </div>
        </div>

        <div className="lg:col-span-9 col-span-12 mt-5">
          <div className="NavbarApp">&emsp;Instructions</div>
          <div>
            <h6>
              Goal: This sound board is almost done! Add an image, text, and sound
              to the last button to see how it works.
            </h6>
            <h6>
              How: Add an onEvent() to the program and set the "id" to "button4".
              Drag a playSound() into the onEvent() and choose a sound from the
              library.
            </h6>
            <h6>Add a setProperty() (outside your onEvent()) to change the button's "image" property.</h6>
          </div>

          <div className="row">
            <div className="col-6">
              <div class="box">
                <div class="box-head">
                  <a class="yellow-btn">
                    <i class="fa-solid fa-minus"></i>
                  </a>
                  <h2>TOOLBOX</h2>
                </div>

                <div class="box-content p-3">
                  <div className="pin" draggable="true" id="setProperty" onDragStart={handleDragStart} >
                    <div class="divtochange flex" style={{ whiteSpace: "nowrap" }}>
                      setProperty
                    </div>
                  </div>

                  <div
                    className="pin"
                    draggable="true"
                    id="playSound"
                    onDragStart={handleDragStart}
                  >
                    <div class="divtochange flex" style={{ whiteSpace: "nowrap" }}>
                      Play Sound
                    </div>
                  </div>

                  {/* Add draggable OnEvent */}
                  <div className={selectedpopup ? null : "plug-board work-space"} draggable="true" id="onEvent" onDragStart={handleDragStart}>


                    <div class="plug-board-top">
                      OnEvent (id, type, callback)
                      <div class="function">Function</div>
                    </div>

                    <div class="plug">
                      <div
                        class="plug-blue"
                        ondrop="drop(event)"
                        ondragover="allowDrop(event)"
                      ></div>
                    </div>

                    <div class="plug-board-bottom">
                      <div class="bottom-blue"></div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-9">
              <div class="box">
                <div class="box-head">
                  &emsp;Workspace
                  <a class="btn" onClick={() => { popupvalue(); handleHtmlCode(); }}>
                    <button>Show Code</button>
                  </a>{" "}
                  <a class="btn me-2" data-bs-toggle="modal" data-bs-target="#show-code-popup">
                    <button>Version History</button>
                  </a>
                </div>

                <div class="box-content p-3" onDrop={handleDrop} onDragOver={handleDragOver}>
                  {workspaceItems.map((item, index) => (
                    <div key={item.id}>
                      {item.type === "setProperty" && (
                        <div className="divtochange flex pin" style={{
                          marginLeft:
                            item.type === "setProperty"
                              ? "25px"
                              
                              
                              : "0px", // Add margin for specific types
                        }}>
                          setProperty(
                          <select
                            className="selectinpin"
                            onChange={(e) => handleWorkspaceButtonChange(e, index)}
                            value={item.button}
                          >
                            <option value="">Select a Button</option>
                            {Object.keys(data).map((btn, idx) => (
                              <option key={idx} value={btn}>
                                {btn}
                              </option>
                            ))}
                          </select>
                          <select
                            className="selectinpin"
                            onChange={(e) => handleWorkspacePropertyChange(e, index)}
                            value={item.property}
                          >
                            <option value="">Select Property</option>
                            <option value="image">Image</option>
                          </select>
                          <select
                            className="selectinpin"
                            onChange={(e) => handleWorkspaceValueChange(e, index)}
                            value={item.value}
                          >
                            <option value="">Select Value</option>
                            {item.button && item.property === "image" &&
                              data[item.button]?.image.map((img, idx) => (
                                <option key={idx} value={img}>
                                  {img}
                                </option>
                              ))}
                          </select>
                          )
                        </div>
                      )}

                      {item.type === "playSound" && (
                        <div className="divtochange flex pin"  style={{
                          marginLeft:
                            item.type === "playSound"
                              ? "25px"
                              
                              
                              : "0px", // Add margin for specific types
                        }} >
                          Play Sound(
                          <select
                            className="selectinpin"
                            onChange={(e) => handleWorkspaceSoundChange(e, index)}
                            value={item.sound}
                          >
                            <option value="">Select Sound</option>
                            {Object.keys(dataone).map((sound, idx) => (
                              <option key={idx} value={dataone[sound]}>
                                {sound}
                              </option>
                            ))}
                          </select>
                          )
                        </div>
                      )}

                      {item.type === "onEvent" && (

                        <div
                          class={selectedpopup ? null : "plug-board work-space"}
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                        >
                          <div class="plug-board-top">
                            OnEvent (id, type, callback)
                            <div class="function">Function</div>
                          </div>

                         

                          
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {selectedpopup && (
            <div className="modal">
              <div className="overlay">
                <div className="modal-content">
                  <div>{htmlCode}</div>
                  <button className="btn" style={{ marginTop: "30px" }} onClick={popupvalue}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Appone;



