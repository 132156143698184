import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom"
import Header from "../Header";
import { Advanced, DoodleCallingFunction } from "../../api/aws-api";
import { ConstructorEventPost } from "../../Event/event";
import { CircleSpinner } from "react-spinners-kit";
import Editor from "@monaco-editor/react";

const AdvancedCourse = () => {
    const [loading, setLoading] = useState(false)
    const [SaveCodeloading, setSaveCodeloading] = useState(false)
    const [Code, setCode] = useState("responsedata")
    const [CodingLanguage, setCodingLanguage] = useState("python3")
    const [Output, setOutput] = useState("")
    const [FullActivityData, setFullActivityData] = useState()
    const [CourseProps1, setCourseProps] = useState()
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        const { resource, CourseProps } = location.state ? location.state : {}
        if (resource) { setCode(resource.code); setFullActivityData(resource) }
        if (CourseProps) { setCourseProps(CourseProps) }
        console.log(resource)
        //FetchBlogData()
    }, [])


    const navigate = useNavigate();


    const handleEditorChange = (value, event) => {
        setCode(value)
    }

    const RunTheCode = async () => {
        setLoading(true)
        const StudentId = JSON.parse(localStorage.getItem('UserKey'));
        const CheckingCode = FullActivityData && FullActivityData["PrependCode"] ? FullActivityData["PrependCode"] + Code : Code
        const temp = {
            "code": CheckingCode,
            "language": CodingLanguage,
            "ActivityId": "1",
            "StudentId": StudentId["user_id"]
        }
        const responsedata = await ConstructorEventPost(DoodleCallingFunction, temp)
        if (responsedata["statusCode"] === 200) {
            setOutput(responsedata["output"])
        }
        console.log(responsedata)
        setLoading(false)
    }

    const SaveCodeToBacked = () => {
        navigate("/codomo/course", { state: { "course": CourseProps1 } })
    }

    const content = FullActivityData && FullActivityData["name"] ? FullActivityData["name"] : ""

    const wordLimit = 40; // Adjust the word limit as needed
    const words = content.split(' ');
    const isLongText = words.length > wordLimit;
    const [showFullText, setShowFullText] = useState(false);
    const truncatedText = words.slice(0, wordLimit).join(' ');

    return (
        <>
            <Header />
            <div className="nav-tab-wrapper tabs  section-padding">
                <div className="container">
                    <div className="mini-title mb-4">Code Editor</div>
                    <div className="ml-2">
                        {showFullText ? content : truncatedText}
                        {isLongText && !showFullText ? (
                            <button className="pl-4" style={{ fontSize: "10px" }} onClick={() => setShowFullText(true)}>... Show More</button>
                        ) : isLongText && showFullText ? (<button className="pl-4" style={{ fontSize: "10px" }} onClick={() => setShowFullText(false)}> Show Less</button>) : ""}
                        {/* {FullActivityData && content /*FullActivityData["description"]*/}
                    </div>
                    <div className=" grid grid-cols-12 gap-[30px] pt-4">
                        <div className="xl:col-span-7 lg:col-span-6 col-span-12">

                            <div className="bg-white shadow-box7 p-8 rounded-md">
                                {/* <CodeMirror
                                value={Code}
                                height="600px"
                                theme={vscodeDark}
                                onChange={(e)=>setCode(e.target.value)}
                                /> */}
                                <div className="small-btn-saveDataToCloud mb-4 mr-4" onClick={SaveCodeToBacked} >
                                    <iconify-icon icon="mingcute:back-2-fill" style={{ fontSize: '1em' }} /> {"   "}   Back
                                </div>
                                <div className="small-btn mb-4" onClick={SaveCodeToBacked} >
                                    {SaveCodeloading ?
                                        <CircleSpinner className="inline-block" size={20} color="#FF7E84" loading={SaveCodeloading} /> :
                                        <><iconify-icon icon="fontisto:save" style={{ fontSize: '1em' }} /> {"   "}   Save Code and Continue</>
                                    }
                                </div>

                                <Editor
                                    className="mt-4"
                                    height="400px"
                                    language="python"
                                    theme="vs-dark"
                                    value={Code}
                                    onChange={handleEditorChange}
                                    options={{
                                        inlineSuggest: true,
                                        fontSize: "16px",
                                        formatOnType: true,
                                        autoClosingBrackets: true,
                                        minimap: { enabled: false }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
                            <div className="small-btn-saveDataToCloud mt-4" onClick={!loading && RunTheCode} >
                                {loading ?
                                    <CircleSpinner className="inline-block" size={20} color="#FF7E84" loading={loading} /> :
                                    <><iconify-icon icon="codicon:run-all" style={{ fontSize: '1em' }} /> {"   "}   Run Your Code</>
                                }
                            </div>

                            <hr className="mt-4"></hr>
                            {Output !== "" ?
                                <div className="shadow-box7 p-8 rounded-md mt-4">
                                    <div className="mini-title mt-4">Output :</div>
                                    <div className="input-container mt-4">
                                        {Output.split('\n').map((line, index) => (
                                            <>
                                                {line}
                                                <br />
                                            </>
                                        ))}
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default AdvancedCourse;