import React, { useState, useEffect } from "react";
import CourseDetails from "./CourseDetails";
import Header from "../Header";
import { FetchCourseDetails } from "../../api/aws-api";
import { ConstructorEventGet } from "../../Event/event";
import { useLocation } from "react-router-dom";
import { ClassicSpinner } from "react-spinners-kit";

const Course = (props) => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [courseProps, setCourseProps] = useState();

  useEffect(() => {
    const { course } = location.state ? location.state : [];
    setCourseProps(course);
    fetchdata(course);
    setLoading(true);
  }, [props]);

  const fetchdata = async (course) => {
    const temp = sessionStorage.getItem("Course" + course.course_id);
    const parsedTemp = temp ? JSON.parse(temp) : null;
    if (parsedTemp) {
      setLoading(false);
      setData(parsedTemp);
    }
    const responsedata = await ConstructorEventGet(FetchCourseDetails, {
      course_id: course.course_id,
    });
    setData(responsedata);
    sessionStorage.setItem(
      "Course" + course.course_id,
      JSON.stringify(responsedata)
    );
    //const s3data = await ConstructorEventGet(FetchJsonFromS3,{bucket_name : "anglofonecoursedetails",fetchfile:responsedata[0]["course_details"]})
    //setCoursedata(s3data)
    setLoading(false);
  };

  return (
    <>
      <Header />

      {/* Added spinner while changing from one course to another */}

      {loading ? (
        <div className="spinner">
          <div className="spinner-wrapper">
            <ClassicSpinner size={50} color="black" loading={props.loading} />
          </div>
        </div>
      ) : (
        <CourseDetails
          CourseDetails={data}
          loading={loading}
          courseProps={courseProps}
        />
      )}
    </>
  );
};

export default Course;
