import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import { ShowStudentName, StudentAdmiration } from "../../api/aws-api";
import { ConstructorEventGet, ConstructorEventPost } from "../../Event/event";
import { PublishMessageAsBatchNotification } from "../../api/aws-api";
import axios from 'axios';

const NotificationService = (props) => {
  const [studentName, setStudentName] = useState("");
  const [studentNameoptions, setStudentNameoptions] = useState([]);
  const [studentId, setStudentId] = useState([]);
  // const [message, setMessage] = useState();
  const [starRating, setStarRating] = useState(0); // State for star rating
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    ShowStudentNamelist();
  }, []);

  const ShowStudentNamelist = async () => {

    const responsedata = await ConstructorEventGet(ShowStudentName, {

    })


    console.log(`The res data is ${responsedata}`)
    setStudentNameoptions(responsedata.body);
    setStudentId(responsedata.body1)

    // console.log(`The res data is ${responsedata.statusCode}`)
    // setStudentNameoptions(responsedata.body);



  };

  console.log(`the response body is ${studentNameoptions}`)
  console.log(`the response body is ${studentId}`)

  // const ShowStudentNamelist = async () => {
  //   try {
  //     const responsedata = await ConstructorEventGet(ShowStudentName, {});
  //     if (Array.isArray(responsedata)) { // Check if responsedata is an array
  //       setBatchoptions(responsedata);
  //     } else {
  //       console.error("Response data is not an array:", responsedata);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching student names:", error);
  //   }
  // };


  console.log(`The student id is ${studentName} and the star is ${starRating}`)
  const handleSubmit = async (e) => {

  };

  const studentAdmiration = async () => {
    const Params = {
      "Userid": studentName,
      "StarRating": starRating.toString(),

    }

    const responsedata = await ConstructorEventPost(StudentAdmiration, Params)
    console.log(`=======${responsedata}`);
  }

  // Function to handle star selection
  const handleStarClick = (rating) => {
    setStarRating(rating);
  };

  // Function to render stars
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          onClick={() => handleStarClick(i)}
          style={{ cursor: "pointer" }}
          className={`star ${i <= starRating ? 'star-two' : 'star-one'}`}
        >
          {i <= starRating ? "★" : "☆"}
        </span>
      );
    }
    return stars;
  };

  console.log(`the selected student name is ${studentName}`)
  return (
    <>
      <Header />
      <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container">
          <div className=" grid grid-cols-12 gap-[30px]">
            <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
              <div className="mini-title">Message</div>
              <h4 className="column-title ">CODOMO</h4>
              <div>Send Individual Messages to Students</div>
              <ul className=" list-item space-y-6 pt-8">
                <li className="flex">
                  <div className="xl:col-span-7 lg:col-span-6 col-span-12">
                    <div className=" rounded-md"></div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="xl:col-span-7 lg:col-span-6 col-span-12">
              <div className="bg-white shadow-box7 p-8 rounded-md">



                <div className="input-container">
                  <div>Student Name</div>
                  <select
                    className="from-control"
                    value={studentName}
                    onChange={(e) => setStudentName(e.target.value)}
                    required
                  >

                    {studentNameoptions.length >= 1 ? studentNameoptions.map((item, index) => (
                      <option key={index} value={studentId[index]}>
                        {item}
                      </option>
                    )) : null}
                  </select>
                  <div
                    className={`input-placeholder ${studentName && "active"}`}
                  >
                  </div>
                </div>
                {/* <div className="md:col-span-2 col-span-1 input-container">
                    <textarea
                      className=" from-control"
                      rows="3"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                    <span
                      className={`input-placeholder ${message && "active"}`}
                    >
                      Your Message*
                    </span>
                  </div> */}

                {/* Star rating */}
                <div className="md:col-span-2 col-span-1 input-container">
                  <div>Star Rating*</div>
                  {renderStars()}
                </div>

                <button
                  className="btn btn-primary mt-[10px]"
                  type="submit"
                  name="submit"
                  onClick={() => {
                    studentAdmiration();
                    navigate('/codomo/admin/createnewuser');
                  }}
                >
                  Click Me
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationService;
