import React,{useState} from "react";
import SignUpForm from "./SignUpForm";
import {GoogleIcon} from "../../constant/images";
import { useGoogleLogin} from '@react-oauth/google';
import { ConstructorEventGetNoParams } from '../../Event/event';
import {useNavigate} from "react-router-dom"


const SignUp = () => {
  const [usedGoogle,setUsedGoogle] = useState(false)
  const [googleId,setGoogleId] = useState("")
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate()

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => fetchdatafromgoogleapis(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });
  const  fetchdatafromgoogleapis =  async(user) =>{
    const responsedata = await ConstructorEventGetNoParams(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
      headers: {Authorization: `Bearer ${user.access_token}`,Accept: 'application/json'}})
      setUsedGoogle(true)
      setName(responsedata.name)
      setEmail(responsedata.email)
      setGoogleId(responsedata.id)
  }
  return (
    <>
      <div className="nav-tab-wrapper tabs section-padding-top-half">
        <div className="container">
          <div className=" grid grid-cols-12 gap-[30px]">
            <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
              <div className="mini-title">Sign Up</div>
              <h4 >
              We're Thrilled To Have You <span className="shape-bg">Onboard!</span>  By Creating An Account.  
              </h4>
              <div className="mt-4">
              
              To learn more about our courses and special offers, please contact CODOMO team. 
              We would be happy to provide you with further information and answer any questions you may have.
              </div>
              <ul className=" space-y-[19px] pt-4">
                <li className=" flex items-center space-x-3">
                    <div className="flex-none">
                      <span className="h-8 w-8 rounded bg-red-paste text-primary flex flex-col justify-center items-center text-lg transition  hover:bg-primary hover:text-white">
                        <iconify-icon icon="ion:mail-open"></iconify-icon>
                      </span>
                    </div>
                  <span className="text-md flex-1">codomomgmt@gmail.com</span>
                </li>
                <li className=" flex items-center space-x-3">
                    <div className="flex-none">
                      <span className="h-8 w-8 rounded bg-[#ECECEC] text-[#827878] flex flex-col justify-center items-center text-lg transition  hover:bg-brown hover:text-white">
                        <iconify-icon icon="material-symbols:phone-in-talk-sharp"></iconify-icon>
                      </span>
                    </div>
                  <span className="text-md flex-1">+91 9605799251</span>
                  
                </li>
                <li className=" flex items-center space-x-3">
                    <div className="flex-none">
                      <span className="h-8 w-8 rounded bg-green-paste text-secondary flex flex-col justify-center items-center text-lg transition  hover:bg-secondary hover:text-white">
                        <iconify-icon icon="ic:twotone-whatsapp"></iconify-icon>
                      </span>
                    </div>
                  <span className="text-md flex-1">+91 9062378215</span>
                </li>
              </ul>
      

              <div className="flex-none pt-4">
                    <button className="btn btn-black block text-center " disabled={usedGoogle} style={{ display: 'flex',alignItems: 'center'  }} onClick={login}>
                        <img
                            className="center"
                            src={GoogleIcon}
                            alt=""
                            style={{ marginRight: '0.5em' }}/>
                            Sign Up Using Google
                    
                </button> 
              </div>

            </div>
            <div className="xl:col-span-7 lg:col-span-6 col-span-12">
              <div className="bg-white shadow-box7 p-8 rounded-md">
                <SignUpForm email={email} setEmail={setEmail} name={name} setName={setName} usedGoogle={usedGoogle} googleId={googleId}/>
              </div>
              <div className="resend-otp-text mt-4"> Have we met before?
                  <div className="resend-otp" onClick={() =>navigate("/signIn")}>jump back in</div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
