import React, { useState,useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom"
import Header from "../Header";
import { Table,Input } from "antd";
import {UpdateCourseDetails} from "../../api/aws-api";
import { ConstructorEventPost} from "../../Event/event";
import { ClassicSpinner,MagicSpinner } from "react-spinners-kit";
import Swal from 'sweetalert2'
import { notifyError, notifySuccess } from "../../Common/CommonFunction";

const ActivityManagement = () => {
    const [loading,setLoading] = useState(false)
    const [ActivityEditMenu,setActivityEditMenu] = useState(false)
    const [SaveToCloud,setSaveToCloud] = useState(false)
    const [data,setData] = useState([])

    const [DisplayData,setDisplayData] = useState([])
    const [Description,setDescription] = useState("")
    const [Description_Head,setDescription_Head] = useState("")
    const [Lectures,setLectures] = useState("")
    const [ModuleName,setModuleName] = useState("")
    const [TotalDurationInMinutes,setTotalDurationInMinutes] = useState("")
    const [ActivityResource,setActivityResource] = useState([])
    
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(()=>{
        const {data} = location.state ? location.state : []
        if (data){setData(data);setDisplayData(data["modules"] ? data["modules"] : [])}
      },[])
   
    const EraseDataFromState = () => {
      setDescription("")
      setDescription_Head("")
      setLectures("")
      setModuleName("")
      setTotalDurationInMinutes("")
      setActivityResource([])
    }
    const setDataToEditTab = (data) => {
        setDescription(data.description)
        setDescription_Head(data.description_head)
        setLectures(data.Lectures)
        setModuleName(data.module_name)
        setTotalDurationInMinutes(data.TotalDurationInMinutes)
        setActivityResource(data.resource)
      }
    const columns = [
        {
          title: "Module Name",
          dataIndex: "module_name",
          sorter: (a, b) => a.module_name.length - b.module_name.length,
          render: (text, record) => <Link onClick={()=> (setDataToEditTab(record),setActivityEditMenu(true))}>{text}</Link>
        },
        {
          title: "Description Head",
          dataIndex: "description_head",
          sorter: (a, b) => a.description_head.length - b.description_head.length,
        },
        {
            title: "Resource Count",
            dataIndex: "resource",
            render: (text) => <div>{text.length}</div>
        },
        {
            title: "Duration in Minutes",
            dataIndex: "TotalDurationInMinutes",
        },
        {
            title: "Activities",
            dataIndex: "module_name",
            render: (text,record) => <div >
              <Link to="/codomo/admin/subactivitymanagement" state={{"fulldata": data,"data" : record}}><iconify-icon icon="icon-park-outline:activity-source" style={{ color:"blue" ,fontSize: '1.8em', marginLeft: '20px' }} ></iconify-icon></Link>
              <iconify-icon icon="line-md:remove" style={{ color:"red" ,fontSize: '1.8em', marginLeft: '20px' }} onClick={()=>RemoveItemFromdata(text)}/>
              </div>
        }
      ];

    const UpdateDataInState = () => {
      const temp = {
        "description" : Description,
        "description_head" : Description_Head,
        "Lectures" : Lectures,
        "module_name" : ModuleName,
        "TotalDurationInMinutes" : TotalDurationInMinutes,
        "resource" : ActivityResource
      }
      const Repetation = DisplayData.filter((item)=> item.module_name !== ModuleName)
      setDisplayData([...Repetation,temp])
      setActivityEditMenu(false)
      setSaveToCloud(true)
    }

    const RemoveItemFromdata = (moduleNameOfItem) => {
      const Repetation = DisplayData.filter((item)=> item.module_name !== moduleNameOfItem)
      setDisplayData(Repetation)
      setSaveToCloud(true)
    }
       
     
    const UpdateUserDataToCloud = async() => {
      setLoading(true)
      let temp = data
      temp["modules"] = DisplayData
      const responsedata = await ConstructorEventPost(UpdateCourseDetails,temp)
      if (responsedata === "course_updated"){notifySuccess("Activity Updated");navigate("/codomo/admin/coursemanagement")}
      else {notifyError("Something Gone Wrong")}
      setLoading(false)
    }
       
    return (
        <>
          <Header/>
          {loading ? 
          <div className="spinner" >
            <div className="spinner-wrapper">
              <ClassicSpinner size={50} color ="black" loading={loading} />
              </div></div> :
                <div className="nav-tab-wrapper tabs  section-padding">
                <div className="container">
                  <div className=" grid grid-cols-12 gap-[30px]">
                    <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
                      <div className="mini-title">Activity Management 
                      {SaveToCloud ? <div className="small-btn-saveDataToCloud ml-4" onClick={UpdateUserDataToCloud}>
                        <iconify-icon icon="tabler:cloud-up" style={{fontSize: '1em'}} />
                          Save Changes To Cloud
                        </div> : "" }
                        </div>
                        <div className="small-btn mt-4" onClick={()=>(setActivityEditMenu(true),EraseDataFromState())}>
                        <iconify-icon icon="typcn:plus" style={{fontSize: '1em'}}></iconify-icon> {" "}
                          Create New Activity 
                        </div>
                        
                          {ActivityEditMenu ? 
                          <div className="bg-white shadow-box7 p-8 rounded-md mt-8">
                            <div className="input-container mt-8">
                              <input
                                type="text"
                                className="from-control"
                                value={ModuleName}
                                onChange={(e) => setModuleName(e.target.value)}
                                required
                              />
                              <span className={`input-placeholder ${ModuleName && 'active'}`}>
                                Module Name *
                              </span>
                            </div>
                            <div className="input-container mt-8">
                              <input
                                type="email"
                                className=" from-control"
                                value={Description_Head}
                                onChange={(e) => setDescription_Head(e.target.value)}
                                required
                              />
                              <span className={`input-placeholder ${Description_Head && 'active'}`}>
                                Description Head *
                              </span>
                            </div>
                            <div className="input-container mt-8">
                              <input
                                type="email"
                                className=" from-control"
                                value={Description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                              />
                              <span className={`input-placeholder ${Description && 'active'}`}>
                                Description *
                              </span>
                            </div>
                            <div className="input-container mt-8">
                              <input
                                type="number"
                                className=" from-control"
                                value={TotalDurationInMinutes}
                                onChange={(e) => setTotalDurationInMinutes(e.target.value)}
                                required
                              />
                              <span className={`input-placeholder ${TotalDurationInMinutes && 'active'}`}>
                                Total Duration In Minutes  *
                              </span>
                            </div>
                            <div className="input-container mt-8">
                                <input
                                  type="number"
                                  className='from-control'
                                  value={Lectures}
                                  pattern="[0-9]{10}"
                                  onChange={(e) => setLectures(e.target.value) }
                                  required
                                />
                                <span className={`input-placeholder ${Lectures && 'active'}`}>
                                Lectures *
                                </span>
                              </div>
                            <button className="btn btn-black mt-4 mr-4" onClick={()=>(UpdateDataInState())}>Save</button>
                          </div> 
                        : ""} 
                    </div>
                    <div className="xl:col-span-7 lg:col-span-6 col-span-12">
                      <div className="bg-white shadow-box7 p-8 rounded-md">
                      <div className="from-control mb-4">Total Activity : {DisplayData && DisplayData.length}</div>
                        <Table    
                            className=""      
                            columns={columns}
                            dataSource={DisplayData}      
                            pagination={DisplayData.length > 9 ?true : false}
                            rowKey={(record) => record.key}
                        />
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>}

        </>
    )

}
export default ActivityManagement;