import React, { useEffect, useContext } from "react";
import { ClassicSpinner } from "react-spinners-kit";
import { AuthenticationState } from "../../App";
import { AuthenticateUser, FetchHomePageDetails } from "../../api/aws-api";
import { ConstructorEventPost, ConstructorEventGet } from "../../Event/event";
import { Link, useNavigate } from "react-router-dom";

const Authentication = () => {
  const { setuserAuthenticated, setVerifiedAsMentor, setVerifiedAsAdmin } =
    useContext(AuthenticationState);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("authentication triggered");
    const lastLoginTime = localStorage.getItem("lastLoginTime");
    const authToken = localStorage.getItem("authToken");
    if (authToken && Date.now() - lastLoginTime < 24 * 60 * 120 * 1000) {
      CheckStatus(authToken);
    } else {
      setuserAuthenticated(false);
      navigate("/signIn");
    }
  }, []);

  const CheckStatus = async (authToken) => {
    const storedData = localStorage.getItem("UserKey");
    if (
      storedData !== "undefined" &&
      storedData !== "null" &&
      storedData !== undefined &&
      storedData !== null
    ) {
      const params = JSON.parse(storedData);
      fetchdata(JSON.parse(storedData));
      params["authToken"] = authToken;
      const responsedata = await ConstructorEventPost(AuthenticateUser, params);

      console.log("auth triggered");
      if (responsedata === "authentication_success") {
        setuserAuthenticated(true);
      } else if (responsedata === "authentication_failed") {
        setuserAuthenticated(false);
        navigate("/signIn");
      } else {
        setuserAuthenticated(false);
        navigate("/signIn");
      }
    } else {
      setuserAuthenticated(false);
      navigate("/signIn");
    }
  };

  const fetchdata = async (params) => {
    const responsedata = await ConstructorEventGet(
      FetchHomePageDetails,
      params
    );
    sessionStorage.setItem("UserData", JSON.stringify(responsedata[0]));
    checkUserType(responsedata[0]);
  };

  function checkUserType(data) {
    const userType = data && data.user_type;
    if (userType === "mentor") {
      setVerifiedAsMentor(true);
    } else if (userType === "admin") {
      setVerifiedAsAdmin(true);
    }
  }

  return (
    <>
      <div className=" min-h-screen bg-[url('../images/all-img/404-bg.png')] bg-cover bg-no-repeat bg-center">
        <div className="max-w-[570px] mx-auto flex flex-col items-center text-center justify-center min-h-screen px-4">
          <img alt="" className="mb-14" />
          <h2 className=" mb-6">Authenticating</h2>
          <div className="mb-10">
            You will be granted entry shortly, please be patient.
          </div>
          <button className="btn btn-primary">
            <ClassicSpinner size={30} color="white" loading={true} />
          </button>
        </div>
      </div>
    </>
  );
};

export default Authentication;
