import React, { useState,useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom"
import Header from "../Header";
import { Table,Input } from "antd";
import { FetchUserDetailsForAdmin,UpdateUserDetails} from "../../api/aws-api";
import { ConstructorEventPost,ConstructorEventGet } from "../../Event/event";
import { ClassicSpinner,MagicSpinner } from "react-spinners-kit";
import Swal from 'sweetalert2'

const UserManagement = () => {
    const [loading,setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('');
    const [userdata,setUserData] = useState([])
    const [selecteduser,setSelectedUser] = useState()
    const [filteredData, setFilteredData] = useState([]);
    const [userName,setUserName] = useState("")
    const [userType,setUserType] = useState("")
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [status,setStatus] = useState("")
    const countryPrefix = '+91';
    const navigate = useNavigate();

    useEffect(()=>{
      fetchdata()
      },[])

    const fetchdata = async() => {
        setLoading(true)
        const responsedata = await ConstructorEventGet(FetchUserDetailsForAdmin)
        setUserData(responsedata);
        setFilteredData(responsedata)
        setLoading(false)
        console.log(responsedata)
        let names = responsedata.map(item => item.name);
        console.log(names); 

    }
   
    const setUser = (data) => {
      setSelectedUser(data)
      setUserName(data.name)
      setUserType(data.type)
      setEmail(data.email)
      setPhone(data.mobile)
      setStatus(data.status)
    }
    const columns = [
        {
          title: "Users",
          dataIndex: "name",
          sorter: (a, b) => a.name.length - b.name.length,
          render: (text, record) => <Link onClick={()=> setUser(record)}>{text}</Link>
        },
        {
          title: "User Type",
          dataIndex: "type",
          sorter: (a, b) => a.type.length - b.type.length,
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Phone",
            dataIndex: "mobile",
        },
        {
          title: "Status",
          dataIndex: "status",
      },
      ];
       
    const UpdateUserDataToCloud = async() => {
      setLoading(true)
      const params = {
        user_id : selecteduser.id,
        user_type: userType,
        user_mobile : phone,
        user_status : status,
        user_name : userName,
        user_email : email
      }
      console.log(params)
      const responsedata = await ConstructorEventPost(UpdateUserDetails,params)
      if (responsedata === "updated"){setLoading(false);fetchdata();console.log("updated");setSelectedUser();setSearchValue('')}
      else {setLoading(false)}
    }
    
    const handleSearch = (e) => {
      const searchInput = e.target.value.toLowerCase();
      setSearchValue(searchInput);
      // Filter the data source based on search input value
      const filtered = userdata&&userdata.filter(record =>(record.name.toLowerCase().includes(searchInput)||record.email.toLowerCase().includes(searchInput)||record.mobile.toLowerCase().includes(searchInput)));
      setFilteredData(filtered);
    }
       
    return (
        <>
          <Header/>
          {loading ? 
          <div className="spinner" >
            <div className="spinner-wrapper">
              <ClassicSpinner size={50} color ="black" loading={loading} />
              </div></div> :
                <div className="nav-tab-wrapper tabs  section-padding">
                <div className="container">
                  <div className=" grid grid-cols-12 gap-[30px]">
                    <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
                      <div className="mini-title">User Management
                        <div className="small-btn ml-4" onClick={()=>navigate('/codomo/admin/createnewuser')}>
                          New User {" "}
                          <iconify-icon icon="entypo:add-user" ></iconify-icon>
                        </div>
                      </div>
                        <div className="bg-white shadow-box7 p-8 rounded-md mt-8">
                          <Input
                              placeholder="Search by Name,Phone or Email"
                              className="from-control"
                              value={searchValue}
                              onChange={handleSearch}
                              style={{ marginBottom: 16 }}
                                        
                          />
                          {selecteduser ? 
                          <div>
                            <hr></hr>
                            <div className="input-container mt-8">
                              <input
                                type="text"
                                className="from-control"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                required
                                disabled
                              />
                              <span className={`input-placeholder ${userName && 'active'}`}>
                                Name *
                              </span>
                            </div>
                            <div className="input-container">
                                <select
                                    className="from-control p-4 bg-[#ECECEC] text-[#827878] mt-8"
                                    value={userType}
                                    onChange={(e) => setUserType(e.target.value)}
                                    required
                                > 
                                    <option key="student" value="student">Student</option>
                                    <option key="mentor" value="mentor">Mentor</option>
                                    <option key="admin" value="admin">Admin</option>
                    
                                </select>
                                </div>
                            <div className="input-container mt-8">
                              <input
                                type="email"
                                className=" from-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                disabled
                              />
                              <span className={`input-placeholder ${email && 'active'}`}>
                                Email *
                              </span>
                            </div>
                            <div className="input-container-phone mt-8">
                              {phone ? <span class="input-group-addon">{phone && countryPrefix}</span> : "" }
                                <input
                                  type="text"
                                  className={`from-control-phone ${!phone && 'pl-5'}`}
                                  value={phone}
                                  pattern="[0-9]{10}"
                                  onChange={(e) => setPhone(e.target.value) }
                                  required
                                />
                                <span className={`input-placeholder ${phone && 'active'}`}>
                                Phone *
                                </span>
                              </div>
                              <div className="input-container">
                                <select
                                    className="from-control p-4 bg-[#ECECEC] text-[#827878] mt-8"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    required
                                >
                                    <option key="active" value="active">Active</option> 
                                    <option key="inactive" value="inactive">InActive</option>
                                    <option key="expired" value="expired">Expired</option> 
                                </select>
                                </div>
                            {selecteduser.mobile !== phone ? <button className="btn btn-black mt-4 mr-4" onClick={UpdateUserDataToCloud}>Update User</button>
                            :selecteduser.status !== status ? <button className="btn btn-black mt-4 mr-4" onClick={UpdateUserDataToCloud}>Update User</button>
                            :selecteduser.type !== userType ? <button className="btn btn-black mt-4 mr-4" onClick={UpdateUserDataToCloud}>Update User</button>
                            :selecteduser.name !== userName ? <button className="btn btn-black mt-4 mr-4" onClick={UpdateUserDataToCloud}>Update User</button>
                            :selecteduser.email !== email ? <button className="btn btn-black mt-4 mr-4" onClick={UpdateUserDataToCloud}>Update User</button>
                            :""}
                          </div> 
                        : ""}
                      </div>  
                        
                    </div>
                    <div className="xl:col-span-7 lg:col-span-6 col-span-12">
                      <div className="bg-white shadow-box7 p-8 rounded-md">
                      <div className="from-control mb-4">User :{filteredData && filteredData.length}</div>
                        <Table    
                            className=""      
                            columns={columns}
                            dataSource={filteredData}      
                            pagination={filteredData && filteredData.length > 9 ?true :false}
                            rowKey={(record) => record.key}
                        />
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>}

        </>
    )

}
export default UserManagement;