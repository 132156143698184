/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from "react";
import {
  user3,
  comment,
  q,
  calender,
  clockIcon,
  fbIcon,
  pnIcon,
  twIcon,
  insIcon,
  rc3,
  rc1,
  insIcon1,
  insIcon3,
  insIcon2,
} from "../../constant/images";
import Header from "../Header";
import { ConstructorEventGet } from "../../Event/event";
import { RetrieveBlog } from "../../api/aws-api";
import { ClassicSpinner } from "react-spinners-kit";

const Blog = () => {
  const [blog,setBlog] = useState([])
  const [loading,setLoading] = useState(true)
  useEffect(()=>{
    FetchBlogData()
  },[])
  const FetchBlogData = async () =>{
    setLoading(true)
    const temp = sessionStorage.getItem('blog');
    const parsedTemp = temp ? JSON.parse(temp): null;
    if (parsedTemp) {setLoading(false);setBlog(parsedTemp);}
    const responsedata = await ConstructorEventGet (RetrieveBlog)
    console.log(responsedata)
    sessionStorage.setItem('blog', JSON.stringify(responsedata))
    setBlog(responsedata ? responsedata : [])
    setLoading(false)
  }
  return (
    <>
    <Header/>
    {loading ? 
    <div className="spinner" >
    <div className="spinner-wrapper">
    <ClassicSpinner size={50} color ="black" loading={loading} />
    </div></div>
    :
    <div className="nav-tab-wrapper tabs  section-padding">
      <div className="container">
        <div className="grid grid-cols-12 gap-[30px]">
          <div className="lg:col-span-12 col-span-12">
            <div className="bg-[#F8F8F8] rounded-md">
              <img alt="" className=" rounded-t-md mb-10 " />
              <div className="px-10 pb-10">
                <div className="flex  flex-wrap  xl:space-x-10  mt-6 mb-6">
                  <a className=" flex items-center space-x-4 mr-4" href="#">
                    <img src={user3} alt="" />
                    <span>Codomo</span>
                  </a>
                  <a className=" flex items-center space-x-4 mr-4" href="#">
                    <img src={calender} alt="" />
                    <span>3 21 Feb, 22</span>
                  </a>
                  <a className=" flex items-center space-x-2 mr-4" href="#">
                    <img src={clockIcon} alt="" />
                    <span>3 Min Read</span>
                  </a>
                </div>
                <h3>
                  {blog && blog[0].Heading }
                </h3>
                <p className="mt-6">
                    {blog && blog[0].Content }
                </p>
              </div>
            </div>
            <div className=" mt-14">
              <h3 className=" mb-4">Share your thoughts</h3>
              <div>
                Your email address will not be published. Requires fields are
                marked*
              </div>
            </div>
            <form
                  className=" md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6 "
                >
                  <div>
                    <input
                      type="text"
                      className=" from-control"
                      placeholder="Name*"
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      className=" from-control"
                      placeholder="Email*"

                    />
                  </div>
                  <div className="md:col-span-2 col-span-1">
                    <textarea
                      className=" from-control"
                      placeholder="Your Message*"
                      rows="5"
                    ></textarea>
                  </div>
                  <button class="btn btn-primary mt-[10px]" disabled> {/*type="submit" name="submit"*/} 
                    Send Message
                  </button>
                </form>
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};

export default Blog;
