import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Header from "../Header";
import { Table } from "antd";
import { UpdateCourseDetails } from "../../api/aws-api";
import { ConstructorEventPost } from "../../Event/event";
import { ClassicSpinner } from "react-spinners-kit";
import { notifyError, notifySuccess } from "../../Common/CommonFunction";

const SubActivityManagement = () => {
  const [loading, setLoading] = useState(false);
  const [ActivityEditMenu, setActivityEditMenu] = useState(false);
  const [SaveToCloud, setSaveToCloud] = useState(false);
  const [data, setData] = useState();
  const [FullData, setFullData] = useState();

  const [DisplayData, setDisplayData] = useState([]);
  const [Name, setName] = useState("");
  const [Duration, setDuration] = useState("");
  const [Description, setDescription] = useState("");
  const [S3Path, setS3Path] = useState("");
  const [Type, setType] = useState("");
  const [Url, setUrl] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { fulldata, data } = location.state ? location.state : [];
    if (data) {
      setData(data);
      setDisplayData(data["resource"] ? data["resource"] : []);
    }
    if (fulldata) {
      setFullData(fulldata);
    }
  }, []);

  const EraseDataFromState = () => {
    setName("");
    setDuration("");
    setDescription("");
    setS3Path("");
    setType("");
    setUrl("");
  };
  const setDataToEditTab = (data) => {
    setName(data.name);
    setDuration(data.duration_in_minutes);
    setDescription(data.description);
    setS3Path(data.s3_path);
    setType(data.type);
    setUrl(data.url);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.SessionName.length - b.SessionName.length,
      render: (text, record) => (
        <Link
          onClick={() => (setDataToEditTab(record), setActivityEditMenu(true))}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Duration In Minutes",
      dataIndex: "duration_in_minutes",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "S3 Path",
      dataIndex: "s3_path",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: " ",
      dataIndex: "name",
      render: (text) => (
        <div>
          <iconify-icon
            icon="line-md:remove"
            style={{ color: "red", fontSize: "1.8em", marginLeft: "20px" }}
            onClick={() => RemoveItemFromdata(text)}
          />
        </div>
      ),
    },
  ];

  const UpdateDataInState = () => {
    const temp = {
      name: Name,
      duration_in_minutes: Duration,
      description: Description,
      s3_path: S3Path,
      type: Type,
      url: Url,
    };
    const Repetation = DisplayData.filter((item) => item.name !== Name);
    setDisplayData([...Repetation, temp]);
    setActivityEditMenu(false);
    setSaveToCloud(true);
  };

  const RemoveItemFromdata = (SubActivityName) => {
    const Repetation = DisplayData.filter(
      (item) => item.name !== SubActivityName
    );
    setDisplayData(Repetation);
    setSaveToCloud(true);
  };

  const UpdateUserDataToCloud = async () => {
    setLoading(true);
    let tempFull = FullData;
    let temp = data;

    temp["resource"] = DisplayData;
    const ModuleFiletered = FullData["modules"].filter(
      (item) => item.module_name !== temp["module_name"]
    );
    tempFull["modules"] = [...ModuleFiletered, temp];

    const responsedata = await ConstructorEventPost(
      UpdateCourseDetails,
      tempFull
    );
    if (responsedata === "course_updated") {
      notifySuccess("Sub Activity Updated");
      navigate("/codomo/admin/coursemanagement");
    } else {
      notifyError("Something Gone Wrong");
    }
    setLoading(false);
  };

  return (
    <>
      <Header />
      {loading ? (
        <div className="spinner">
          <div className="spinner-wrapper">
            <ClassicSpinner size={50} color="black" loading={loading} />
          </div>
        </div>
      ) : (
        <div className="nav-tab-wrapper tabs  section-padding">
          <div className="container">
            <div className=" grid grid-cols-12 gap-[30px]">
              <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
                <div className="mini-title">
                  Sub Activity Management
                  {SaveToCloud ? (
                    <div
                      className="small-btn-saveDataToCloud  ml-4"
                      onClick={UpdateUserDataToCloud}
                    >
                      <iconify-icon
                        icon="tabler:cloud-up"
                        style={{ fontSize: "1em" }}
                      />
                      Save Changes To Cloud
                    </div>
                  ) : (
                    ""
                  )}{" "}
                </div>
                <div
                  className="small-btn mt-4"
                  onClick={() => (
                    setActivityEditMenu(true), EraseDataFromState()
                  )}
                >
                  <iconify-icon
                    icon="typcn:plus"
                    style={{ fontSize: "1em" }}
                  ></iconify-icon>{" "}
                  Add New Sub Activity
                </div>

                {ActivityEditMenu ? (
                  <div className="bg-white shadow-box7 p-8 rounded-md mt-8">
                    <div>
                      <div className="input-container mt-8">
                        <input
                          type="text"
                          className="from-control"
                          value={Name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        <span
                          className={`input-placeholder ${Name && "active"}`}
                        >
                          Name *
                        </span>
                      </div>
                      <div className="input-container mt-8">
                        <input
                          type="number"
                          className=" from-control"
                          value={Duration}
                          onChange={(e) => setDuration(e.target.value)}
                          required
                        />
                        <span
                          className={`input-placeholder ${
                            Duration && "active"
                          }`}
                        >
                          Duration in Minutes *
                        </span>
                      </div>
                      <div className="input-container mt-8">
                        <input
                          type="email"
                          className=" from-control"
                          value={Description}
                          onChange={(e) => setDescription(e.target.value)}
                          required
                        />
                        <span
                          className={`input-placeholder ${
                            Description && "active"
                          }`}
                        >
                          Description *
                        </span>
                      </div>
                      <div className="input-container mt-8">
                        <input
                          type="email"
                          className=" from-control"
                          value={S3Path}
                          onChange={(e) => setS3Path(e.target.value)}
                          required
                        />
                        <span
                          className={`input-placeholder ${S3Path && "active"}`}
                        >
                          S3 Path *
                        </span>
                      </div>
                      <div className="input-container mt-8">
                        <input
                          type="email"
                          className=" from-control"
                          value={Type}
                          onChange={(e) => setType(e.target.value)}
                          required
                        />
                        <span
                          className={`input-placeholder ${Type && "active"}`}
                        >
                          Type *
                        </span>
                      </div>
                      <div className="input-container mt-8">
                        <input
                          type="email"
                          className=" from-control"
                          value={Url}
                          onChange={(e) => setUrl(e.target.value)}
                          required
                        />
                        <span
                          className={`input-placeholder ${Url && "active"}`}
                        >
                          Url *
                        </span>
                      </div>
                      <button
                        className="btn btn-black mt-4 mr-4"
                        onClick={UpdateDataInState}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="xl:col-span-7 lg:col-span-6 col-span-12">
                <div className="bg-white shadow-box7 p-8 rounded-md">
                  <div className="from-control mb-4">
                    Total Sub Activity : {DisplayData && DisplayData.length}
                  </div>
                  <Table
                    className=""
                    columns={columns}
                    dataSource={DisplayData}
                    pagination={
                      DisplayData && DisplayData.length > 9 ? true : false
                    }
                    rowKey={(record) => record.key}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default SubActivityManagement;
