import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../Header";

const OneCompilerIframe = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [NavigationUrl, setNavigationUrl] = useState("");
  useEffect(() => {
    // let UrlTest  = "https://onecompiler.com/embed/javascript/3wyne344h"
    // if (UrlTest.includes("onecompiler.com")){setNavigationUrl(UrlTest)}
    // const { Url } = location.state ? location.state : {}
    // if (Url) { setNavigationUrl(Url) }
    const storedData = sessionStorage.getItem("OneCompilerUrl");
    if (
      storedData !== "undefined" &&
      storedData !== "null" &&
      storedData !== undefined &&
      storedData !== null
    ) {
      if(storedData.includes("editor")){
        function modifyUrl(originalUrl, oldWord, newWord) {
          // Use regular expression to find and replace the old word in the URL
          let modifiedUrl = originalUrl.replace(new RegExp(oldWord, 'g'), newWord);
          return modifiedUrl;
      }
      
      // Example usage
      let originalUrl = storedData;
      let oldWord = "editor";
      let newWord = "embed";
      let modifiedUrl = modifyUrl(originalUrl, oldWord, newWord);
      console.log(`The modified url will be ${modifiedUrl}`);
      setNavigationUrl(modifiedUrl)
      }else{
        setNavigationUrl(storedData);
      }
      // setNavigationUrl(storedData)
      
    } else {
      navigate("/codomo/home");
    }
  }, []);

  console.log(`The navigation inside the ${NavigationUrl}`)

  return (
    <>
      <Header />
      <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container ">
          <div style={{ height: "100vh" }}>
            <iframe
              frameBorder="0"
              style={{
                height: "600px",
                width: "100%",
                frameborder: "0",
                marginwidth: "0",
                marginheight: "0",
              }}
              src={NavigationUrl}
              title="Embedded Content"
            ></iframe>
          </div>
          {/* <div style={{
            position:"relative",
            height:"0",
            paddingBottom: "40%",
            overflow:"hidden"
          }}><iframe
        src="https://makecode.microbit.org/#pub:_44PLf7AKHDJC&embed=1&nofooter=1"
        style={{ border: 0, width: '100%', height: '600px' }}
        allowFullScreen
      />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default OneCompilerIframe;
