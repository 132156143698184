import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/newacitivity.css";
import { SaveHtmlStructure } from "../../api/aws-api";
import { FetchHomePageDetails } from "../../api/aws-api";
import { ConstructorEventGet } from "../../Event/event";
import Header from "../Header";


export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();

  const [workspaceItems, setWorkspaceItems] = useState([]);

  const [activitystarted, setActivitystarted] = useState(false);
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [ClickedNext, setClickedNext] = useState(false);
  const [dragging, setDragging] = useState(true);
  const [divtochangeContent, setDivtochangeContent] = useState([]);
  const [screen, setScreen] = useState("Left");
  const [selectedAudio, setSelectedAudio] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isMobileClicked, setMobileClicked] = useState(false); // New state
  const [selectedAttribute, setSelectedAttribute] = useState(false);
  const [propertyScreen, setPropertyScreen] = useState("");
  const [selectedPropertyColor, setPropertySelectedColor] = useState("");
  const [valuePropertyDropdown, setPropertyValueDropdown] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [ValueDropdown, setValueDropdown] = useState("");
  const [selectedpopup, setSelectedpopup] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [fileData, setFileData] = useState(null);

  const dataone = {
    Sound: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/original-phone-ringtone-36558.mp3",
    Drum: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/grunge-bounce-drum-loop-40464.mp3",
    Rain: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/heavy-rain-nature-sounds-8186.mp3",
    Guitar: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/guitar-riff-159089.mp3",
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.id);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");

    const newWorkspaceItem = {
      id: workspaceItems.length + 1,
      type: droppedItemId,
    };

    setWorkspaceItems([...workspaceItems, newWorkspaceItem]);
  };

  const loadFile = async () => {
    try {
      const response = await fetch("https://jayanth70122.github.io/heart/heart"); // Correct URL to your .hex file
      if (!response.ok) {
        throw new Error("File not found");
      }
      const fileBlob = await response.blob();
      setFileData(fileBlob);
    } catch (error) {
      console.error("Error loading the file:", error);
    }
  };
  const loadFilebeating = async () => {
    try {
      const response = await fetch("https://jayanth70122.github.io/heart/beatingheart"); // Correct URL to your .hex file
      if (!response.ok) {
        throw new Error("File not found");
      }
      const fileBlob = await response.blob();
      setFileData(fileBlob);
    } catch (error) {
      console.error("Error loading the file:", error);
    }
  };
  const loadFileduck = async () => {
    try {
      const response = await fetch("https://jayanth70122.github.io/heart/duck"); // Correct URL to your .hex file
      if (!response.ok) {
        throw new Error("File not found");
      }
      const fileBlob = await response.blob();
      setFileData(fileBlob);
    } catch (error) {
      console.error("Error loading the file:", error);
    }
  };
  const loadFilegiraffe = async () => {
    try {
      const response = await fetch("https://jayanth70122.github.io/heart/giraffe"); // Correct URL to your .hex file
      if (!response.ok) {
        throw new Error("File not found");
      }
      const fileBlob = await response.blob();
      setFileData(fileBlob);
    } catch (error) {
      console.error("Error loading the file:", error);
    }
  };
  const loadFiletortoise = async () => {
    try {
      const response = await fetch("https://jayanth70122.github.io/heart/tortoise"); // Correct URL to your .hex file
      if (!response.ok) {
        throw new Error("File not found");
      }
      const fileBlob = await response.blob();
      setFileData(fileBlob);
    } catch (error) {
      console.error("Error loading the file:", error);
    }
  };
  const loadFilecow = async () => {
    try {
      const response = await fetch("https://jayanth70122.github.io/heart/duck"); // Correct URL to your .hex file
      if (!response.ok) {
        throw new Error("File not found");
      }
      const fileBlob = await response.blob();
      setFileData(fileBlob);
    } catch (error) {
      console.error("Error loading the file:", error);
    }
  };
  const loadFilesword = async () => {
    try {
      const response = await fetch("https://jayanth70122.github.io/heart/sword"); // Correct URL to your .hex file
      if (!response.ok) {
        throw new Error("File not found");
      }
      const fileBlob = await response.blob();
      setFileData(fileBlob);
    } catch (error) {
      console.error("Error loading the file:", error);
    }
  };
  const loadFileshake = async () => {
    try {
      const response = await fetch("https://jayanth70122.github.io/heart/shake"); // Correct URL to your .hex file
      if (!response.ok) {
        throw new Error("File not found");
      }
      const fileBlob = await response.blob();
      setFileData(fileBlob);
    } catch (error) {
      console.error("Error loading the file:", error);
    }
  };
  const loadFileskull = async () => {
    try {
      const response = await fetch("https://jayanth70122.github.io/heart/skull"); // Correct URL to your .hex file
      if (!response.ok) {
        throw new Error("File not found");
      }
      const fileBlob = await response.blob();
      setFileData(fileBlob);
    } catch (error) {
      console.error("Error loading the file:", error);
    }
  };


  const handleDownload = () => {
    if (!fileData) {
      alert("File not loaded yet. Please load the file first.");
      return;
    }

    // Create a URL for the file blob
    const url = URL.createObjectURL(fileData);

    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "file.hex"; // The file name
    document.body.appendChild(a);
    a.click();

    // Clean up the URL object and remove the temporary link
    URL.revokeObjectURL(url);
    a.remove();
  };


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    setHtmlCode(data);
  }

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  const handleMobileClick = () => {
    setMobileClicked(true);
    if (activitystarted && selectedOption === "Click" && isMobileClicked) {
      var audio = new Audio(selectedAudio);
      audio.play();
      setScreen(SelectedScreen);
      if (propertyScreen && selectedPropertyColor && valuePropertyDropdown) {
        console.log(propertyScreen);
        console.log(selectedPropertyColor);
        console.log(valuePropertyDropdown);
        setSelectedScreen(propertyScreen);
        setSelectedColor(selectedPropertyColor);
        setValueDropdown(valuePropertyDropdown);
        setScreen(propertyScreen);
      }
    }
  };

  return (
    <>
    <Header />

    <div style={{
      backgroundImage: "url('https://img.freepik.com/premium-vector/abstract-composition-with-subtle-lines-circle-shape-minimalist-illustration-design_181477-620.jpg?w=900')",
      backgroundSize: "cover", // Ensures the background covers the entire area
      backgroundPosition: "center", // Centers the background image
      backgroundRepeat: "no-repeat", // Prevents repeating the image
      minHeight: "100vh", // Makes sure the background covers the entire viewport height
    }}>
    <div className="container"
    
    >
      <div>

        <div>
            <div className="Newbody">
              <div className="Newheader"></div>
            </div>
        </div>

        <div className="lg:col-span-9 col-span-12 mt-5">
          <i className="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>
          <div className="NavbarApp">
            <span>Instructions</span>
            <button className="getHtmlCodeBtn" onClick={handleHtmlCode}>
              Share App
            </button>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="box">
                <div className="box-head">Toolbox</div>

                <div className="box-content p-3">
                  <div
                    className="pin"
                    draggable="true"
                    id="setProperty"
                    onDragStart={handleDragStart}
                  >
                    <div className="divtochange flex">setProperty </div>
                  </div>

                  <div
                    className="pin"
                    draggable="true"
                    id="setScreen"
                    onDragStart={handleDragStart}
                  >
                    <div className="divtochange flex">setScreen ( screenid )</div>
                  </div>

                  <div
                    className="pin"
                    draggable="true"
                    id="playSound"
                    onDragStart={handleDragStart}
                  >
                    <div className="divtochange flex">playSound ( URL )</div>
                  </div>

                  <div
                    className="plug-board work-space"
                    draggable="true"
                    id="onEvent"
                    onDragStart={handleDragStart}
                  >
                    <div className="plug-board-top">
                      OnEvent (id, type, callback)
                      <div className="function">Function</div>
                    </div>

                    <div className="plug">
                      <div
                        className="plug-blue"
                        ondrop="drop(event)"
                        ondragover="allowDrop(event)"
                      ></div>
                    </div>

                    <div className="plug-board-bottom">
                      <div className="bottom-blue"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <button
                className="button-12 mt-2"
                style={{ textAlign: "center" }}
                
              >
                RESET
              </button> */}

            <div className="col-9">
              <div className="box">
                <div className="box-head">Workspace</div>
                <div className="box-content p-3" onDrop={handleDrop} onDragOver={handleDragOver}>
                  {workspaceItems.map((item) => (
                    <div key={item.id} className="divtochange flex">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="10em" height="10em" viewBox="0 0 36 36" onClick={handleDownload}>
                        <path fill="#dd2e44" d="M35.885 11.833c0-5.45-4.418-9.868-9.867-9.868c-3.308 0-6.227 1.633-8.018 4.129c-1.791-2.496-4.71-4.129-8.017-4.129c-5.45 0-9.868 4.417-9.868 9.868c0 .772.098 1.52.266 2.241C1.751 22.587 11.216 31.568 18 34.034c6.783-2.466 16.249-11.447 17.617-19.959c.17-.721.268-1.469.268-2.242"  onClick={handleDownload}></path>
                      </svg> */}
                      {/* <button onClick={()=>{loadFile(),handleDownload()}}>Load HEX File</button> */}
                      {/* <button onClick={() => { loadFile(); handleDownload(); }}>Load HEX File</button> */}
                      {item.type === "setProperty" && (
                        <div className="pin">
                          Basics (
                          <select
                            className="dropdown"
                            onChange={(e) => {
                              if (e.target.value === "Left") {
                                loadFile(); // Load the file
                                handleDownload(); // Trigger the download
                              }
                              else if (e.target.value === "Right") {
                                loadFilebeating(); // Load the file
                                handleDownload(); // Trigger the download
                              }
                            }}
                          >
                            <option>Select</option>
                            <option value="Left">Heart</option>
                            <option value="Right">Beating Heart</option>
                          </select>
                          )
                        </div>
                      )}



                      {item.type === "setScreen" && (
                        <div className="pin">
                          Animals (
                          <select className="dropdown" onChange={(e) => {
                            if (e.target.value === "Duck") {
                              handleDownload();
                              loadFileduck();
                            }
                            else if (e.target.value === "Cow") {
                              handleDownload();
                              loadFilecow();
                            }
                            else if (e.target.value === "Giraffe") {
                              handleDownload();
                              loadFilecow();
                            }
                            else if (e.target.value === "Tortoise") {
                              handleDownload();
                              loadFilecow();
                            }
                          }}>
                            <option>Select</option>
                            <option value="Duck">Duck</option>
                            <option value="Tortoise">Tortoise</option>
                            <option value="Giraffe">Giraffe</option>
                            <option value="Cow">Cow</option>
                          </select>
                          )
                        </div>
                      )}

                      {item.type === "playSound" && (
                        <div className="pin">
                          playSound (
                          <select
                            className="dropdown"
                            onChange={(e) => {
                              if (e.target.value === "sword") {
                                loadFilesword(); // Load the file
                                handleDownload(); // Trigger the download
                              }
                              else if (e.target.value === "skull") {
                                loadFileskull(); // Load the file
                                handleDownload(); // Trigger the download
                              }
                              else if (e.target.value === "shake") {
                                loadFileshake(); // Load the file
                                handleDownload(); // Trigger the download
                              }
                            }}
                          >
                            <option value="">Select Sound</option>
                            <option value="sword">Sword</option>
                            <option value="skull">Skull</option>
                            <option value="shake">Shake</option>

                          </select>
                          )
                        </div>
                      )}

                      {item.type === "onEvent" && (
                        <div className="plug-board work-space">
                          <div className="plug-board-top">
                            OnEvent (id, type, callback)
                            <div className="function">Function</div>
                          </div>

                          <div className="plug">
                            <div
                              className="plug-blue"
                              ondrop="drop(event)"
                              ondragover="allowDrop(event)"
                            ></div>
                          </div>

                          <div className="plug-board-bottom">
                            <div className="bottom-blue"></div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {selectedpopup && (
            <div className="modal">
              <div className="overlay">
                <div className="modal-content">
                  {htmlCode}
                  <button className="btn" style={{ marginTop: "30px" }} onClick={popupvalue}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
    </div>
    </>
  );
}

export default Appone;
