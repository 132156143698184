import React from "react";
import Header from "../Header";
import About from "./About";


const AboutOne = () => {
  return (
    <>
      <Header />
      <About />
    </>
  );
};

export default AboutOne;
