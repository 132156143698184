import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import { FetchBatchData } from "../../api/aws-api";
import { ConstructorEventGet, ConstructorEventPost } from '../../Event/event';
import { PublishMessageAsBatchNotification } from "../../api/aws-api";



const NotificationService = (props) => {
  const [batch, setBatch] = useState();
  const [batchoptions, setBatchoptions] = useState([]);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    FetchBatchList()
  }, [])
  const FetchBatchList = async () => {
    const id = JSON.parse(localStorage.getItem('UserKey'))["user_id"];
    const responsedata = await ConstructorEventGet(FetchBatchData, { "mentorId": id })
    console.log(responsedata)
    setBatchoptions(responsedata ? responsedata : [])
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const Params = {
      "BatchId": batch,
      "Message": message
    }
    const responsedata = await ConstructorEventPost(PublishMessageAsBatchNotification, Params)
    console.log(Params, responsedata)
    if (responsedata[0] === "Message sent successfully") { setLoading(false); navigate("/codomo/home") }
  };
  return (
    <>
      <Header />
      <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container">
          <div className=" grid grid-cols-12 gap-[30px]">
            <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
              <div className="mini-title">Notification</div>
              <h4 className="column-title ">
                Send Notification
              </h4>
              <div>
                Send Batch Wise Notification to Students
              </div>
              <ul className=" list-item space-y-6 pt-8">
                <li className="flex">
                  <div className="xl:col-span-7 lg:col-span-6 col-span-12">
                    <div className=" rounded-md">

                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="xl:col-span-7 lg:col-span-6 col-span-12">
              <div className="bg-white shadow-box7 p-8 rounded-md">
                <form
                  onSubmit={handleSubmit}
                  className=" md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6 "
                >
                  <div className="input-container">
                    <select
                      className="from-control"
                      value={batch}
                      onChange={(e) => setBatch(e.target.value)}
                      required
                    >
                      <option value=""></option>
                      {batchoptions.map((item, index) => (
                        <option key={index} value={item.BatchId}>
                          {item.BatchName}
                        </option>
                      ))}

                    </select>
                    <span className={`input-placeholder ${batch && 'active'}`}>
                      Select Batch*
                    </span>
                  </div>

                  <div className="md:col-span-2 col-span-1 input-container">
                    <textarea
                      className=" from-control"
                      rows="3"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                    <span className={`input-placeholder ${message && 'active'}`}>
                      Your Message*
                    </span>
                  </div>
                  <button className="btn btn-primary mt-[10px]" type="submit" name="submit">
                    {loading ? 'sending..' : 'Send Message'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationService;