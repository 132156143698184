import React, { useState } from 'react';
import tinycolor from 'tinycolor2';

function DragAndDrop() {
  const [dragging, setDragging] = useState(false);
  const [activitystarted, setActivitystarted] = useState(false);
  const [colorchange, setColorchange] = useState({ "IconId": "20", "colour": "" });
  const [LastItemId, setLastItemId] = useState(10)


  const clearDraggedItems = () => {
    const dropZone = document.getElementById('drop-zone');
    // Remove all child elements from the drop zone
    while (dropZone.firstChild) {
      dropZone.removeChild(dropZone.firstChild);
    }
  };


  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', e.target.id);
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData('text/plain');
    const droppedItem = document.getElementById(droppedItemId);
    const clone = droppedItem.cloneNode(true);
    clone.id = LastItemId
    setLastItemId(LastItemId + 1)
    clone.addEventListener('click', (e) => {
      ClickedClonnedItem(e); // Example onclick functionality
    });
    e.target.appendChild(clone);
    setDragging(false);
  };

  const ClickedClonnedItem = (e) => {
    e.preventDefault();
    const newColor = tinycolor.random().toHexString();
    console.log(e.target.id)
    const SelectedItem = document.getElementById(e.target.id)
    SelectedItem.style.color = newColor
    console.log("from clonned item function")
  }
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const changeColor = (Id) => {
    const newColor = tinycolor.random().toHexString();
    setColorchange({ "IconId": Id, "colour": newColor });
  };

  return (
    <div className="container">
      <div className='grid grid-cols-12'>
        <div className="lg:col-span-3 col-span-12 ">
          <div className='design-vew4'>
            <div
              id="drop-zone"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              style={{
                border: '1px dashed #ccc',
                minHeight: '380px',
                minWidth: '150px',
                padding: '20px',
                color: 'black'
              }}
            >
              {dragging ? (
                <p>Drop the item here...</p>
              ) : (
                <p>Drag the item here...</p>
              )}
            </div>
            <div
              // id="draggable-item"
              // draggable="true"
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              style={{
                backgroundColor: '',
                padding: '10px',
                marginTop: '20px',
                cursor: 'grab',
              }}>

              <div
                id="drop-zone"
                onDrop={handleDrop}
                onDragOver={handleDragOver}>

                <iconify-icon style={colorchange["IconId"] === "1" ? { color: colorchange["colour"] } : {}} onClick={() => changeColor("1")} icon="cib:apple-music" width="30" id='draggable-item1' draggable="true"></iconify-icon>
                <iconify-icon style={colorchange["IconId"] === "2" ? { color: colorchange["colour"] } : {}} onClick={() => changeColor("2")} icon="mdi:star" width="30" id='draggable-item2' draggable="true"></iconify-icon>
                <iconify-icon style={colorchange["IconId"] === "3" ? { color: colorchange["colour"] } : {}} onClick={() => changeColor("3")} icon="ph:heart-fill" width="30" id='draggable-item3' draggable="true"></iconify-icon>
                <iconify-icon style={colorchange["IconId"] === "4" ? { color: colorchange["colour"] } : {}} onClick={() => changeColor("4")} icon="ph:smiley-fill" width="30" id='draggable-item4' draggable="true"></iconify-icon>
                <iconify-icon style={colorchange["IconId"] === "5" ? { color: colorchange["colour"] } : {}} onClick={() => changeColor("5")} icon="heroicons:hand-thumb-up-20-solid" width="30" id='draggable-item5' draggable="true"></iconify-icon>
                <iconify-icon style={colorchange["IconId"] === "6" ? { color: colorchange["colour"] } : {}} onClick={() => changeColor("6")} icon="mdi:bird" width="30" id='draggable-item6' draggable="true" ></iconify-icon>
              </div>
            </div>
            <button className="button-86 mt-10" onClick={clearDraggedItems}>Reset</button>
          </div>
        </div>



        <div class="lg:col-span-9 col-span-12">
          <div className='whiteboard'>
            <div class="headerwhitebox"></div>
            <h4 class="rightheading">Stamp clouds  </h4>
            <div class="rightpaneul">
              In This app user can stamp different colour icons on the screen.Choose an icon by clicking on its image again to change the colour
              <ul >
                <h4>Input:</h4>
                <li>1. Which icon  the user clicked(user input)</li>
                <li>2. where on the clouds the user clicks( user clicks)</li>
                <hr></hr>
                <h4>Output</h4>
                <li>1. Change the colour of the icons at the medium</li>
                <li>2. Draw an icon on the paper</li>
                <hr></hr>
                <h4>Processing</h4>
                <li>Out of the Four types of processing that you have learned about,pick one and exlain how it can be used to change the input into output</li>
                <li>*if/then</li>
                <li>*counting</li>
                <li>*finding a match</li>
                <li>*comparing</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default DragAndDrop;
