import React, { useState, useRef } from "react";
import "../../assets/css/newacitivity.css";
import backgroundImage from "../../assets/images/NewActivityImages/background.jpg";


export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();

  const [Activitystarted, setActivitystarted] = useState(false);
  const [divtochangeContent, setDivtochangeContent] = useState([]);
  const [ValueDropdown, setValueDropdown] = useState("");
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [Torch, setTorch] = useState(false);
  const [selectedpopup, setSelectedpopup] = useState(false);

  const data = {
    Onbutton: { background_color: ["red", "blue", "green", "yellow"] },
    "Off button": { background_color: ["red", "blue", "green", "yellow"] },
  };

  const handleDragStart = (e, type) => {
    e.dataTransfer.setData("component_type", type); // Pass the type of component being dragged
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const componentType = e.dataTransfer.getData("component_type");

    // Create a new item and append it to the workspace
    const newItem = {
      id: divtochangeContent.length + 1,
      type: componentType,
    };

    setDivtochangeContent([...divtochangeContent, newItem]);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Allow dropping
  };

  const handleHtmlCode = () => {
    const data = getHtmlCode.current.outerHTML;
    console.log("The extracted HTML code is----", data);
    setHtmlCode(data);
  };

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  const renderWorkspaceItems = () => {
    return divtochangeContent.map((item) => (
      <div key={item.id} className="workspace-item">
        {item.type === "setProperty" && (
          <div className="pin"  style={{
            marginLeft:
               "25px" }}>
            setProperty (
            <select
              value={SelectedScreen}
              onChange={(e) => setSelectedScreen(e.target.value)}
              className="selectinpin"
            >
              <option value="">Select Screen</option>
              {Object.keys(data).map((screen, index) => (
                <option key={index} value={screen}>
                  {screen}
                </option>
              ))}
            </select>
            <select
              value={selectedColor}
              onChange={(e) => setSelectedColor(e.target.value)}
              className="selectinpin"
            >
              <option value="">Select Property</option>
              {data[SelectedScreen] &&
                Object.keys(data[SelectedScreen]).map((property, index) => (
                  <option key={index} value={property}>
                    {property}
                  </option>
                ))}
            </select>
            <select
              value={ValueDropdown}
              onChange={(e) => setValueDropdown(e.target.value)}
              className="selectinpin"
            >
              <option value="">Select Value</option>
              {data[SelectedScreen] &&
                data[SelectedScreen][selectedColor] &&
                data[SelectedScreen][selectedColor].map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
            </select>
            )
          </div>
        )}

        {item.type === "onEvent" && (
          <div className="plug-board work-space">
          <div className="plug-board-top">
            OnEvent (id, type, callback)
            
          </div>
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="container" style={{ overflow: "hidden",backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>

      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12">
          <div className="Newbody">
            <div className="Newheader">
              <div
                className="mobile-phone"
                ref={getHtmlCode}
                style={{
                  backgroundColor:
                    Torch === true && Activitystarted ? "white" : "black",
                }}
              >
                <div>
                  <h3 style={{ marginLeft: "30px", marginTop: "15px" }}>
                    Flash Light
                  </h3>
                  <button
                    className="buttonsession"
                    onClick={() => setTorch(true)}
                    style={{
                      backgroundColor:
                        SelectedScreen === "Onbutton" &&
                        Activitystarted &&
                        selectedColor === "background_color"
                          ? ValueDropdown
                          : "white",
                    }}
                  >
                    ON
                  </button>
                  <button
                    className="buttonsession"
                    onClick={() => setTorch(false)}
                    style={{
                      backgroundColor:
                        SelectedScreen === "Off button" &&
                        Activitystarted &&
                        selectedColor === "background_color"
                          ? ValueDropdown
                          : "white",
                    }}
                  >
                    OFF
                  </button>
                </div>
              </div>
              <button
                className="button-12 mt-4" style={{
                  marginLeft: "0px"
                }}
                onClick={() =>
                  Activitystarted
                    ? setActivitystarted(false)
                    : setActivitystarted(true)
                }
              >
                {Activitystarted ? "RESET" : "RUN"}
              </button>
            </div>
          </div>
        </div>

        <div className="lg:col-span-9 col-span-12 mt-5">
          <i className="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>
          <div className="NavbarApp">&emsp;Instructions</div>
          <div>
            <div className="fontsp">Goal:</div>
            <h6>
              &emsp;&emsp;The screen will start out black. Add code so it turns
              the background-color of the button.
            </h6>
            <div className="fontsp">How:</div>
            <h6>
              &emsp;&emsp; Add code inside the onEvent() that sets the
              "button-color" to "green".{" "}
            </h6>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="box">
                <div className="box-head">&emsp;Toolbox</div>
                <div className="box-content p-3">
                  <div className="controlhead">UI Controls</div>
                  <div>&emsp;</div>

                  {/* Draggable setProperty block */}
                  <div
                    className="pin"
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, "setProperty")}
                  >
                    <div className="divtochange flex" style={{ whiteSpace: "nowrap" }}>setProperty</div>
                  </div>

                  {/* Draggable onEvent block */}
                  <div
                    className="plug-board work-space"
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, "onEvent")}
                  >
                    <div className="plug-board-top">
                      OnEvent (id, type, callback)
                      <div className="function">Function</div>
                    </div>
                    <div className="plug">
                      <div className="plug-blue"></div>
                    </div>
                    <div className="plug-board-bottom">
                      <div className="bottom-blue"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Workspace */}
            <div className="col-9">
              <div className="box">
                <div className="box-head">
                  &emsp;Workspace
                  <a className="btn" onClick={popupvalue}>
                    <button>Show Code</button>
                  </a>
                  {selectedpopup && (
                    <div className="modal">
                      <div className="overlay">
                        <div className="modal-content">
                          <pre>{htmlCode}</pre>
                          <button className="btn" onClick={popupvalue}>
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="box-content p-3"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  style={{ minHeight: "300px" }}
                >
                  {renderWorkspaceItems()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appone;
