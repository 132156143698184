

import React, { useEffect, useState } from "react";
import Header from "../Header";
import { ClassicSpinner, CombSpinner } from "react-spinners-kit";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import { FetchMentorDetails } from "../../api/aws-api";
import { ConstructorEventGet } from "../../Event/event";
import { FetchBatchData } from "../../api/aws-api";
import { Tooltip } from 'react-tooltip';
import { useNavigate } from "react-router-dom";
import "../../assets/css/calenderactivity.css";
import Swal from 'sweetalert2'
import { gapi } from 'gapi-script';


const CLIENT_ID = '347764156937-klkgu25nrg2l7pq8iticmrvha29f3vr7.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCio8YcxXtf28_wN_ZWIHIjrlmzjGbXTJg';
const SCOPES = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/admin.reports.audit.readonly';





function BatchManagement() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [mentorList, setMentorList] = useState([])
    const [mentorName, setMentorName] = useState("")
    const [calenderEvents, setCalenderEvents] = useState([])
    const [mentorId, setMentorId] = useState("")
    const [batch, setBatch] = useState([])
    const batchStatusList = ["Scheduled", "InProgress", "Cancelled", "Completed"]
    const [selectedBatches, setSelectedBatches] = useState([]);
    const [hideCalender, setHideCalender] = useState(false)
    const [status, setStatus] = useState("Cancelled")
    const [batchList, setBatchList] = useState([])
    const [signedIn, setSignedIn] = useState(false);
    const [meetLink, setMeetLink] = useState('');
    const [participants, setParticipants] = useState([]);
    const [eventDetails, setEventDetails] = useState([]);
    const [duration, setDuration] = useState();
    const [mentorExists, setMentorExists] = useState(false);
    const [studentExists, setStudentExists] = useState(false);
    const [meetStartTime, setMeetStartTime] = useState('');
    const [meeetEndTime, setMeetEndTime] = useState('');
    const [meetIdetifier, setMeetIdentifier] = useState();
    const [mentorsTime, setMentorsTime] = useState('');
    const [meetDiff, setMeetDiff] = useState();
    const [meetId, setMeetId] = useState('');
    const [sTime, setSTime] = useState();
    const [mentorETime, setMentorETime] = useState();
    const [mentoremail, setMentoremail] = useState("");
    const [colorChangeInCalender, setColorChangeInCalender] = useState("")



    useEffect(() => {
        // Initial setup
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);

        getMentorsList();
        setSelectedBatches([]);
        setBatchList([]);
        setMentorId("");

        // Google API setup
        function start() {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                scope: SCOPES,
            }).then(() => {
                gapi.client.load('calendar', 'v3'); // Load the Calendar API library
                const authInstance = gapi.auth2.getAuthInstance();
                authInstance.signIn();
                setSignedIn(authInstance.isSignedIn.get());
                authInstance.isSignedIn.listen(setSignedIn);
            });
        }

        gapi.load('client:auth2', start);


    }, []);


    // Ensure mentorId is defined before calling fetchActivities
    // Pass the required meeting code here


    // useEffect(() => {
    //     const { batch, meetLink } = location.state ? location.state : {};
    //     setBatchDetails(batch);
    //     if (meetLink) {
    //         setMeetLink(meetLink); // Set the meet link if passed from BatchManagement
    //     }
    // }, []);


    const signIn = () => {
        gapi.auth2.getAuthInstance().signIn();
    };

    const signOut = () => {
        gapi.auth2.getAuthInstance().signOut();
    };

    const createGoogleMeet = () => {
        if (!gapi.client.calendar) {
            console.error('Google Calendar API is not loaded');
            return;
        }

        const event = {
            summary: 'Google Meet Meeting',
            start: {
                dateTime: new Date(new Date().getTime() + 5 * 60 * 1000).toISOString(), // 5 minutes from now
                timeZone: 'America/Los_Angeles',
            },
            end: {
                dateTime: new Date(new Date().getTime() + 30 * 60 * 1000).toISOString(), // 30 minutes later
                timeZone: 'America/Los_Angeles',
            },
            conferenceData: {
                createRequest: {
                    requestId: 'some-random-string',
                    conferenceSolutionKey: {
                        type: 'hangoutsMeet',
                    },
                },
            },
        };

        gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
            conferenceDataVersion: 1,
        }).then(response => {
            setMeetLink(response.result.hangoutLink);
            console.log('Meet link:', response.result.hangoutLink);
        }).catch(error => {
            console.error('Error creating Google Meet:', error);
        });
    };

    const fetchParticipants = () => {
        setLoading(true);

        if (!gapi.client) {
            console.error('Google API client is not loaded');
            setLoading(false);
            return;
        }

        gapi.client.request({
            path: 'https://admin.googleapis.com/admin/reports/v1/activity/users/all/applications/meet',
            method: 'GET',
        }).then(response => {
            console.log('Full API Response:', response);
            const participants = response.result.items || [];

            const relevantParticipants = participants.filter(item =>
                item.events.some(event => event.name === 'call_join')
            );

            console.log('Filtered Participants:', relevantParticipants);
            setParticipants(relevantParticipants);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching participants:', error);
            setLoading(false);
        });
    };

    const logAllEvents = (result, targetMeetingCode) => {
        const eventsData = [];

        result.items.forEach(item => {
            item.events.forEach(event => {
                console.log(event.parameters)
                const identifierParam = event.parameters?.find(param => param.name === 'identifier');
                const meetingCodeParam = event.parameters?.find(param => param.name === 'meeting_code');
                const durationParam = event.parameters?.find(param => param.name === 'duration_seconds');

                if (meetingCodeParam && meetingCodeParam.value === targetMeetingCode) {
                    const eventData = {
                        identifier: identifierParam ? identifierParam.value : 'na',

                        meeting_code: meetingCodeParam.value,
                        duration_seconds: durationParam ? durationParam.intValue : 'na',
                        event_name: event.name,

                    };

                    eventsData.push(eventData);

                    // Pass the extracted values to handleEventClick
                    handleEventClick(eventData.identifier, eventData.meeting_code, eventData.duration_seconds);
                }
            });
        });

        // Display or log all relevant events
        console.log('All events with the targeted meeting code:', eventsData);
        setEventDetails(eventsData); // Assuming you want to store these in state for display

        return eventsData;
    };

    //   const logCallJoinEvents = (events) => {
    //     events.forEach(event => {
    //       event.events.forEach(e => {
    //         if (e.name === 'call_join') {
    //           console.log('Call Join Event:');
    //           console.log('Event Name:', e.name);
    //           console.log('Parameters:', e.parameters);

    //           const identifierParam = e.parameters.find(param => param.name === 'identifier');
    //           const meetingCodeParam = e.parameters.find(param => param.name === 'meeting_code');
    //           const timeParam = e.parameters.find(param => param.name === 'time'); // Adjust based on the actual parameter name

    //           if (identifierParam) {
    //             console.log('Identifier:', identifierParam.value);
    //           }

    //           if (meetingCodeParam) {
    //             console.log('Meeting Code:', meetingCodeParam.value);
    //           }

    //           if (timeParam) {
    //             console.log('Join Time:', new Date(timeParam.value).toISOString());
    //           }
    //         }
    //       });
    //     });
    //   };

    const fetchActivities = (targetMeetingCode, startingTime, event) => {
        //setLoading(true)


        if (!gapi.client || !gapi.client.request) {
            console.error('Google API client is not loaded or initialized');
            setLoading(false);
            return;
        }

        gapi.client.request({
            path: 'https://admin.googleapis.com/admin/reports/v1/activity/users/all/applications/meet',
            method: 'GET',
        }).then(response => {
            const eventsData = response.result.items.flatMap(item => item.events.map(event => {

                response.result.items.forEach((item) => {
                    const time = item.id.time;
                    const events = item.events;
                });




                const identifierParam = event.parameters.find(param => param.name === 'identifier');
                const meetingCodeParam = event.parameters.find(param => param.name === 'meeting_code');
                const durationParam = event.parameters.find(param => param.name === 'duration_seconds');

                return {
                    time: item.id.time,
                    identifier: identifierParam ? identifierParam.value : 'na',
                    meeting_code: meetingCodeParam ? meetingCodeParam.value : 'na',
                    duration_seconds: durationParam ? durationParam.intValue : 'na',
                };
            }));

            const filteredEvents = eventsData.filter(event => event.meeting_code === targetMeetingCode);
            const mentorEvents = filteredEvents.filter(event => event.identifier === "naveencodomo@gmail.com");

            const scheduledDate = `${startingTime.toLocaleDateString()} ${startingTime.toLocaleTimeString()}`

            const timeArray = filteredEvents.map(item => new Date(item.time));

            // Find minimum and maximum times
            const min = new Date(Math.min(...timeArray));
            const max = new Date(Math.max(...timeArray));



            const meetStartTime = min.toLocaleString()
            const meetEndTime = max.toLocaleString()



            const identifierDurations = filteredEvents.reduce((acc, curr) => {
                const { identifier, duration_seconds } = curr;
                if (duration_seconds === 'na') return acc;

                const duration = parseInt(duration_seconds);
                if (!acc[identifier]) {
                    acc[identifier] = duration;
                } else {
                    acc[identifier] = Math.max(acc[identifier], duration);
                }
                return acc;
            }, {});

            const meetingAttendees = Object.keys(identifierDurations).join(', ');

            const mentorearlyTime = mentorEvents.reduce((earliest, curr) => {
                const currTime = new Date(curr.time);
                return currTime < earliest ? currTime : earliest;
            }, new Date(filteredEvents[0].time));

            const mentorsMeetingSecond = mentorEvents[0].duration_seconds / 60

            const mentorTime = new Date(mentorearlyTime.getTime() - mentorsMeetingSecond * 60000)

            const mentorJoiningTime = `${mentorTime.toLocaleDateString()} ${mentorTime.toLocaleTimeString()}`

            console.log("meettt", min, mentorTime)



            const meetingTime = max - min
            const meetInMinutes = meetingTime / (1000 * 60);
            const meetTotalTimingInMinutes = meetInMinutes.toFixed(2)


            const differenceInMilliseconds = mentorTime - min;
            // Convert milliseconds to minutes
            const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

            const mentorLateBy = differenceInMinutes.toFixed(2)





            Swal.fire({
                icon: "info",
                title: "The Batch details are",
                html: `
                <p>Scheduled Time: ${scheduledDate}</p>
                <p>Start Time: ${meetStartTime}</p>
                <p>End Time: ${meetEndTime}</p>  <!-- Include endTime here -->
                <p>Batch Details: ${event.title}</p>
                <p>Participants: ${meetingAttendees}</p>
                <p>Mentor Start Time : ${mentorJoiningTime}</p>
                <p>Duration (minutes): ${meetTotalTimingInMinutes}</p>
                <p>Mentor is late by : ${mentorLateBy} (minutes) from the scheduled time </p>
                <p>Meeting Code: ${targetMeetingCode}</p>
            `
            });

            setLoading(false);
        }).catch(error => {
            console.error('Error fetching activities:', error);
            setLoading(false);
            Swal.fire({
                icon: "info",
                title: "The Batch details are",
                html: `
                <p>Scheduled Time: ${startingTime.toLocaleString()}</p>
                <p>Start Time: meeting not started yet</p>
                <p>End Time: meeting not started yet</p>
                <p>Batch Details: ${event.title}</p>
                <p>Participants: meeting not started yet</p>
                <p>Meeting Code: ${targetMeetingCode}</p>
                `
            });
    
        });

    };


    const getMentorsList = async () => {
        const responsedata = await ConstructorEventGet(FetchMentorDetails)
        setMentorList(responsedata)

    }


    const selectMentor = async (e) => {
        setSelectedBatches([])
        setBatch([])
        setCalenderEvents([])
        setMentorName(e.target.value)

        const mentorDetails = mentorList.filter((ele) => {
            return ele.user_name === e.target.value
        })



        const mentorid = mentorDetails[0].user_id
        setMentorId(mentorid)

        const responsedata = await ConstructorEventGet(FetchBatchData, { "mentorId": mentorid })
        setBatchList(responsedata)


        batchList.forEach((batch, index) => {
            console.log(`Class Schedule for Batches ${index + 1} (${batch.BatchName}):`, batch.ClassSchedule);
        });


    }




    const handleCheckboxChange = (event) => {

        const value = event.target.value;
        setSelectedBatches((prevSelectedItems) =>
            prevSelectedItems.includes(value)
                ? prevSelectedItems.filter((item) => item !== value)
                : [...prevSelectedItems, value]
        );

    }

    useEffect(() => {
        displayEventsInTheCalender()

    }, [selectedBatches])




    const displayEventsInTheCalender = async () => {
        // Filter events based on batch progress



        const scheduledEventsList = batchList.filter((ele) => ele.batchProgress === "Scheduled");
        const inProgressEventsList = batchList.filter((ele) => ele.batchProgress === "InProgress");
        const completedEventsList = batchList.filter((ele) => ele.batchProgress === "Completed")
        const cancelledEventsList = batchList.filter((ele) => ele.batchProgress === "Cancelled")


        console.log("The calender events are calender events", calenderEvents)


        function colorChangeDuration(elee) {
            setColorChangeInCalender("")
            const meetlink = elee.meet_link
            const meetid = meetlink.split("/").pop()
            const meetingid = meetid.split("-")
            const meetidStingcap = meetingid.join('').toUpperCase();

            if (!gapi.client || !gapi.client.request) {
                console.error('Google API client is not loaded or initialized');
                setLoading(false);
                return;
            }

            gapi.client.request({
                path: 'https://admin.googleapis.com/admin/reports/v1/activity/users/all/applications/meet',
                method: 'GET',
            }).then(response => {
                const eventsData = response.result.items.flatMap(item => item.events.map(event => {

                    response.result.items.forEach((item) => {
                        const time = item.id.time;
                        const events = item.events;
                    });




                    const identifierParam = event.parameters.find(param => param.name === 'identifier');
                    const meetingCodeParam = event.parameters.find(param => param.name === 'meeting_code');
                    const durationParam = event.parameters.find(param => param.name === 'duration_seconds');

                    return {
                        time: item.id.time,
                        identifier: identifierParam ? identifierParam.value : 'na',
                        meeting_code: meetingCodeParam ? meetingCodeParam.value : 'na',
                        duration_seconds: durationParam ? durationParam.intValue : 'na',
                    };
                }));

                const filteredEvents = eventsData.filter(event => event.meeting_code === meetidStingcap);
                
                const mentorEvents = filteredEvents.filter(event => event.identifier === mentorId || event.identifier === mentorName);



                const timeArray = filteredEvents.map(item => new Date(item.time));

                // Find minimum and maximum times
                const min = new Date(Math.min(...timeArray));
                const max = new Date(Math.max(...timeArray));


                const meetingTime = max - min
                const meetInSeconds = meetingTime.toString().slice(0, 4);



                setColorChangeInCalender(meetInSeconds)

            }).catch(error => {
                console.error('Error fetching activities:', error);
                setLoading(false);
            });







        }



        // Create events with extended properties
        const scheduledEvents = scheduledEventsList.flatMap((ele) =>
            ele.ClassSchedule.map((elee) => (

                colorChangeDuration(elee),

                {


                    title: `${ele.BatchName.split(" ")[0]}(${ele.batchProgress})(${ele.StudentsEndrolled.map((stu) => stu.name).join(", ")})`,
                    start: elee.startDateTime,
                    extendedProps: {
                        identifier: 'na', // Placeholder; should be updated with actual data
                        duration_seconds: 'na', // Placeholder; should be updated with actual data
                        meeting_code: `${elee.meet_link}`,
                        duration: `${colorChangeInCalender}`,// Placeholder; should be updated with actual data
                    }
                }))
        );

        const inProgressEvents = inProgressEventsList.flatMap((ele) =>
            ele.ClassSchedule.map((elee) => (

                colorChangeDuration(elee), {
                    title: `${ele.BatchName.split(" ")[0]}(${ele.batchProgress})(${ele.StudentsEndrolled.map((stu) => stu.name).join(", ")})`,
                    start: elee.startDateTime,
                    extendedProps: {
                        identifier: 'na', // Placeholder; should be updated with actual data
                        duration_seconds: 'na', // Placeholder; should be updated with actual data
                        meeting_code: `${elee.meet_link}`,
                        duration: `${colorChangeInCalender}`, // Placeholder; should be updated with actual data
                    }
                }))
        );

        const completedEvents = completedEventsList.flatMap((ele) =>
            ele.ClassSchedule.map((elee) => (
                colorChangeDuration(elee),
                {
                    title: `${ele.BatchName.split(" ")[0]}(${ele.batchProgress})(${ele.StudentsEndrolled.map((stu) => stu.name).join(", ")})`,
                    start: elee.startDateTime,
                    extendedProps: {
                        identifier: 'na', // Placeholder; should be updated with actual data
                        duration_seconds: 'na', // Placeholder; should be updated with actual data
                        meeting_code: `${elee.meet_link}`,
                        duration: `${colorChangeInCalender}`, // Placeholder; should be updated with actual data
                    }
                }))
        );

        const cancelledEvents = cancelledEventsList.flatMap((ele) =>
            ele.ClassSchedule.map((elee) => (
                colorChangeDuration(elee),
                {
                    title: `${ele.BatchName.split(" ")[0]}(${ele.batchProgress})(${ele.StudentsEndrolled.map((stu) => stu.name).join(", ")})`,
                    start: elee.startDateTime,
                    extendedProps: {
                        identifier: 'na', // Placeholder; should be updated with actual data
                        duration_seconds: 'na', // Placeholder; should be updated with actual data
                        meeting_code: `${elee.meet_link}`,
                        duration: `${colorChangeInCalender}`,// Placeholder; should be updated with actual data
                    }
                }))
        );

        // Handle batch selection and update calendar events
        if (selectedBatches.length === 1) {
            handleSingleBatch(selectedBatches[0]);
        } else if (selectedBatches.length === 2) {
            handleTwoBatches(selectedBatches[0], selectedBatches[1]);
        } else if (selectedBatches.length > 2) {
            alert("You can't select more than two batch statuses");
            setSelectedBatches([]);
        } else {
            setCalenderEvents([]);
            setHideCalender(false);
        }

        function handleSingleBatch(value1) {
            if (value1 === "Scheduled") {
                const eventsToDisplayInCalender = [...scheduledEvents]
                setCalenderEvents(eventsToDisplayInCalender)
                const result = batchList.filter((ele) => {
                    return ele.batchProgress === "Scheduled"
                })

                if (result.length === 0) {
                    Swal.fire({
                        icon: "info",
                        title: `There are no Scheduled batches.`,
                    })
                    setSelectedBatches([])
                } else {
                    setHideCalender(true)
                    setBatch(result)
                }

            } else if (value1 === "InProgress") {
                const eventsToDisplayInCalender = [...inProgressEvents]
                setCalenderEvents(eventsToDisplayInCalender)
                const result = batchList.filter((ele) => {
                    return ele.batchProgress === "InProgress"
                })

                if (result.length === 0) {
                    Swal.fire({
                        icon: "info",
                        title: `There are no InProgress batches.`,
                    })
                    setSelectedBatches([])
                } else {
                    setHideCalender(true)
                    setBatch(result)
                }
            } else if (value1 === "Cancelled") {
                setHideCalender(true)
                const eventsToDisplayInCalender = [...cancelledEvents]
                setCalenderEvents(eventsToDisplayInCalender)

                const result = batchList.filter((ele) => {
                    return ele.batchProgress === "Cancelled"
                })

                if (result.length === 0) {
                    Swal.fire({
                        icon: "info",
                        title: `There are no cancelled batches.`,
                    })
                    setSelectedBatches([])
                } else {
                    setBatch(result)
                }


            } else if (value1 === "Completed") {
                setHideCalender(true)
                const eventsToDisplayInCalender = [...completedEvents]
                setCalenderEvents(eventsToDisplayInCalender)
                const result = batchList.filter((ele) => {
                    return ele.batchProgress === "Completed"
                })

                if (result.length === 0) {
                    Swal.fire({
                        icon: "info",
                        title: `There are no completed batches.`,
                    })
                    setSelectedBatches([])
                } else {
                    setBatch(result)
                }

            }

        }




        function containsTwoValue(value1, value2) {
            if (value1 === "Scheduled" && value2 === "InProgress" || value1 === "InProgress" && value2 === "Scheduled") {
                const eventsToDisplayInCalender = [...scheduledEvents, ...inProgressEvents]
                setCalenderEvents(eventsToDisplayInCalender)
                setHideCalender(true)


                const value1 = batchList.filter((ele) => {
                    return ele.batchProgress === "Scheduled"
                })

                const value2 = batchList.filter((ele) => {
                    return ele.batchProgress === "InProgress"
                })


                setBatch([...value1, ...value2])

            } else if (value1 === "Cancelled" && value2 === "Completed" || value1 === "Completed" && value2 === "Cancelled") {
                setHideCalender(true)
                const value1 = batchList.filter((ele) => {
                    return ele.batchProgress === "Cancelled"
                })

                const value2 = batchList.filter((ele) => {
                    return ele.batchProgress === "Completed"
                })


                setBatch([...value1, ...value2])
            }
            else {
                alert(`You can't select ${value1} with ${value2}`)
                setSelectedBatches([])
            }
        }

        function handleTwoBatches(value1, value2) {
            if ((value1 === "Scheduled" && value2 === "InProgress") || (value1 === "InProgress" && value2 === "Scheduled")) {
                setCalenderEvents([...scheduledEvents, ...inProgressEvents]);
            } else if ((value1 === "Cancelled" && value2 === "Completed") || (value1 === "Completed" && value2 === "Cancelled")) {
                setHideCalender(true);
                setCalenderEvents([...cancelledEvents, ...completedEvents]);
                const cancelledBatches = batchList.filter((ele) => ele.batchProgress === "Cancelled");
                const completedBatches = batchList.filter((ele) => ele.batchProgress === "Completed");
                setBatch([...cancelledBatches, ...completedBatches]);
            } else {
                alert(`You can't select ${value1} with ${value2}`);
                setSelectedBatches([]);
            }
        }
    }

    function goToAssignBatchToMentorPage() {
        navigate(mentorId ? "/codomo/admin/assignBatch" : "", mentorId ? { state: { mentorId, meetLink } } : "");
    }


    const handleEventClick = (info) => {

        console.log("the calender events are", calenderEvents)


        const { event } = info;
        const stime = new Date(info.event.start)
        const meetlink = info.event._def.extendedProps.meeting_code
        const meetid = meetlink.split("/").pop()
        const meetingid = meetid.split("-")
        const meetidString = meetingid.join('');
        const meetidStingcap = meetingid.join('').toUpperCase(); // Joins without dashes and converts to uppercase: 'MIEQYBQDZE'
        setMeetId(meetidStingcap)
        fetchActivities(meetidStingcap, stime, event);


    };


    return (
        <div>
            <Header />
            {loading ? (
                <div className="spinner">
                    <div className="spinner-wrapper">
                        <ClassicSpinner size={50} color="black" loading={loading} />
                    </div>
                </div>
            ) : (
                <div className="nav-tab-wrapper tabs section-padding">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p className="p-6">
                            Mentor Name:
                            <select onChange={selectMentor}>
                                <option value="">Select a mentor</option>
                                {mentorList?.map((ele) => (
                                    <option key={ele.id}>{ele.user_name}</option>
                                ))}
                            </select>
                        </p>
                        {mentorId && (
                            <div style={{ display: "flex" }}>
                                <p className="pt-6 pl-6">Batch Status:</p>
                                <div className="pt-6 pl-4" style={{ display: "flex" }}>
                                    {batchStatusList?.map((item) => (
                                        <div key={item}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value={item}
                                                    checked={selectedBatches.includes(item)}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <b>{item}</b>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="pt-4 pl-6 pr-4 space-x-[18px]">
                            <button
                                className="btn btn-primary py-[15px] px-8"
                                onClick={goToAssignBatchToMentorPage}
                            >
                                Assign Batch To Mentor
                            </button>
                        </div>
                    </div>
                    {hideCalender ?
                        <div>
                            <div className="grid lg:grid-cols-4 md:grid-cols-2  grid-cols-1 gap-[30px] p-6">
                                {batch.map((item, index) => (

                                    <div
                                        className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center hover:-translate-y-2 pt-4"
                                        key={index}
                                    >


                                        <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8] ">
                                            <iconify-icon icon={`${item.batchProgress === 'Completed' ? 'fluent-mdl2:completed-solid' : item.batchProgress === 'InProgress' ? 'grommet-icons:in-progress' : item.batchProgress === 'Scheduled' ? 'mdi:clipboard-text-date' : 'mdi:clipboard-text-date'}`} style={{ fontSize: '2em' }} />

                                        </div>
                                        <div className="course-content pb-6">

                                            <b>Batch Status : {item.batchProgress}</b>
                                            <br></br>
                                            <b className="font-bold">Course Name: {item.courseName}</b>
                                            <br></br>
                                            <b>BatchStudents: {item.StudentsEndrolled.map((ele) => (
                                                <li>{ele.name}</li>
                                            ))}
                                            </b>
                                            <b>Batch Id: {item.BatchName.split("-").pop()}</b>
                                            <br></br>
                                            <b>Date: {item.StartDate.replaceAll("-", "/") + "  -  " + item.EndDate.replaceAll("-", "/")}</b>
                                            <br></br>
                                            <b>Timing: {item.StartTime + "  -  " + item.EndTime}</b>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                            <div className="container">
                                {calenderEvents && (
                                    <FullCalendar
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        initialView="timeGridWeek"
                                        headerToolbar={{
                                            center: "dayGridMonth,timeGridWeek",
                                        }}
                                        weekends={true}
                                        selectable={true}
                                        dayMaxEvents={0}
                                        //eventBackgroundColor={}
                                        events={calenderEvents.map(event => {

                                            console.log("tttt", event.extendedProps.duration)
                                            let backgroundColor;
                                            if (event.extendedProps.duration === 0) {
                                                backgroundColor = 'red';
                                            } else if (event.extendedProps.duration < 3600) {
                                                backgroundColor = 'yellow';
                                            } else if (event.extendedProps.duration >= 3600) {
                                                backgroundColor = 'green';
                                            } else if (mentorExists === true && studentExists === false) {
                                                backgroundColor = 'blue';
                                            } else if (mentorExists === false && studentExists === true) {
                                                backgroundColor = 'pink';
                                            }

                                            return {
                                                ...event,
                                                backgroundColor: backgroundColor,
                                                textColor: "black"
                                            };
                                        })}
                                        eventClick={handleEventClick}
                                    />
                                )}
                            </div>


                        </div> : <div className="xl:col-span-7 lg:col-span-6 col-span-12">
                            <div className="container">
                                {calenderEvents && (
                                    <FullCalendar
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        initialView="timeGridWeek"
                                        headerToolbar={{
                                            center: "dayGridMonth,timeGridWeek",
                                        }}
                                        weekends={true}
                                        selectable={true}
                                        dayMaxEvents={0}
                                        //eventBackgroundColor={}
                                        events={calenderEvents.map(event => {

                                            // console.log("duration", duration)

                                            let backgroundColor;
                                            if (duration === 0) {
                                                backgroundColor = 'red';
                                            } else if (duration < 3600) {
                                                backgroundColor = 'yellow';
                                            } else if (duration >= 3600) {
                                                backgroundColor = 'green';
                                            } else if (mentorExists === true && studentExists === false) {
                                                backgroundColor = 'blue';
                                            } else if (mentorExists === false && studentExists === true) {
                                                backgroundColor = 'pink';
                                            }

                                            return {
                                                ...event,
                                                backgroundColor: backgroundColor,
                                                textColor: "black"
                                            };
                                        })}
                                        eventClick={handleEventClick}
                                    />
                                )}
                            </div>


                        </div>
                    }

                </div>
            )}

        </div>
    );
}

export default BatchManagement;


