import React, { useState } from 'react';
import "../../assets/css/newacitivity.css"

function Dropdown() {
  // Define the options for the dropdown
  const options = [
    { value: '00', label: 'are there in antarctica' },
    { value: '8', label: 'Have Won The FIFA World Cup' },
    { value: '195', label: ' in the world' },
    { value: '9', label: 'begin with letter i' },
    { value: '4', label: 'have satellite on moon' },
  ];

  // Initialize state to store the selected value
  const [selectedLanguage, setSelectedLanguage] = useState('');

  // Event handler for dropdown change
  const handleDropdownChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  return (
    <div className="container">
      <div className='grid grid-cols-12'>
        <div className="lg:col-span-3 col-span-12 ">
          <div className="design-vew22" >
            <div className=' '></div>
            <div className="dropdown-container">
              <h4>How Many Countries</h4>
              <div>
                <label htmlFor="languageDropdown">Select </label>
                <select
                  id="languageDropdown"
                  value={selectedLanguage}
                  onChange={handleDropdownChange}
                >
                  <option value="">Select an option</option>
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className='small-box'>
                {selectedLanguage && (
                  <p> {selectedLanguage}</p>
                )}
              </div>
            </div>
          </div>
        </div>
       
          <div class="lg:col-span-9 col-span-12">
          <div className='whiteboard'>
            <div class="headerwhitebox"></div>
            <h4 class="rightheading">How many Countries  </h4>
            <div class="rightpaneul">
              <li>This app tells you some fun facts about different countries.Ask it a question and it will tell you how many countries meet that requirement</li>
              <ul >
                <h4>Input:</h4>
                <li>1. The question that the user chose(user mouse click)</li>
                <li>2. List of countries and factsabout them(internet or stored in app)</li>
                <hr></hr>
                <h4>Output</h4>
                <li>1. The number of countries that meet the requirement</li>
                <hr></hr>
                <h4>Processing</h4>
                <li>What types of processes are needed for this app to work?</li>


              </ul>

            </div>

          </div>


        </div>
      </div>
    </div>

  );
}

export default Dropdown;
