import React, { useState,useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom"
import Header from "../Header";
import { Table } from "antd";
import {UpdateCourseDetails} from "../../api/aws-api";
import { ConstructorEventPost } from "../../Event/event";
import { ClassicSpinner } from "react-spinners-kit";
import { notifyError, notifySuccess } from "../../Common/CommonFunction";

const CourseMappingManagement = () => {
    const [loading,setLoading] = useState(false)
    const [ActivityEditMenu,setActivityEditMenu] = useState(false)
    const [SaveToCloud,setSaveToCloud] = useState(false)
    const [data,setData] = useState()

    const [DisplayData,setDisplayData] = useState([])
    const [ModuleMapper,setModuleMapper] = useState("")
    const [ModuleOrder,setModuleOrder] = useState("")
    
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(()=>{
        const {data} = location.state ? location.state : []
        if (data){
            setData(data);
            if (data["module_mapper"]){
                const outputArray = Object.entries(data["module_mapper"]).map(([mapperKey, order]) => {return { "mapperKey" : mapperKey,"Order" : order };});
                setDisplayData(outputArray ? outputArray : [])}
            }else{setDisplayData([])}
            
      },[])
   
    const EraseDataFromState = () => {
      setModuleMapper("")
      setModuleOrder("")
    }
    const setDataToEditTab = (data) => {
        setModuleMapper(data.mapperKey)
        setModuleOrder(data.Order)
      }
    const columns = [
        {
          title: "Mapping Key",
          dataIndex: "mapperKey",
          sorter: (a, b) => a.mapperKey.length - b.mapperKey.length,
          render: (text, record) => <Link onClick={()=> (setDataToEditTab(record),setActivityEditMenu(true))}>{text}</Link>
        },
        {
          title: "Mapping Order",
          dataIndex: "Order",
        },
        {
          title: " ",
          dataIndex: "mapperKey",
          render: (text) => <div >
            <iconify-icon icon="line-md:remove" style={{ color:"red" ,fontSize: '1.8em', marginLeft: '20px' }} onClick={()=>RemoveItemFromdata(text)}/>
            </div>
        }
        
      ];
       
      const UpdateDataInState = () => {
        const temp = { "mapperKey" : ModuleMapper,"Order" : ModuleOrder }
        const Repetation = DisplayData.filter((item)=> item.mapperKey !== ModuleMapper)
        setDisplayData([...Repetation,temp])
        setActivityEditMenu(false)
        setSaveToCloud(true)
      }
  
      const RemoveItemFromdata = (RemoveMapperKey) => {
        const Repetation = DisplayData.filter((item)=> item.mapperKey !== RemoveMapperKey)
        setDisplayData(Repetation)
        setSaveToCloud(true)
      }
         
       
      const UpdateUserDataToCloud = async() => {
        setLoading(true)
        let temp = data
        temp["module_mapper"] = ChangeDataFormat()
        const responsedata = await ConstructorEventPost(UpdateCourseDetails,temp)
        if (responsedata === "course_updated"){notifySuccess("Activity Updated");navigate("/codomo/admin/coursemanagement")}
        else {notifyError("Something Gone Wrong")}
        setLoading(false)
      }


      const ChangeDataFormat = () => {
        let temp = {}
        for (let individualData of DisplayData){
            temp[individualData["mapperKey"]] = individualData["Order"]
        }
        return temp
      }
       
    return (
        <>
          <Header/>
          {loading ? 
          <div className="spinner" >
            <div className="spinner-wrapper">
              <ClassicSpinner size={50} color ="black" loading={loading} />
              </div></div> :
                <div className="nav-tab-wrapper tabs  section-padding">
                <div className="container">
                  <div className=" grid grid-cols-12 gap-[30px]">
                    <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
                      <div className="mini-title">Module Mapping Management
                      {SaveToCloud ? <div className="small-btn-saveDataToCloud ml-4" onClick={UpdateUserDataToCloud}>
                        <iconify-icon icon="tabler:cloud-up" style={{fontSize: '1em'}} />
                          Save Changes To Cloud
                        </div> : "" }</div>
                      <div className="small-btn mt-4" onClick={()=>(setActivityEditMenu(true),EraseDataFromState())}>
                        <iconify-icon icon="typcn:plus" style={{fontSize: '1em'}}></iconify-icon> {" "}
                          Add Module Mapping
                        </div>
                        
                          {ActivityEditMenu ? 
                          <div className="bg-white shadow-box7 p-8 rounded-md mt-8">
                            <div className="input-container mt-8">
                              <input
                                type="text"
                                className="from-control"
                                value={ModuleMapper}
                                onChange={(e) => setModuleMapper(e.target.value)}
                                required
                              />
                              <span className={`input-placeholder ${ModuleMapper && 'active'}`}>
                              Module Mapper *
                              </span>
                            </div>
                            <div className="input-container mt-8">
                              <input
                                type="email"
                                className=" from-control"
                                value={ModuleOrder}
                                onChange={(e) => setModuleOrder(e.target.value)}
                                required
                              />
                              <span className={`input-placeholder ${ModuleOrder && 'active'}`}>
                              Order *
                              </span>
                            </div>
                            <button className="btn btn-black mt-4 mr-4" onClick={UpdateDataInState}>Save</button>
                          </div> 
                        : ""}
                        
                        
                    </div>
                    <div className="xl:col-span-7 lg:col-span-6 col-span-12">
                      <div className="bg-white shadow-box7 p-8 rounded-md">
                      <div className="from-control mb-4">Total Activity : {DisplayData && DisplayData.length}</div>
                        <Table    
                            className=""      
                            columns={columns}
                            dataSource={DisplayData ? DisplayData: []}      
                            pagination={DisplayData && DisplayData.length > 9 ?true : false}
                            rowKey={(record) => record.mapperKey}
                        />
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>}

        </>
    )

}
export default CourseMappingManagement;