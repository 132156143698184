import React,{useEffect,useState,useContext} from 'react';
import {useNavigate} from 'react-router-dom'
import { useGoogleLogin} from '@react-oauth/google';
import SignInForm from "./SignInForm";
import { ConstructorEventPost,ConstructorEventGetNoParams } from '../../Event/event';
import { CheckUserDetailsForSignIn } from '../../api/aws-api';
import { toast } from 'react-toastify';


function SignIn(props) {
  const navigate = useNavigate()
  const [showOtpColoumn,setShowOtpColoumn] = useState(false)
  const [proceedLoading,setProceedLoading] = useState(false)
  const [usedGoogle,setUsedGoogle] = useState(false)


  const notify = (message) => toast.error(message, {
    className: 'my-custom-toast',
    position: "top-left",
    autoClose: 10000,
    hideProgressBar: false,
    pauseOnHover: true,
    progress: undefined,
    theme: "light",
    });;
  const login = useGoogleLogin({
      onSuccess: (codeResponse) => fetchdatafromgoogleapis(codeResponse),
      onError: (error) => console.log('Login Failed:', error)
  });
  
  useEffect(() => {
      document.documentElement.scrollTop = 0;
  },[]);

  const  fetchdatafromgoogleapis =  async(user) =>{
    setUsedGoogle(true)
    const responsedata = await ConstructorEventGetNoParams(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
      headers: {Authorization: `Bearer ${user.access_token}`,Accept: 'application/json'}})
      FetchData({emailOrPhone : responsedata.email,googleId :responsedata.id})
  }
  
  
  const FetchData = async (data) => {
    setProceedLoading(true)
    const responsedata = await ConstructorEventPost(CheckUserDetailsForSignIn,data)
    if (responsedata === "user-verified"){setShowOtpColoumn(responsedata)}
    else if (responsedata === "otp-verification-failed"){notify("Otp Verification Failed")}
    else if (responsedata === "user-not-active"){notify("User is not Active")}
    else if (responsedata === "user-verification-failed"){notify("Sorry, it looks like we can't find your account! Click 'let's get started' to join our platform.");navigate('/signUp')}
    else if (responsedata === "otp-expired"){notify("Otp is Expired")}
    else if (responsedata[0] === "email-verification-pending"){
      notify("Please Verify Your Mail")
      localStorage.setItem('UserKey', JSON.stringify(responsedata[1]));navigate('/confirmemail')}
    else if (responsedata[0] === "otp-verified"){
      localStorage.setItem('authToken',  responsedata[2]);localStorage.setItem('lastLoginTime', Date.now());navigate("/codomo/home")
      localStorage.setItem('UserKey', JSON.stringify(responsedata[1])) }
    else if (responsedata[0] === "googleId-verified"){
      localStorage.setItem('authToken',  responsedata[2]);localStorage.setItem('lastLoginTime', Date.now());navigate("/codomo/home")
      localStorage.setItem('UserKey', JSON.stringify(responsedata[1]))}

    setProceedLoading(false)
    setUsedGoogle(false)
  }
  return (
    <>
      <SignInForm GoogleLoginFunction={login} FetchData={FetchData} 
      showOtpColoumn={showOtpColoumn} proceedLoading={proceedLoading}
      setShowOtpColoumn = {setShowOtpColoumn} usedGoogle = {usedGoogle}
      />
    </>
  );
}

export default SignIn;

