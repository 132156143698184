import React, { useState } from 'react';
// import $ from 'jquery';
import "../../assets/css/newacitivity.css"

function NewCourseActvitythree() {
  const [questionNumber, setQuestionNumber] = useState(1);
  const [words, setWords] = useState({})
  const [Activitystarted, setActivitystarted] = useState(false)
  var btn = ('#top');
  
  var nameY= "Missile";
  var colourY= "2002-2007";
  var matchcolourY = colourY.split(/[,\s_.-]+/);
  var regexcolourY = new RegExp(`${matchcolourY[0]}\\W+${matchcolourY[1]}`);
  var foodY = "Aerospace";
  var matchfoodY = foodY.split(/[,\s_.-]+/);
  var regexfoodY = new RegExp(`${matchfoodY[0]}\\W+${matchfoodY[1]}`);
  var emotionY = "Wings Of Fire";
  var matchemotionY = emotionY.split(/[,\s_.-]+/);
  var regexemotionY = new RegExp(`${matchemotionY[0]}\\W+${matchemotionY[1]}\\W+${matchemotionY[2]}`);
  var cityY = "Rameswaram";

  const Questions = {
    "3": "",
    "4": "",
  };
  if (words["colour"]?.match(nameY) !== null && words["name"]?.match(regexcolourY) !== null && words["city"]?.match(cityY) !== null && words["foodY"]?.match(regexfoodY) !== null && words["emotionY"]?.match(regexemotionY) !== null ) {
    Questions["3"] = `Dr.APJ Abdul Kalam,widely regarded as People's President(2002-2007).He was also known as MissileMan and an expert in aerospace.His inspirational book 'Wings Of Fire' ignite dreams and ambitions,inspiring generations to strive for excellence.`; 
  } else {
    Questions["3"] = `Sorry your answer is wrong`;
  }
  if (words["colour"]?.match(nameY) !== null && words["name"]?.match(regexcolourY) !== null && words["city"]?.match(cityY) !== null && words["foodY"]?.match(regexfoodY) !== null && words["emotionY"]?.match(regexemotionY) !== null ) {
    Questions["4"] = `Dr.APJ Abdul Kalam,renowned as the "MissileMan" contributed significantly to India's missile programs, including Pokhran-II.His expertise in aerospace engineering during his presidency marked him as a visionary for technological growth in India.`;
  } else {
    Questions["4"] = `Sorry your answer is wrong`;
  }

  
  function navigateToPage() {

    window.location.href = 'http://localhost:3000/';
  }


  function allowDrop(event) {
    event.preventDefault();
  }

  function drag(event) {
    event.dataTransfer.setData("text/plain", event.target.outerHTML);
  }


  function drop(event) {
    event.preventDefault();
    var data = event.dataTransfer.getData("text/plain");
    var draggedItem = new DOMParser().parseFromString(data, "text/html").body.firstChild;
    event.target.appendChild(draggedItem);
  }

  const setResultToState = (AttributeName, value) => {
    let temp = words
    words[AttributeName] = value
    setWords(temp)
  }

  return (
    <div className='Newbody' style={{ overflow: 'hidden' }}>
      {/* <div className='Newheader'> */}
        <div className="container" >
          <div className='grid grid-cols-12'>
            <div className="lg:col-span-3 col-span-12 ">
              <div className={questionNumber < 5 ? "design-vew22" : "design-vew2"}  >
                {/* <div className=' mt-4'> */}
                  <div className='Acitivityone'>
                    <div>

                      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap" />
                      <link rel="stylesheet" href="styles.css" />
                      {questionNumber == 5 ? <h3 className='sucesstext'>Sucessfully Completed....!!</h3>

                        : questionNumber == 1 ?
                          <div className='arcade-text'>
                            <h3>"Let's play "</h3>
                          </div>
                          : questionNumber == 2 ?
                            <div className='fontr container'>

                              <label >
                              Presidential tenure
                              </label>
                              <input type="text" className="form-control " placeholder="Hint: ____-2007" onChange={(e) => setResultToState("name", e.target.value)} />

                              <label >
                              Moniker
                              </label>
                              <input type="text" className="form-control" placeholder="Hint: ____Man" onChange={(e) => setResultToState("colour", e.target.value)} />


                              <label >
                              Field of expertise
                              </label>
                              <input type="text" className="form-control" placeholder="Hint: Aero____" onChange={(e) => setResultToState("food", e.target.value)} />
                              <label >
                              Famous book
                              </label>
                              <input type="text" className="form-control" placeholder="Hint: Wings____" onChange={(e) => setResultToState("emotion", e.target.value)} />

                              <label >
                              Birthplace
                              </label>
                              <input type="text" className="form-control" placeholder="Hint: Rames____" onChange={(e) => setResultToState("city", e.target.value)} />
                            </div>

                            : ""}

                      <div id='content'>
                        {questionNumber == 1 ?
                          <>
                            <button class="button-86 mr-2 p-3 w175 mt-8 button-gap-t" role="button" onClick={() => Activitystarted && setQuestionNumber(questionNumber + 1)}>Press Here </button>

                          </>
                          :
                          questionNumber == 2 ?
                            <>
                              <button className="button-81 mr-2 p-3 w175 mt-8 button-gap" onClick={() => setQuestionNumber(questionNumber + 1)}>Inspiration</button>
                              <button className="button-81 mr-2 p-3 w175 mt-8 button-gap" onClick={() => setQuestionNumber(questionNumber + 2)}>Scientist</button>

                            </>

                            :
                            questionNumber == 3 ?
                              <>
                                <div class="arcade-text">{Questions[3]}</div>
                              </>
                              :
                              questionNumber == 4 ?
                                <>
                                  <div class="arcade-text2">{Questions[4]}</div>
                                  {/* <iframe className='"button-gap-2' src="https://giphy.com/embed/UqdSupDxiAdXsmHiW5" width="210" height="200" frameBorder="0" class="giphy-embed" allowFullScreen></iframe> */}
                                </>


                                : ""}
                      </div>
                    </div>
                 </div>
                  
                
              </div>
              <button className="button-12 " style={{ textAlign: "center" }} onClick={() => Activitystarted ? (setQuestionNumber(1), setActivitystarted(false)) : setActivitystarted(true)}>{Activitystarted ? "RESET" : "RUN"}</button>
          <button className="button-12 " style={{ textAlign: "center" }} onClick={() => setQuestionNumber(5)}>FINISH</button>
            </div>
            <div className='whiteboard'>
              <div class="lg:col-span-9 col-span-12">
                <div class="headerwhitebox">Instructions</div>
                <h4 class="rightheading">Questionare</h4>
                <div class="rightpaneul">
                A Short Quiz: Pathfinder of Progress- Dr. APJ Abdul Kalam Tribute Quiz
                  <ul >
                    <li>What are the Inputs here?</li>
                    <li>Your answer is input, please see the hint and you may also take help from your parents and mentor</li>
                    <li>What are the Outputs?</li>
                  </ul>
                </div>
              </div>
              
            </div>
            
          </div>
        </div>

      

      </div >
  );
}

export default NewCourseActvitythree;