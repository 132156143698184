import React, { useState, useEffect } from 'react';
import "../../assets/css/newacitivity.css"
import { useLocation } from 'react-router-dom';

function AcitvityCourse() {
    const json = {


        "FinishScreen": 4,
        "Question": "Try Out this quiz about national park ./nWhat are the Inputs?/nWhat are the Outputs?",
        "Screens": {
            "1": {
                "Button": [
                    {
                        "ButtonName": "START",
                        "ButtonNavigation": 2
                    }
                ],
                "Header": "Nationel Park Quiz ?"
            },
            "2": {
                "Button": [
                    {
                        "ButtonName": "Jim Corbett National Park",
                        "ButtonNavigation": 5
                    },
                    {
                        "ButtonName": "Northeast Greenland ",
                        "ButtonNavigation": 6
                    }
                ],
                "Header": "which is the largest nationel park in the world ?"
            },
            "3": {
                "Button": [
                    {
                        "ButtonName": "South Africa",
                        "ButtonNavigation": 7
                    },
                    {
                        "ButtonName": "Australia ",
                        "ButtonNavigation": 8
                    }
                ],
                "Header": "which country has the most national parks ?"
            },
            "4": {
                "Button": [
                    {
                        "ButtonName": 106,
                        "ButtonNavigation": 9
                    },
                    {
                        "ButtonName": 142,
                        "ButtonNavigation": 10
                    }
                ],
                "Header": "How many Nationel Parks are there in India ?"
            },
            "5": {
                "Button": [
                    {
                        "ButtonName": "NEXT",
                        "ButtonNavigation": 3
                    }
                ],
                "Header": "Wrong Answer!! Northeast Greenland National Park is the world's largest national park and the 10th largest protected area "
            },
            "6": {
                "Button": [
                    {
                        "ButtonName": "NEXT",
                        "ButtonNavigation": 3
                    }
                ],
                "Header": "Good Job!! Northeast Greenland National Park is the world's largest national park and the 10th largest protected area "
            },
            "7": {
                "Button": [
                    {
                        "ButtonName": "NEXT",
                        "ButtonNavigation": 4
                    }
                ],
                "Header": "Wrong Answer!! Australia leads the list of countries with the most national parks by an incredible distance, home to over 600 national parks. "
            },
            "8": {
                "Button": [
                    {
                        "ButtonName": "NEXT",
                        "ButtonNavigation": 4
                    }
                ],
                "Header": "Good Job!! Australia leads the list of countries with the most national parks by an incredible distance, home to over 600 national parks. "
            },
            "9": {
                "Button": [
                    {
                        "ButtonName": "NEXT",
                        "ButtonNavigation": 11
                    }
                ],
                "Header": "Good Job!! National Parks in India: There are 106 National Parks in India covering an area of 44,378 sq.km "
            },
            "10": {
                "Button": [
                    {
                        "ButtonName": "NEXT",
                        "ButtonNavigation": 11
                    }
                ],
                "Header": "Wrong Answer!! National Parks in India: There are 106 National Parks in India covering an area of 44,378 sq.km "
            },
            "11": {
                "Header": "Successfully Completed",
                "iframe": "https://giphy.com/embed/l2Sqir5ZxfoS27EvS"
            }
        },
        "TotalScreens": 5
    }



    const [ScreenNumber, setScreenNumber] = useState(1);
    const [Activitystarted, setActivitystarted] = useState(false);
    const [ActivityData, setActivityData] = useState(json)
    const location = useLocation();

    // useEffect(()=>{
    //     const {resource} = location.state ? location.state : {}
    //     if (resource){setActivityData(resource["ActivityData"])}
    //     console.log(resource)
    //   },[])

    const images = {
        "HomeActivity": require('../../assets/images/NewActivityImages/homeactivity.jpg'),
        "Dog": require('../../assets/images/NewActivityImages/dog2.jpg'),
        "Cat": require("../../assets/images/NewActivityImages/cat.jpg"),
        "FootBall": require("../../assets/images/NewActivityImages/football7.jpg")
        // and so on
    };

    return (
        <div className="container" style={{ overflow: 'hidden' }}>
            <div className='grid grid-cols-12'>
                <div className="lg:col-span-3 col-span-12 ">
                    <div class="design-vew22 ">
                        <div className="fontq mt-4">
                            <div className="activityone">
                                {/* header */}
                                {ActivityData && ActivityData["Screens"] && ActivityData["Screens"][ScreenNumber] && ActivityData["Screens"][ScreenNumber]["Header"]}
                                {ActivityData && ActivityData["Screens"] && ActivityData["Screens"][ScreenNumber] && ActivityData["Screens"][ScreenNumber]["image"] ? <img src={images[ActivityData["Screens"][ScreenNumber]["image"]]} /> : ""}
                                {ActivityData && ActivityData["Screens"] && ActivityData["Screens"][ScreenNumber] && ActivityData["Screens"][ScreenNumber]["link"] ? <a href={ActivityData["Screens"][ScreenNumber]["link"]["LinkUrl"]} className="button-86 me-1">{ActivityData["Screens"][ScreenNumber]["link"]["LinkButtonName"]}</a> : ""}

                            </div>
                            <div id='content'>
                                {ActivityData && ActivityData["Screens"] && ActivityData["Screens"][ScreenNumber] && ActivityData["Screens"][ScreenNumber]["Button"] && ActivityData["Screens"][ScreenNumber]["Button"].map((item, index) => (
                                    <button key={index} className="button-86 md--10 p-3 w175 mt-8 button-gap" onClick={() => Activitystarted && setScreenNumber(item["ButtonNavigation"])}>{item["ButtonName"]}</button>
                                ))
                                }
                                {ActivityData && ActivityData["Screens"] && ActivityData["Screens"][ScreenNumber] && ActivityData["Screens"][ScreenNumber]["iframe"] &&
                                    <iframe src={ActivityData["Screens"][ScreenNumber]["iframe"]} width="220" height="480" class="giphy-embed" allowFullScreen />
                                }
                            </div>
                        </div>
                    </div>
                    <div class="nav-controls justify-content-between align-items-center">
                        <button className="runbttn mr-3 " style={{ textAlign: "center" }} onClick={() => Activitystarted ? (setScreenNumber(1), setActivitystarted(false)) : setActivitystarted(true)}>{Activitystarted ? "RESET" : "RUN"}</button>
                    </div>
                    <div class="nav-controls2 d-flex justify-content-between align-items-center">
                        <button className="runbttn mr-3 " style={{ textAlign: "center" }} onClick={() => Activitystarted && ActivityData && ActivityData["FinishScreen"] && setScreenNumber(ActivityData["FinishScreen"])} >FINISH</button>
                    </div>
                </div>
                <div class="lg:col-span-9 col-span-12">
                    <div class="whitespace">
                        <div class="headerwhitebox">Instructions</div>
                        <h4 class="rightheading">Questionare</h4>
                        <div class="rightpaneul">
                            Try Out this App that will give you suggestions on how to spend your freetime.
                            <ul >
                                <li>What are the Inputs?</li>
                                <li>What are the Outputs?</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
                            }

    export default AcitvityCourse; 