import { React, useState, useEffect, useRef } from "react";
import Header from "../Header";
import { FetchMentorDetails } from "../../api/aws-api";
import { ConstructorEventGet } from "../../Event/event";
import { ClassicSpinner } from "react-spinners-kit";
import { useLocation } from 'react-router-dom';
import { FetchBatchData, AssignBatchToMentor } from "../../api/aws-api";
import { ConstructorEventPost } from "../../Event/event";
import Swal from 'sweetalert2'



const AssignBatchToTheMentor = () => {


    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const [mentorList, setMentorList] = useState([])
    const [selectedMentorName, setSelectedMentorName] = useState("")
    const [selectedMentorId, setSelectedMentorId] = useState("")
    const [defaultMentorName, setDefaultMentorName] = useState("")
    const [defaultMentorId, setDefaultMentorId] = useState("")
    const [batchList, setBatchList] = useState([])
    const [selectedBatch, setSelectedBatch] = useState([])
    const [selectedBatchName, setSelectedBatchName] = useState("")
    const checkboxesRef = useRef([]);
    const radiosRef = useRef([]);


    useEffect(() => {
        fetchMentorDetails()
        getBatchDetailsOfTheMentor()
    }, [])

    const fetchMentorDetails = async () => {
        setLoading(true)
        const responsedata = await ConstructorEventGet(FetchMentorDetails)

        const mentorid = location.state || {};

        const mentorList = responsedata.filter((ele) => {
            return ele.user_id !== mentorid.mentorId
        })

        const defaultMentor = responsedata.filter((ele) => {
            return ele.user_id === mentorid.mentorId
        })


        setDefaultMentorName(defaultMentor[0].user_name)
        setDefaultMentorId(defaultMentor[0].user_id)

        setMentorList(mentorList)
        setLoading(false)


    }


    const getBatchDetailsOfTheMentor = async () => {

        const mentorid = location.state || {};

        const responsedata = await ConstructorEventGet(FetchBatchData, { "mentorId": mentorid.mentorId })

        setBatchList(responsedata)
    }

    const selectMentor = (ele) => {
        setSelectedMentorName(ele.user_name)
        setSelectedMentorId(ele.user_id)

    }

    console.log("hhhhhhh", defaultMentorId)

    const selectBatch = (ele, course_name, event) => {

        const { value, checked } = event.target;

        if (selectedMentorId === "") {
            Swal.fire({
                icon: "warning",
                title: "Please select mentor before selecting batches",
                // footer: '<a href="#">Why do I have this issue?</a>'
            });
        } else {
            if (checked) {
                // If checkbox is checked, add the value to the list
                console.log("hhhhhhhhh", ele)
                ele.mentorName = selectedMentorName
                ele.mentorId = selectedMentorId
                setSelectedBatch([...selectedBatch, ele]);
            } else {
                // If checkbox is unchecked, remove the value from the list
                setSelectedBatch(selectedBatch.filter(item => item.BatchName !== course_name));
            }

        }
    }



    const assignBatch = async () => {
        console.log("the selected batch is", selectedBatch[0], defaultMentorId)

        setLoading(true)

        try {
            for (let i = 0; i < selectedBatch.length; i++) {
                await ConstructorEventPost(AssignBatchToMentor, { "batchName": selectedBatch[i].BatchName, "mentor_id": defaultMentorId, "newBatchData": selectedBatch[i] })
            }
            setSelectedMentorId("")
            setSelectedMentorName("")
            setSelectedBatch("")
            setLoading(false)
            Swal.fire({
                icon: "success",
                title: "Batches Assigned Successfully",
                // footer: '<a href="#">Why do I have this issue?</a>'
            });
            getBatchDetailsOfTheMentor()
        } catch (e) {
            Swal.fire({
                icon: "error",
                title: `Error While Assigning Batch ${e}`,
                // footer: '<a href="#">Why do I have this issue?</a>'
            })
        }



    }


    return (
        <>
            <Header />
            {loading ?
                <div className="spinner" >
                    <div className="spinner-wrapper">
                        <ClassicSpinner size={50} color="black" loading={loading} />
                    </div></div> :
                <div className="nav-tab-wrapper tabs  section-padding">
                    <div className="container">
                        <div className="mini-title">Assign Batch To Mentor</div>
                        <div className=" grid grid-cols-12 gap-[30px]">

                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 mt-8">
                                <div className="bg-white shadow-box7 p-8 rounded-md">
                                    <div className="text-center">
                                        <b>Mentors</b>
                                    </div>
                                    <div>
                                        {mentorList.map((ele, index) => (
                                            <p className="shadow-sm p-4 bg-body-tertiary rounded" style={{ cursor: "pointer" }} >
                                                <input type='radio'
                                                    checked={selectedMentorName === ele.user_name}
                                                    name={selectedMentorName}
                                                    id={index} value={ele.user_id}
                                                    ref={(el) => (checkboxesRef.current[index] = el)}
                                                    onChange={() => selectMentor(ele)}
                                                />
                                                {" "}{ele.user_name}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 ">
                                <div className="bg-white shadow-box7 p-8 rounded-md mt-8">
                                    <div className="text-center">
                                        <b>{`${defaultMentorName} Batches`}</b>
                                    </div>
                                    <div className="hover:shadow-box hover:bg-red">
                                        {batchList.map((ele, index) => (
                                            <p className="shadow-sm p-4 bg-body-tertiary rounded" style={{ cursor: "pointer" }} ><input type="checkbox"
                                                id={index}
                                                checked={JSON.stringify(selectedBatch).includes(ele.BatchName)}
                                                value={ele.BatchName}
                                                ref={(el) => (radiosRef.current[index] = el)}
                                                onChange={
                                                    (event) => selectBatch(ele, ele.BatchName, event)
                                                }
                                            /> <div style={{ display: "grid" }}>
                                                    <p><b>Batch:</b> {ele.BatchName}</p>
                                                    <p><b>Date:</b> {ele.StartDate}</p>
                                                    <p><b>Time:</b> {ele.StartTime}</p>
                                                    <p><b>BatchProgress:</b> {ele.batchProgress}</p>
                                                    <p><b>Batch Students:</b>{ele.StudentsEndrolled.map((student) => (
                                                        <li>{student.name}</li>
                                                    ))}</p>
                                                </div>
                                            </p>
                                        ))}

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="text-center mt-6">
                            <button className="btn btn-primary" type="" onClick={assignBatch}>Assign Batch To The Mentor</button>
                        </div>

                    </div>
                </div>
            }
        </>
    )
}

export default AssignBatchToTheMentor;