import React, { useState, useEffect } from 'react';
import "../../assets/css/newacitivity.css"
import { useLocation } from 'react-router-dom';

function AcitvityCourse() {
    const json = {


        "FinishScreen": 4,
        "Question": "Explore this app for recommendations on locating movie theaters./nWhat are the Inputs?/nWhat are the Outputs?",
        "Screens": {
            "1": {
                "Button": [
                    {
                        "ButtonName": "Locate Quiz",
                        "ButtonNavigation": 2
                    }
                ],
                "Header": "To begin, please click the button below."
            },
            "2": {
                "Button": [
                    {
                        "ButtonName": "Yes Of Course",
                        "ButtonNavigation": 3
                    },
                    {
                        "ButtonName": "No i donot like",
                        "ButtonNavigation": 4
                    }
                ],
                "Header": "Do you enjoy watching films in theaters?"
            },
            "3": {
                "Button": [
                    {
                        "ButtonName": "Batman movies",
                        "ButtonNavigation": 5
                    },
                    {
                        "ButtonName": "Superman movies",
                        "ButtonNavigation": 5
                    }
                ],
                "Header": "Which do you prefer: Superman movies or Batman movies?"
            },
            "4": {
                "Header": "Successfully Completed",
                "iframe": "https://giphy.com/embed/l2Sqir5ZxfoS27EvS"
            },
            "5": {
                "link": {
                    "LinkButtonName": "Locator",
                    "LinkUrl": "https://maps.app.goo.gl/NmYeXotRpCPE1GLB8"
                }
            }
        },
        "TotalScreens": 5
    }




    const [ScreenNumber, setScreenNumber] = useState(1);
    const [Activitystarted, setActivitystarted] = useState(false);
    const [ActivityData, setActivityData] = useState(json)
    const location = useLocation();

    // useEffect(()=>{
    //     const {resource} = location.state ? location.state : {}
    //     if (resource){setActivityData(resource["ActivityData"])}
    //     console.log(resource)
    //   },[])

    const OpenUrlInNewTab = (url) => {
        window.open(url, "_blank");
    }
    const images = {
        "HomeActivity": require('../../assets/images/NewActivityImages/homeactivity.jpg'),
        "Dog": require('../../assets/images/NewActivityImages/dog2.jpg'),
        "Cat": require("../../assets/images/NewActivityImages/cat.jpg"),
        "FootBall": require("../../assets/images/NewActivityImages/football7.jpg")
        // and so on
    };

    return (
        <div className="container" style={{ overflow: 'hidden' }}>
            <div className='grid grid-cols-12'>
                <div className="lg:col-span-3 col-span-12 ">
                    <div class="design-vew22 ">
                        <div className="fontq mt-4">
                            <div className="activityone">
                                {/* header */}
                                {ActivityData && ActivityData["Screens"] && ActivityData["Screens"][ScreenNumber] && ActivityData["Screens"][ScreenNumber]["Header"]}
                                {ActivityData && ActivityData["Screens"] && ActivityData["Screens"][ScreenNumber] && ActivityData["Screens"][ScreenNumber]["image"] ? <img src={images[ActivityData["Screens"][ScreenNumber]["image"]]} /> : ""}
                                {ActivityData && ActivityData["Screens"] && ActivityData["Screens"][ScreenNumber] && ActivityData["Screens"][ScreenNumber]["link"] ? <div onClick={()=>OpenUrlInNewTab(ActivityData["Screens"][ScreenNumber]["link"]["LinkUrl"])} className="button-86 me-1">{ActivityData["Screens"][ScreenNumber]["link"]["LinkButtonName"]}</div> : ""}

                            </div>
                            <div id='content'>
                                {ActivityData && ActivityData["Screens"] && ActivityData["Screens"][ScreenNumber] && ActivityData["Screens"][ScreenNumber]["Button"] && ActivityData["Screens"][ScreenNumber]["Button"].map((item, index) => (
                                    <button key={index} className="button-86 md--10 p-3 w175 mt-8 button-gap" onClick={() => Activitystarted && setScreenNumber(item["ButtonNavigation"])}>{item["ButtonName"]}</button>
                                ))
                                }
                                {ActivityData && ActivityData["Screens"] && ActivityData["Screens"][ScreenNumber] && ActivityData["Screens"][ScreenNumber]["iframe"] &&
                                    <iframe src={ActivityData["Screens"][ScreenNumber]["iframe"]} width="220" height="480" class="giphy-embed" allowFullScreen />
                                }
                            </div>
                        </div>
                    </div>
                    <div class="nav-controls justify-content-between align-items-center">
                        <button className="runbttn mr-3 " style={{ textAlign: "center" }} onClick={() => Activitystarted ? (setScreenNumber(1), setActivitystarted(false)) : setActivitystarted(true)}>{Activitystarted ? "RESET" : "RUN"}</button>
                    </div>
                    <div class="nav-controls2 d-flex justify-content-between align-items-center">
                        <button className="runbttn mr-3 " style={{ textAlign: "center" }} onClick={() => Activitystarted && ActivityData && ActivityData["FinishScreen"] && setScreenNumber(ActivityData["FinishScreen"])} >FINISH</button>
                    </div>
                </div>
                <div class="lg:col-span-9 col-span-12">
                    <div class="whitespace">
                        <div class="headerwhitebox">Instructions</div>
                        <h4 class="rightheading">Questionare</h4>
                        <div class="rightpaneul">
                        Explore this app for recommendations on locating movie theaters.
                            <ul >
                                <li>What are the Inputs?</li>
                                <li>What are the Outputs?</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AcitvityCourse; 