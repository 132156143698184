import React, { useState, useEffect } from "react";
import "../../assets/css/newacitivity.css";
import { useLocation } from "react-router-dom";

function TestFile() {
  const json = {
    Question:
      "Try Out this quiz about General knowledge./nWhat are the Inputs?/nWhat are the Outputs?",
    TotalScreens: 2,
    FinishScreen: 2,
    Screens: {
      1: {
        HeaderComments: "I Am Thinking Of A Number From 1 to 100 ?",
        Header: "Can You Guess It ?",
        Guess: { value: "60", tries: 5 },
      },
      2: {
        Header: "Successfully Completed",
        iframe: "https://giphy.com/embed/l2Sqir5ZxfoS27EvS",
      },
    },
  };

  const [ScreenNumber, setScreenNumber] = useState(1);
  const [Activitystarted, setActivitystarted] = useState(false);
  const [ActivityData, setActivityData] = useState(json);
  const [Selected, setSelected] = useState(false);
  const [GuessedNumber, setGuessedNumber] = useState(1);
  const [Try, setTry] = useState(0);
  const location = useLocation();
  let value = 20;
  let numberoftries = 5;
  let option = "";
  // useEffect(()=>{
  //     const {resource} = location.state ? location.state : {}
  //     if (resource){setActivityData(resource["ActivityData"])}
  //     console.log(resource)
  //   },[])
  const tries = () => {};

  const images = {
    HomeActivity: require("../../assets/images/NewActivityImages/homeactivity.jpg"),
    Dog: require("../../assets/images/NewActivityImages/dog2.jpg"),
    Cat: require("../../assets/images/NewActivityImages/cat.jpg"),
    FootBall: require("../../assets/images/NewActivityImages/football7.jpg"),
    // and so on
  };

  const CheckValueToOptions = () => {
    if (Try < numberoftries) {
      if (GuessedNumber == value) {
        return "correct";
      } else {
        return "sorry";
      }
    } else if (Try >= numberoftries) {
      return "Maximum tries reached!";
    }
  };

  return (
    <div className="container" style={{ overflow: "hidden" }}>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div class="design-vew22 ">
            <div className="fontq ">
              <div className="activityone">
                {/* header */}

                <div className="HeaderComments">
                  {ActivityData &&
                    ActivityData["Screens"] &&
                    ActivityData["Screens"][ScreenNumber] &&
                    ActivityData["Screens"][ScreenNumber]["HeaderComments"]}
                </div>
                {ActivityData &&
                  ActivityData["Screens"] &&
                  ActivityData["Screens"][ScreenNumber] &&
                  ActivityData["Screens"][ScreenNumber]["Header"]}
                {ActivityData &&
                ActivityData["Screens"] &&
                ActivityData["Screens"][ScreenNumber] &&
                ActivityData["Screens"][ScreenNumber]["image"] ? (
                  <img
                    src={images[ActivityData["Screens"][ScreenNumber]["image"]]}
                  />
                ) : (
                  ""
                )}
                {ActivityData &&
                ActivityData["Screens"] &&
                ActivityData["Screens"][ScreenNumber] &&
                ActivityData["Screens"][ScreenNumber]["link"] ? (
                  <a
                    href={
                      ActivityData["Screens"][ScreenNumber]["link"]["LinkUrl"]
                    }
                    className="button-86 me-1"
                  >
                    {
                      ActivityData["Screens"][ScreenNumber]["link"][
                        "LinkButtonName"
                      ]
                    }
                  </a>
                ) : (
                  ""
                )}
                <select
                  className="from-control"
                  value={GuessedNumber}
                  onChange={(e) =>
                    Activitystarted &&
                    (setGuessedNumber(e.target.value), setTry(Try + 1))
                  }
                  required
                >
                  {Array.from({ length: 100 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
                {Activitystarted && CheckValueToOptions()}
              </div>
              <div id="content">
                {ActivityData &&
                  ActivityData["Screens"] &&
                  ActivityData["Screens"][ScreenNumber] &&
                  ActivityData["Screens"][ScreenNumber]["Button"] &&
                  ActivityData["Screens"][ScreenNumber]["Button"].map(
                    (item, index) => (
                      <button
                        key={index}
                        className="button-86 md--10 p-3 w175 mt-8 button-gap"
                        onClick={() =>
                          Activitystarted &&
                          setScreenNumber(item["ButtonNavigation"])
                        }
                      >
                        {item["ButtonName"]}
                      </button>
                    )
                  )}
                {ActivityData &&
                  ActivityData["Screens"] &&
                  ActivityData["Screens"][ScreenNumber] &&
                  ActivityData["Screens"][ScreenNumber]["iframe"] && (
                    <iframe
                      src={ActivityData["Screens"][ScreenNumber]["iframe"]}
                      width="220"
                      height="480"
                      class="giphy-embed"
                      allowFullScreen
                    />
                  )}
              </div>
            </div>
          </div>
          {/* <div class="nav-controls justify-content-between align-items-center"> */}
          <button
            className="runbttn mr-3 "
            style={{ textAlign: "center" }}
            onClick={() =>
              Activitystarted
                ? (setScreenNumber(1), setActivitystarted(false))
                : setActivitystarted(true)
            }
          >
            {Activitystarted ? "RESET" : "RUN"}
          </button>
          {/* </div> */}
        </div>
        <div class="lg:col-span-9 col-span-12">
          <div className="whiteboard">
            <div class="headerwhitebox"></div>
            <h4 class="rightheading">Guess the Number </h4>
            <div class="rightpaneul">
              <li>In this app, you try to guess the computer's number.</li>
              <ul>
                <h4>Input:</h4>
                <li>1. The number you are guessing (user dropdown)</li>
                <li></li>
                <hr></hr>
                <h4>Output</h4>
                <li>1. The number of countries that meet the requirement.</li>
                <li>
                  2. Display that tells you if you are too low or too high.
                </li>
                <li>3. Display that tells you that you got it right.</li>
                <li>4. Display that tells you that you are out of tries.</li>

                <hr></hr>
                <h4>Processing</h4>
                <li>
                  Out of the four types of processing that you have learned
                  about, pick at least two and explain how they can be used to
                  change the input into output.
                </li>
                <li>if/then</li>
                <li>counting</li>
                <li>finding a match</li>
                <li>comparing</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TestFile;
