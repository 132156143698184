import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import MobileMenu from "./MobileMenu";

import {
  Anglofone_logo,
  Codomo_logo,
  codomoJpeg1,
  codomologoMoreWidth,
  LOGOSingleLine,
  CodomoLogoNew,
} from "../constant/images";
import { googleLogout } from "@react-oauth/google";
import { AuthenticationState } from "../App";

const Header = (props) => {
  const {
    setuserAuthenticated,
    setVerifiedAsMentor,
    verifiedAsMentor,
    verifiedAsAdmin,
    setVerifiedAsAdmin,
  } = useContext(AuthenticationState);
  const [activeMobileMenu, setActiveMobileMenu] = useState(false);
  const [downloadoptions, setDownloadoptions] = useState([]);
  const navigate = useNavigate();
  const scrollNav = useRef(null);
  useEffect(() => {
    const storedData = sessionStorage.getItem("CourseKey");
    if (
      storedData !== "undefined" &&
      storedData !== "null" &&
      storedData !== undefined &&
      storedData !== null
    ) {
      setDownloadoptions(JSON.parse(storedData));
    } else if (
      props.course !== "undefined" &&
      props.course !== "null" &&
      props.course !== undefined &&
      props.course !== null
    ) {
      setDownloadoptions(props.course);
      console.log(props.course);
    }
    // scrolling nav
    const handleScroll = () => {
      let windowScroll = window.scrollY > 100;
      scrollNav.current &&
        scrollNav.current.classList.toggle("rt-sticky-active", windowScroll);
      scrollNav.current &&
        scrollNav.current.classList.toggle("sticky", windowScroll);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [props.course]);

  const logOut = () => {
    googleLogout();
    navigate("/signIn");
    localStorage.clear();
    sessionStorage.clear();
    setuserAuthenticated(false);
    setVerifiedAsMentor(false);
    setVerifiedAsAdmin(false);
  };

  return (
    <>
      <header
        className="site-header  home-one-header top-0 w-full z-[999] rt-sticky "
        ref={scrollNav}
      >
        <div className="main-header py-6">
          <div className="container">
            <div className=" flex items-center justify-between">
              <Link
                to={"/codomo/home"}
                className="brand-logo flex-none lg:mr-10 md:w-auto max-w-[120px] "
              >
                <img
                  style={{ height: "60px" }}
                  src={CodomoLogoNew}
                  alt=""
                ></img>
              </Link>
              <div className="flex items-center flex-1">
                <div className="flex-1 main-menu relative mr-[74px]">
                  <ul className="menu-active-classNamees">
                    <li>
                      <Link to={"/codomo/home"}>Home</Link>
                    </li>
                    <li className="menu-item-has-children">
                      <a>Course</a>
                      <ul className="sub-menu">
                        {downloadoptions.map((item, index) => (
                          <li>
                            <Link to="/codomo/course" state={{ course: item }}>
                              {item.course_name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <a>Downloads</a>
                      <ul className="sub-menu">
                        {downloadoptions.map((item, index) => (
                          <li>
                            <Link
                              to="/codomo/course/downloads"
                              state={{ course: item }}
                            >
                              {item.course_name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                    {verifiedAsMentor ? (
                      <li className="menu-item-has-children">
                        <a>Mentor</a>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/codomo/mentor/activities">
                              Batch management
                            </Link>
                          </li>
                          <li>
                            <Link to="/codomo/mentor/notification">
                              Notification Service
                            </Link>
                          </li>
                          <li>
                            <Link to="/codomo/mentor/admiration">
                              Student Admiration
                            </Link>
                          </li>
                          <li>
                            <Link to="/codomo/mentor/addblog">Add Blog</Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}
                    {verifiedAsAdmin ? (
                      <li className="menu-item-has-children">
                        <a>Admin</a>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/codomo/admin/usermanagement">
                              User Management
                            </Link>
                          </li>
                          <li>
                            <Link to="/codomo/admin/coursemanagement">
                              Course Management
                            </Link>
                          </li>
                          <li>
                            <Link to="/codomo/admin/assiginingcoursetomentor">
                              Assign Course to Mentor
                            </Link>
                          </li>
                          <li>
                            <Link to="/codomo/admin/batchmanagement">
                              Batch Management
                            </Link>
                          </li>
                          <li>
                            <Link to="/codomo/admin/createbatch">
                              Create Batch
                            </Link>
                          </li>
                        </ul>

                      </li>
                    ) : (
                      ""
                    )}
                    <li>
                      <Link to={"/codomo/blog"}>Blog</Link>
                    </li>

                    <li>
                      <Link to={"/codomo/contactus"}>Contact</Link>
                    </li>
                  </ul>
                </div>
                <div className="flex-none flex space-x-[18px]">
                  <div className=" hidden lg:block">
                    <button
                      className="btn btn-primary py-[15px] px-8"
                      onClick={logOut}
                    >
                      Logout
                    </button>
                  </div>
                  <div className=" block lg:hidden">
                    <button
                      type="button"
                      className=" text-3xl md:w-[56px] h-10 w-10 md:h-[56px] rounded bg-[#F8F8F8] flex flex-col items-center justify-center
                                        menu-click"
                      onClick={() => setActiveMobileMenu(!activeMobileMenu)}
                    >
                      <iconify-icon
                        icon="cil:hamburger-menu"
                        rotate="180deg"
                      ></iconify-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {activeMobileMenu && (
        <MobileMenu
          activeMenu={activeMobileMenu}
          setActiveMenu={setActiveMobileMenu}
          downloadoptions={downloadoptions}
        />
      )}
    </>
  );
};

export default Header;