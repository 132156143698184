import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/newacitivity.css";
import { SaveHtmlStructure } from "../../api/aws-api";
import { FetchHomePageDetails } from "../../api/aws-api";
import { ConstructorEventGet } from "../../Event/event";
import backgroundImage from "../../assets/images/NewActivityImages/background.jpg";


export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();

  const [workspaceItems, setWorkspaceItems] = useState([]);

  const [activitystarted, setActivitystarted] = useState(false);
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [ClickedNext, setClickedNext] = useState(false);
  const [dragging, setDragging] = useState(true);
  const [divtochangeContent, setDivtochangeContent] = useState([]);
  const [screen, setScreen] = useState("Left");
  const [selectedAudio, setSelectedAudio] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isMobileClicked, setMobileClicked] = useState(false); // New state
  const [selectedAttribute, setSelectedAttribute] = useState(false);
  const [propertyScreen, setPropertyScreen] = useState("");
  const [selectedPropertyColor, setPropertySelectedColor] = useState("");
  const [valuePropertyDropdown, setPropertyValueDropdown] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [ValueDropdown, setValueDropdown] = useState("");
  const [selectedpopup, setSelectedpopup] = useState(false);
  const [userDetails, setUserDetails] = useState([]);

  const dataone = {
    Sound: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/original-phone-ringtone-36558.mp3",
    Drum: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/grunge-bounce-drum-loop-40464.mp3",
    Rain: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/heavy-rain-nature-sounds-8186.mp3",
    Guitar: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/guitar-riff-159089.mp3",
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.id);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");

    const newWorkspaceItem = {
      id: workspaceItems.length + 1,
      type: droppedItemId,
    };

    setWorkspaceItems([...workspaceItems, newWorkspaceItem]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    setHtmlCode(data);
  }

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };
  const handleMobileClick = () => {
    // Handle DesignVew22 click logic
    setMobileClicked(true);
    if (activitystarted && selectedOption === "Click" && isMobileClicked) {
      var audio = new Audio(selectedAudio);
      audio.play();
      setScreen(SelectedScreen);
      if (propertyScreen && selectedPropertyColor && valuePropertyDropdown) {
        console.log(propertyScreen);
        console.log(selectedPropertyColor);
        console.log(valuePropertyDropdown);
        setSelectedScreen(propertyScreen);
        setSelectedColor(selectedPropertyColor);
        setValueDropdown(valuePropertyDropdown);
        setScreen(propertyScreen);
      }
    }
  };

  const [htmlStructure, setHtmlStructure] = useState();
  function getHtmlStructure() {
    const data = getHtmlCode.current.outerHTML;

    setHtmlStructure(`<!DOCTYPE html>
    <html>
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width">
      <title>My App</title>
      <style>
      .design-vew22 {
        background: url("https://d3e3enpv2x7kmj.cloudfront.net/TestImages/mobile.png");
        width: 100%;
        max-width: 280px;
        height: 555px;
        background-size: cover;
        padding: 50px 20px;
        margin-top: 15px;
        margin-bottom:50px
      }
      .buttonsessionActivity10 {
        height: 60px;
        width: 90px;
        border: 1px solid #007bff;
        background-color: green;
        color: wheat;
        border-radius: 10px;
        margin-top: 300px !important;
        margin-left: 100px !important;
      }
      .appLabSession10 {
        height: 115.5%; 
        width: 108%; 
       border-radius: 40px; 
       margin-top: -43px;
       margin-left: -11px; }
      
      </style>
    </head>
    <body>
      ${data}
    </body>
    </html>`);

    const htmlStructure = `<!DOCTYPE html>
    <html>
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width">
      <title>My App</title>
      <style>
      .design-vew22 {
        background: url("https://d3e3enpv2x7kmj.cloudfront.net/TestImages/mobile.png");
        width: 100%;
        max-width: 280px;
        height: 555px;
        background-size: cover;
        padding: 50px 20px;
        margin-top: 15px;
        margin-bottom:50px
      }
      .buttonsessionActivity10 {
        height: 60px;
        width: 90px;
        border: 1px solid #007bff;
        background-color: green;
        color: wheat;
        border-radius: 10px;
        margin-top: 300px !important;
        margin-left: 100px !important;
      }
      .appLabSession10 {
        height: 115.5%; 
        width: 108%; 
       border-radius: 40px; 
       margin-top: -43px;
       margin-left: -11px; }
      
      </style>
    </head>
    <body>
      ${data}
    </body>
    </html>`;

    console.log("the extracted htmlStructure is----", htmlStructure);

    const htmlWithoutNewlines = htmlStructure.replace(/\n/g, "");

    // Remove both single and consecutive backslashes
    const htmlWithoutBackslashes = htmlWithoutNewlines.replace(/\\+/g, "");

    // Data to be sent to the Lambda function
    const additionalData = `${userDetails.user_name}-(${userDetails.course[0].batch_id})-AppLabSessionNine MobileApp`;

    const dataToStore = {
      name: htmlWithoutBackslashes,
    };

    fetch(SaveHtmlStructure, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event: dataToStore,
        additionalData: additionalData,
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));
  }

  return (
    <div className="container" style={{ overflow: "hidden", backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="Newbody">
            <div className="Newheader">
              <div
                className="design-vew22"
                ref={getHtmlCode}
                onClick={handleMobileClick}
              >
                {screen === "Left" && activitystarted ? (
                  <div
                    className={
                      SelectedScreen == "Left" &&
                        activitystarted &&
                        selectedColor == "background_color"
                        ? "appLabSession10"
                        : null
                    }
                    style={{
                      backgroundColor:
                        SelectedScreen == "Left" &&
                          activitystarted &&
                          selectedColor == "background_color"
                          ? ValueDropdown
                          : "white",
                    }}
                  >
                    <button
                      className="buttonsessionActivity10"
                      style={{
                        backgroundColor:
                          activitystarted && valuePropertyDropdown === "green"
                            ? "yellow"
                            : null,
                        color:
                          activitystarted && valuePropertyDropdown === "green"
                            ? "black"
                            : null,
                        marginTop:
                          activitystarted && SelectedScreen === "Left"
                            ? "230px"
                            : "185px",
                        marginLeft:
                          activitystarted && SelectedScreen === "Left"
                            ? "75px"
                            : "65px",
                      }}
                    >
                      Left Screen
                    </button>
                  </div>
                ) : screen === "Right" && activitystarted ? (
                  <div
                    className={
                      SelectedScreen == "Right" &&
                        activitystarted &&
                        selectedColor == "background_color"
                        ? "appLabSession10"
                        : null
                    }
                    style={{
                      backgroundColor:
                        SelectedScreen == "Right" &&
                          activitystarted &&
                          selectedColor == "background_color"
                          ? ValueDropdown
                          : "white",
                    }}
                  >
                    <button
                      className="buttonsessionActivity10"
                      style={{
                        backgroundColor:
                          activitystarted && valuePropertyDropdown === "green"
                            ? "yellow"
                            : null,
                        color:
                          activitystarted && valuePropertyDropdown === "green"
                            ? "black"
                            : null,
                        marginTop:
                          activitystarted && SelectedScreen === "Right"
                            ? "230px"
                            : "185px",
                        marginLeft:
                          activitystarted && SelectedScreen === "Right"
                            ? "75px"
                            : "55px",
                      }}
                    >
                      Right Screen
                    </button>
                  </div>
                ) : (
                  <button
                    className="buttonsessionActivity10"
                    style={{
                      marginTop:
                        activitystarted && SelectedScreen === "Left"
                          ? "230px"
                          : "185px",
                      marginLeft:
                        activitystarted && SelectedScreen === "Right"
                          ? "75px"
                          : "65px",
                    }}
                  >
                    {screen === "Left" ? "Left Screen" : "Right Screen"}
                  </button>
                )}
              </div>
              <button
                className="button-12 mt-2"
                style={{ textAlign: "center" }}
                onClick={() =>
                  activitystarted
                    ? (setClickedNext(false), setActivitystarted(false))
                    : setActivitystarted(true)
                }
              >
                {activitystarted ? "RESET" : "RUN"}
              </button>
            </div>
          </div>
        </div>

        <div className="lg:col-span-9 col-span-12 mt-5">
          <i className="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>

          <div className="lg:col-span-9 col-span-12 mt-5">
            <div className="NavbarApp">
            &emsp;Instructions
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <span></span>

              <button className="getHtmlCodeBtn" onClick={getHtmlStructure}>
                <b>Share App</b>
              </button>
            </div>
            </div>
            <div className="instructions-content">
              <p>
                <strong>Goal:</strong> The screen switches to "rightScreen" when the user
                clicks the "rightButton".
              </p>
              <p>
                <strong>How:</strong> In Code Mode, add a new onEvent() to your program
                for your "Right!" button. Add a setScreen() block inside of it that
                switches screens to "rightScreen".
              </p>
            </div>


            
            
          </div>

          <div className="row">
            <div className="col-6">
              <div className="box">
                <div className="box-head">Toolbox</div>

                <div className="box-content p-3">
                  {/* setProperty block */}
                  <div
                    className="pin"
                    draggable="true"
                    id="setProperty"
                    onDragStart={handleDragStart}
                  >
                    <div className="divtochange flex">setProperty </div>
                  </div>

                  {/* setScreen block */}
                  <div
                    className="pin"
                    draggable="true"
                    id="setScreen"
                    onDragStart={handleDragStart}
                  >
                    <div className="divtochange flex">setScreen ( screenid )</div>
                  </div>

                  {/* playSound block */}
                  <div
                    className="pin"
                    draggable="true"
                    id="playSound"
                    onDragStart={handleDragStart}
                  >
                    <div className="divtochange flex">playSound ( URL )</div>
                  </div>

                  {/* onEvent block */}


                  <div
                    class="plug-board work-space"

                    draggable="true"
                    id="onEvent"
                    onDragStart={handleDragStart}
                  >
                    <div class="plug-board-top">
                      OnEvent (id, type, callback)
                      <div class="function">Function</div>
                    </div>

                    <div class="plug">
                      <div
                        class="plug-blue"
                        ondrop="drop(event)"
                        ondragover="allowDrop(event)"
                      ></div>
                    </div>

                    <div class="plug-board-bottom">
                      <div class="bottom-blue"></div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-9">
              <div className="box">
                <div className="box-head">Workspace <a
                    class="btn"
                    onClick={() => {
                      popupvalue();
                      handleHtmlCode();
                      getHtmlStructure();
                    }}
                  >
                    <button>Show Code</button>
                  </a>
                  {"  "}
                  <a
                    class="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#show-code-popup"
                  >
                    <button>Version History</button>
                  </a></div>
                <div className="box-content p-3" onDrop={handleDrop} onDragOver={handleDragOver} >
                  {workspaceItems.map((item) => (
                    <div key={item.id} className="divtochange flex"



                      style={{
                        marginLeft:
                          item.type === "setProperty"
                            ? "25px"
                            : item.type === "setScreen"
                              ? "25px"
                              : item.type === "playSound"
                                ? "25px"
                                : "0px", // Add margin for specific types
                      }}
                    >
                      {item.type === "setProperty" && (
                        <div className="pin">
                          setProperty (
                          <select className="dropdown">
                            <option value="Left">Left</option>
                            <option value="Right">Right</option>
                          </select>
                          <select className="dropdown">
                            <option value="background_color">background_color</option>
                          </select>
                          <select className="dropdown">
                            <option value="yellow">yellow</option>
                          </select>
                          )
                        </div>
                      )}

                      {item.type === "setScreen" && (
                        <div className="pin">
                          setScreen (
                          <select className="dropdown">
                            <option value="Left">Left</option>
                            <option value="Right">Right</option>
                          </select>
                          )
                        </div>
                      )}

                      {item.type === "playSound" && (
                        <div className="pin mr-10">
                          playSound (
                          <select className="dropdown">
                            <option value="">Select Sound</option>
                            {Object.keys(dataone).map((key, index) => (
                              <option key={index} value={dataone[key]}>
                                {key}
                              </option>
                            ))}
                          </select>
                          )
                        </div>
                      )}

                      {item.type === "onEvent" && (

                        <div
                          class="plug-board work-space"

                        >
                          <div class="plug-board-top">
                            OnEvent (id, type, callback)
                            <div class="function">Function</div>
                          </div>




                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {selectedpopup && (
            <div className="modal">
              <div className="overlay">
                <div className="modal-content">
                  {htmlCode}
                  <button className="btn" style={{ marginTop: "30px" }} onClick={popupvalue}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Appone;


