import { React } from "react";



function ChatScreen() {


    const getTranslatedOutput = () => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-amz-json-1.1");
        myHeaders.append("X-Amz-Target", "AWSShineFrontendService_20170701.TranslateText");
        myHeaders.append("X-Amz-Content-Sha256", "beaead3198f7da1e70d03ab969765e0821b24fc913697e929e726aeaebf0eba3");
        myHeaders.append("X-Amz-Security-Token", "IQoJb3JpZ2luX2VjEJH//////////wEaCmFwLXNvdXRoLTEiSDBGAiEA+5fizalwW2mkS+TE4wpPBAOSS0cuwYXPKGjo+Zw3eJMCIQDqdH2v1mM1FHlLC+/w5aw9CreAev+Oy1SrqNfzPV7SyyrpAghaEAAaDDkxNDQ2ODk2MzE3OCIMH91Hb58EnnUwggdrKsYCB5RlTfNaMHEdKc688m7bBVX4mRx051E1Akw5vToKH14zPGN2CsH6Js2FAMM+GQ1XBwqlc3WfJCHQn7PHbaBAmlbanCXwioQCoBNZnekHDYFVbrzKdorHFLezX9K38Bfv4NaAn4oqIZ4aER8dl1eTmMe5TefKCdn0jUe7Ol99NuvgDMDaw0uX/n6reWxFgvyvbxKBesZFZ9oH1dTRu/VtzJtLdX1ZHgv+WRFcbVSFqJXFCAO551zCHRpWbZq0gYTpbN/s/6k3eGYYVxXNYPBQTbe2qZrRxtSuChtoSZZToPGht6xUV8ZKQJTo1jy9HG7DdD2m4WJG9M/7sfwuYKv9zK6i2Go92xskL6lOyVcPC+o487qIO80BviCVdcjrnXcviLUgVSj9QsazOAHIFwySdeWWBnI1ym6KdvxBEr+KeR2O3ZuD3+Mw0cC+tAY6pgF9qsMUgDzcSdtYlEFJDBqt+Y2Gii8WUwx3mv6MImiHADPoODiGAqutXVhfHREFGJgigxugX0mS44aWUmNLgCr3ecv/ioQjHOS7qwcX5AWZNYjT3bOYyZ1hntx1AMemLFiCoxA+HaZKnLMNErSc1k+ZlwvUs/nZejAkQAHTs05miD5wSl2EQv9dfz85qkwj/dCmo7h8g+M4Wu2XPTqVuhYPDARSyoAK");
        myHeaders.append("X-Amz-Date", "20240711T090539Z");
        myHeaders.append("Authorization", "AWS4-HMAC-SHA256 Credential=ASIA5J2UZANVKUA32HOH/20240711/ap-south-1/translate/aws4_request, SignedHeaders=content-length;content-type;host;x-amz-content-sha256;x-amz-date;x-amz-security-token, Signature=b0a0efbd24272b9b80c699df54505bd85ff4619460c5c3ed0a5f35e8ddf4c18d");

        const raw = "{\r\n  \"SourceLanguageCode\": \"ta-IN\",\r\n  \"TargetLanguageCode\": \"en\",\r\n  \"Text\": \"எல்லோரும் எங்கே\"\r\n} ";

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://translate.ap-south-1.amazonaws.com/", requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));

    }
    return (
        <div style={{ backgroundColor: "#ece5dd", height: "100vh" }}>
            <h2>ChatScreen</h2>

            <button onClick={getTranslatedOutput}>Translate</button>
        </div>
    )
}


export default ChatScreen;